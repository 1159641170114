import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { ResetRewardAmountCouponRequestValidateAction } from '../../../shared/store/actions/reward-request.actions';
import { selectRewardRequest, selectRewardValidate } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-purchase-amount-coupon',
  templateUrl: './purchase-amount-coupon.component.html',
  styleUrls: ['./purchase-amount-coupon.component.scss']
})
export class PurchaseAmountCouponComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  private amountsDuplicate: number[];

  constructor(public fb: FormBuilder, protected readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parentForm.addControl('purchaseAmountCoupon', this.fb.array([], this.duplicatedValidator));

    this.initSelectLocalStore();

    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }
  }
  setEditMode() {
    this.purchaseAmountCoupon.enable();
  }

  initSelectLocalStore() {
    this.localStore
      .pipe(
        select(selectRewardValidate),
        filter(() => this.submitted || this.isEditFirst)
      )
      .subscribe(data => {
        if (data === null || !data.items) {
          this.amountsDuplicate = [];
        } else {
          this.amountsDuplicate = data.items.map(x => x.amount);
        }

        if (this.purchaseAmountCoupon && this.purchaseAmountCoupon.controls.length > 0) {
          this.purchaseAmountCoupon.controls.forEach(ctr => {
            ctr.get('amount').updateValueAndValidity();
          });
        }
      });
  }
  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        if (req && req.purchaseAmtCouponItems && req.purchaseAmtCouponItems.length > 0) {
          req.purchaseAmtCouponItems.forEach((value: any, i) => {
            this.purchaseAmountCoupon.push(this.createForm());

            const amount = value.purchaseAmount ? value.purchaseAmount.amount : null;
            const coupon = value.couponAmount ? value.couponAmount.amount : null;
            const minPerBillAmount = value.minPerBillAmount ? value.minPerBillAmount.amount : null;

            this.purchaseAmountCoupon.at(i).patchValue({
              amount: amount,
              coupon: coupon,
              minPerBill: minPerBillAmount
            });
          });

          if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
            this.purchaseAmountCoupon.disable();
          } else {
            this.setEditMode();
          }
        }
      });
  }

  setItemValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control === null || errorMessage === null) {
        return null;
      }

      const valid = {};
      valid[errorMessage.toLocaleLowerCase()] = true;
      return valid;
    };
  }

  createForm(): FormGroup {
    const initialNullRequired = [{ value: null, disabled: false }, [Validators.required, isZeroValidator()]];

    return this.fb.group({
      amount: [
        { value: null, disabled: false },
        [Validators.required, isZeroValidator(), this.amountDuplicateValidator]
      ],
      coupon: initialNullRequired,
      minPerBill: [{ value: null, disabled: false }, [Validators.required]]
    });
  }

  addPurchaseTier(): void {
    this.purchaseAmountCoupon.push(this.createForm());
  }
  deleteItem(i: number): void {
    this.purchaseAmountCoupon.removeAt(i);
  }

  get purchaseAmountCoupon() {
    return this.parentForm.get('purchaseAmountCoupon') as FormArray;
  }
  get pageMode() {
    return RewardPageModes;
  }

  get duplicatedValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();
      const availableItems = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].amount > 0) {
          const isDuplicated = availableItems.indexOf(array[i].amount) > -1;
          if (!isDuplicated) {
            availableItems.push(array[i].amount);
          }

          if (!fa.controls[i].errors || fa.controls[i].getError('duplicated')) {
            fa.controls[i].setErrors(isDuplicated && { duplicated: true });
          }
        }
      }
      return null;
    };
  }

  get amountDuplicateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value && this.amountsDuplicate && this.amountsDuplicate.find(x => x === control.value)) {
        return { amount_duplicate: true };
      }

      return null;
    };
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('purchaseAmountCoupon');
    this.store.dispatch(new ResetRewardAmountCouponRequestValidateAction());
  }
}
