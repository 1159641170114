export enum RewardCondition {
  PURCHASE_AMOUNT = 'PURCHASE_AMOUNT',
  PURCHASE_QUANTITY = 'PURCHASE_QUANTITY',
  SUPPLIER_COUPON = 'SUPPLIER_COUPON'
}
export enum RewardOffer {
  COUPON = 'COUPON',
  GOODS = 'GOODS'
}
export enum RewardPageModes {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_CREATE = 'REQUEST_CREATE',
  REQUEST_EDIT = 'REQUEST_EDIT',
  VIEW = 'VIEW'
}

export enum RequestTypeEnum {
  NEW = 'N',
  EDIT = 'E'
}

export enum RewardRequestStatusEnum {
  AWAITING_APPROVAL = 'W',
  DRAFT = 'D',
  REJECTED = 'R',
  APPROVED = 'A'
}

export enum RedeemPriceType {
  REDEEM = 'REDEEM',
  FREE = 'FREE'
}
