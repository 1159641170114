import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  RequestTypeEnum,
  RewardCondition,
  RewardOffer,
  RewardPageModes,
  RewardRequestStatusEnum
} from '../../../shared/enum/reward.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { TaskModuleUrl } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  ConditionPurchase,
  OfferItem,
  PurchaseAmtCouponItem,
  PurchaseAmtGoodsItem,
  PurchaseQtyDetails,
  RewardRequestResponse,
  RewardRequestSearchCriteria,
  RewardValidate,
  SupplierCouponItem
} from '../../../shared/models/reward.model';
import { AuthGuardService } from '../../../shared/services';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  ResetRewardByIdRequestSelected,
  ResetRewardRequestValidateAction,
  RewardApproveRequestedAction,
  RewardEditSaveRequestAction,
  RewardRejectRequestedAction,
  RewardRequestByIdRequestAction,
  RewardRequestCancelRequestedAction,
  RewardRequestDeleteRequestAction,
  RewardRequestListRequestAction,
  RewardRequestSaveRequestAction,
  RewardRequestSaveResetAction,
  RewardRequestSubmitRequestAction,
  RewardRequestValidateActionRequest
} from '../../../shared/store/actions/reward-request.actions';
import {
  selectRewardError,
  selectRewardRequest,
  selectRewardRequestListCriteria,
  selectRewardValidate
} from '../../../shared/store/selectors/reward-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { PurchaseAmountCouponComponent } from '../purchase-amount-coupon/purchase-amount-coupon.component';
import { PurchaseAmountGoodsComponent } from '../purchase-amount-goods/purchase-amount-goods.component';
import { PurchaseQuantityCouponComponent } from '../purchase-quantity-coupon/purchase-quantity-coupon.component';
import { PurchaseQuantityGoodsComponent } from '../purchase-quantity-goods/purchase-quantity-goods.component';
import { RewardDetailsComponent } from '../reward-details/reward-details.component';
import { SelectStoreComponent } from '../select-store/select-store.component';
import { SupplierCouponComponent } from '../supplier-coupon/supplier-coupon.component';

@Component({
  selector: 'app-reward-request',
  templateUrl: './reward-request.component.html',
  styleUrls: ['./reward-request.component.scss']
})
export class RewardRequestComponent implements OnInit, OnDestroy {
  @ViewChild('rewardDetails', { static: false }) rewardDetails: RewardDetailsComponent;
  @ViewChild('purchaseAmountCoupon', { static: false }) purchaseAmountCoupon: PurchaseAmountCouponComponent;
  @ViewChild('purchaseAmountGoods', { static: false }) purchaseAmountGoods: PurchaseAmountGoodsComponent;
  @ViewChild('purchaseQuantityCoupon', { static: false }) purchaseQuantityCoupon: PurchaseQuantityCouponComponent;
  @ViewChild('purchaseQuantityGoods', { static: false }) purchaseQuantityGoods: PurchaseQuantityGoodsComponent;
  @ViewChild('supplierCoupon', { static: false }) supplierCoupon: SupplierCouponComponent;
  @ViewChild('selectStore', { static: false }) selectStore: SelectStoreComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RewardPageModes;
    requestId?: string;
    rewardId?: string;
    originPage?: string;
  };

  isFormDirty: boolean;
  public rewardForm: FormGroup;
  public status;
  public submitted: boolean;
  public saved: boolean;
  public conditionPurchase: ConditionPurchase;
  version: number;
  rewardType: RequestTypeEnum;
  public criteriaObject: RewardRequestSearchCriteria;
  public isRequestViewMode: boolean;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  public rewardRequestResponse: RewardRequestResponse;
  isRequestCreateMode: boolean;
  isRequestEditMode: boolean;
  public isEditFirst: boolean;

  hasViewRewardPermission: boolean;
  hasEditRewardPermission: boolean;
  hasApproveRewardPermission: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initControl();
    this.initData();

    this.hasViewRewardPermission = this.authGuardService.checkPermission(['rr_v']);
    this.hasEditRewardPermission = this.authGuardService.checkPermission(['rw_m']);
    this.hasApproveRewardPermission = this.authGuardService.checkPermission(['rw_app']);
  }
  ngAfterViewInit() {
    this.initState();
  }

  initControl() {
    this.rewardForm = this.fb.group({});
  }

  initData() {
    this.version = 0;

    this.status = RewardRequestStatusEnum.DRAFT;
    this.rewardType = RequestTypeEnum.NEW;

    this.isRequestViewMode = [RewardPageModes.REQUEST_VIEW, RewardPageModes.VIEW].includes(this.data.mode);
    this.isRequestCreateMode = this.data.mode === RewardPageModes.REQUEST_CREATE;
    this.isRequestEditMode = this.data.mode === RewardPageModes.REQUEST_EDIT;

    this.localStore
      .pipe(select(selectRewardRequestListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  initState() {
    if (this.data.requestId && this.canView) {
      this.store.dispatch(new RewardRequestByIdRequestAction({ requestId: this.data.requestId }));
    }

    this.rewardRequestView$ = this.localStore.pipe(
      select(selectRewardRequest),
      filter(data => data !== null)
    );

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        tap(req => {
          if (this.data.mode === RewardPageModes.REQUEST_EDIT) {
            this.checkRewardItem(req);
            this.isEditFirst = true;
          }
        })
      )
      .subscribe(res => {
        if (res) {
          this.rewardRequestResponse = res;
          this.version = this.rewardRequestResponse.version;
          this.status = res.status as RewardRequestStatusEnum;
          this.setFromValue(res);

          if (this.isRequestEditMode) {
            this.rewardDetails.setEditMode();
          }
        }
      });

    this.localStore.pipe(select(selectRewardValidate)).subscribe(items => {
      if (this.submitted && items !== null && items.isError !== undefined && !items.isError) {
        const data = this.prepareRewardData(this.rewardForm.getRawValue());
        this.sendToSubmit(data);
      }
    });

    this.localStore.pipe(select(selectRewardError)).subscribe(submitError => {
      if (submitError && submitError.message) {
        this.alertModal('Failed', submitError.message);
      }
    });
  }

  checkRewardItem(reward: RewardRequestResponse): void {
    if (!reward) {
      return;
    }

    const barcodes = [];
    const amounts = [];

    if (reward.purchaseAmtGoodsItems && reward.purchaseAmtGoodsItems.length > 0) {
      reward.purchaseAmtGoodsItems.forEach(v => {
        if (v.offerItem && v.offerItem.barcode) {
          barcodes.push(v.offerItem.barcode);
        }
      });
    }

    if (
      reward.purchaseQtyDetails &&
      reward.purchaseQtyDetails.offerItem &&
      reward.purchaseQtyDetails.offerItem.barcode
    ) {
      barcodes.push(reward.purchaseQtyDetails.offerItem.barcode);
    }

    if (reward.purchaseQtyItems && reward.purchaseQtyItems.length > 0) {
      reward.purchaseQtyItems.forEach(v => {
        if (v.barcode) {
          barcodes.push(v.barcode);
        }
      });
    }

    if (reward.supplierCouponItems && reward.supplierCouponItems.length > 0) {
      reward.supplierCouponItems.forEach(v => {
        if (v.barcode) {
          barcodes.push(v.barcode);
        }
      });
    }

    if (reward.purchaseAmtCouponItems && reward.purchaseAmtCouponItems.length > 0) {
      reward.purchaseAmtCouponItems.forEach(v => {
        amounts.push(v.purchaseAmount.amount);
      });
    }

    const validate = {
      requestNo: reward.requestNo ? reward.requestNo : null,
      barcodes: barcodes,
      amounts: amounts,
      details: { ...reward.details }
    } as RewardValidate;

    if (barcodes.length > 0 || amounts.length > 0) {
      this.store.dispatch(new RewardRequestValidateActionRequest(validate));
    }
  }

  setFromValue(value: RewardRequestResponse): void {
    this.conditionPurchase = {
      condition: value.details.condition,
      offer: value.details.offer
    } as ConditionPurchase;
  }
  onCancelRewardRequest(): void {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel reward request number <strong>&quot;${this.rewardRequestResponse.requestNo}&quot;</strong>?`,
        okText: 'Yes, cancel',
        label: 'Reason',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardRequestCancelRequestedAction({
              id: this.rewardRequestResponse.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onSave(): void {
    this.saved = true;
    const rawValue = this.rewardForm.getRawValue();

    if (!rawValue.details.rewardName || this.details.controls['rewardName'].invalid) {
      return;
    }
    const data = this.prepareRewardData(rawValue);

    if (this.data.mode === RewardPageModes.REQUEST_EDIT && this.data.requestId !== null) {
      this.store.dispatch(new RewardEditSaveRequestAction(data));
    } else {
      this.store.dispatch(new RewardRequestSaveRequestAction(data));
    }
  }

  onSubmit(): void {
    this.submitted = true;
    this.isEditFirst = true;
    const invalid = this.rewardForm.invalid;
    const rawValue = this.rewardForm.getRawValue();
    const msgFailed = this.messageFailed(rawValue);

    if (invalid || msgFailed) {
      if (msgFailed) {
        this.alertModal('Failed', msgFailed);
      }
      return;
    }

    const data = this.prepareRewardData(rawValue);
    if (this.canSendToSubmit(data)) {
      this.sendToSubmit(data);
    } else {
      this.checkRewardItem(data);
    }
  }

  canSendToSubmit(data) {
    return (
      this.conditionPurchase.condition === this.conditionEnum.SUPPLIER_COUPON &&
      this.conditionPurchase.offer === this.offerEnum.COUPON &&
      data.supplierCouponDetails.applyTo === 'ALL_PRODUCT'
    );
  }
  sendToSubmit(data: any) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new RewardRequestSubmitRequestAction(data));
        }
      });
  }

  messageFailed(rawValue: any): string {
    if (!this.conditionPurchase || !this.conditionPurchase.condition || !this.conditionPurchase.offer) {
      return;
    }
    if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_AMOUNT &&
      this.conditionPurchase.offer === this.offerEnum.COUPON &&
      (rawValue.purchaseAmountCoupon === null || rawValue.purchaseAmountCoupon.length === 0)
    ) {
      return 'Please add at least one purchase tier before submit.';
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_AMOUNT &&
      this.conditionPurchase.offer === this.offerEnum.GOODS &&
      (rawValue.purchaseAmountGoods === null || rawValue.purchaseAmountGoods.length === 0)
    ) {
      return 'Please add at least one purchase tier before submit.';
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_QUANTITY &&
      (rawValue.purchaseQtyItems === null || rawValue.purchaseQtyItems.length === 0)
    ) {
      return 'Please select at least one purchase item before submit.';
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_QUANTITY &&
      this.conditionPurchase.offer === this.offerEnum.GOODS &&
      (rawValue.purchaseQtyDetails === null || rawValue.purchaseQtyDetails.offerItem === null)
    ) {
      return 'Please select at least one offer goods before submit.';
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.SUPPLIER_COUPON &&
      this.conditionPurchase.offer === this.offerEnum.COUPON &&
      rawValue.supplierCouponDetails.applyTo === 'SELECT_BY_PRODUCT' &&
      (rawValue.supplierCouponItems === null || rawValue.supplierCouponItems.length === 0)
    ) {
      return 'Please select at least one offer coupon before submit.';
    } else if (
      rawValue.selectStore === null ||
      (rawValue.selectStore.selectStore === 'SELECT_BY_STORE' &&
        (!rawValue.selectStore.stores || rawValue.selectStore.stores.length === 0))
    ) {
      return 'Please select at least one store before submit.';
    }

    return null;
  }

  alertModal(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: title,
        message
      }
    });
  }

  prepareRewardData(rawData): any {
    const data = {
      id: this.data.requestId || null,
      requestNo:
        this.rewardRequestResponse && this.rewardRequestResponse.requestNo
          ? this.rewardRequestResponse.requestNo
          : null,
      type: this.rewardType,
      status: this.status,
      version: this.version,
      details: { ...rawData.details },
      purchaseAmtCouponItems: null,
      purchaseAmtGoodsItems: null,
      purchaseQtyDetails: null,
      purchaseQtyItems: null,
      supplierCouponDetails: null,
      supplierCouponItems: null,
      ...rawData.selectStore
    };

    data.details.offerFromDate = data.details.offerFromDate
      ? dateToStringCriteria(data.details.offerFromDate, true)
      : null;
    data.details.offerToDate = data.details.offerToDate
      ? dateToStringCriteria(data.details.offerToDate, false)
      : null;
    data.details.redeemFromDate = data.details.redeemFromDate
      ? dateToStringCriteria(data.details.redeemFromDate, true)
      : null;
    data.details.redeemToDate = data.details.redeemToDate
      ? dateToStringCriteria(data.details.redeemToDate, false)
      : null;
    data.details.supplier = rawData.details.supplier
      ? {
          supplierCode: rawData.details.supplier.supplierCode,
          supplierName: rawData.details.supplier.supplierName,
          branchNo: rawData.details.supplier.branchNo
        }
      : null;

    if (rawData.supplierCouponDetails) {
      data.supplierCouponDetails = {
        applyTo: rawData.supplierCouponDetails.applyTo,
        couponAmount: this.numberToAmount(rawData.supplierCouponDetails.couponAmount),
        minPerBillAmount: this.numberToAmount(rawData.supplierCouponDetails.minPerBillAmount)
      };
    }

    if (data.details.condition && data.details.offer) {
      data.purchaseAmtCouponItems = this.prepareAmountCoupon(rawData.purchaseAmountCoupon);
      data.purchaseAmtGoodsItems = this.prepareAmountGoods(rawData.purchaseAmountGoods);
      data.purchaseQtyDetails = this.prepareQuantityDetails(rawData.purchaseQtyDetails);
      data.purchaseQtyItems = this.prepareOfferItem(rawData.purchaseQtyItems);
      data.supplierCouponItems = this.prepareSupplierCouponItems(rawData.supplierCouponItems);
    }
    return data;
  }

  prepareSupplierCouponItems(items: any[]): SupplierCouponItem[] {
    if (!items || items.length === 0) {
      return null;
    }

    const supplierCoupon = [];

    items.forEach(value => {
      if (value) {
        const v = {
          barcode: value.barcode,
          articleNo: value.articleNo,
          productName: value.productName,
          unit: value.unit,
          purchaseQuantity: value.purchaseQuantity
        } as SupplierCouponItem;
        supplierCoupon.push(v);
      }
    });

    return supplierCoupon;
  }

  prepareAmountCoupon(items: any[]): PurchaseAmtCouponItem[] {
    if (!items || items.length === 0) {
      return null;
    }

    const offer = [];

    items.forEach(value => {
      if (value && (value.amount >= 0 || value.coupon >= 0 || value.minPerBill >= 0)) {
        const v = {
          purchaseAmount: this.numberToAmount(value.amount),
          couponAmount: this.numberToAmount(value.coupon),
          minPerBillAmount: this.numberToAmount(value.minPerBill)
        } as PurchaseAmtCouponItem;
        offer.push(v);
      }
    });

    return offer;
  }

  prepareAmountGoods(items: any[]): PurchaseAmtGoodsItem[] {
    if (!items || items.length === 0) {
      return null;
    }

    const offer = [];

    items.forEach(value => {
      const v = {
        purchaseAmount: this.numberToAmount(value.purchaseAmount),
        wholesalePriceAmount: this.numberToAmount(value.wholesalePriceIncVatAmount),
        offerQty: value.offerQuantity,
        redeemPrice: {
          type: value.redeemPriceType ? value.redeemPriceType : null,
          amount: this.numberToAmount(value.redeemPriceAmount)
        },
        offerItem: {
          barcode: value.barcode,
          articleNo: value.articleNo,
          productName: value.offerItem,
          unit: value.unit,
          unitFactor: value.unitFactor
        }
      };
      offer.push(v);
    });

    return offer;
  }

  prepareQuantityDetails(items: any): PurchaseQtyDetails {
    if (!items) {
      return null;
    }

    return {
      couponAmount: items.couponAmount ? this.numberToAmount(items.couponAmount) : null,
      minPerBillAmount: items.minPerBillAmount ? this.numberToAmount(items.minPerBillAmount) : null,
      offerItem: items.offerItem
        ? {
            barcode: items.offerItem.barcode,
            articleNo: items.offerItem.articleNo,
            productName: items.offerItem.productName,
            unit: items.offerItem.unit,
            unitFactor: items.offerItem.unitFactor
          }
        : null,
      wholesalePriceAmount: items.wholesalePriceAmount ? this.numberToAmount(items.wholesalePriceAmount) : null,
      offerQty: items.offerQty ? items.offerQty : null,
      redeemPriceAmount: items.redeemPriceAmount ? this.numberToAmount(items.redeemPriceAmount) : null,
      purchaseQty: items.purchaseQty ? items.purchaseQty : null,
      free: items.free
    } as PurchaseQtyDetails;
  }

  prepareOfferItem(items: any[]): OfferItem[] {
    if (!items || items.length === 0) {
      return null;
    }

    const offer = [];

    items.forEach(value => {
      offer.push({
        barcode: value.barcode,
        articleNo: value.articleNo,
        productName: value.productName,
        unit: value.unit,
        unitFactor: value.unitFactor
      });
    });

    return offer;
  }

  numberToAmount(n: number) {
    if (n === null) {
      return null;
    }
    return { amount: n, currency: 'THB' };
  }
  onChangeConditionAndOffer(event: ConditionPurchase): void {
    this.isEditFirst = false;
    this.conditionPurchase = event;

    if (this.selectStore) {
      this.selectStore.refreshControl(event);
    }
  }

  toggleToEditMode() {
    this.isRequestViewMode = false;
    this.isEditFirst = true;
    this.isRequestEditMode =
      this.data.mode === RewardPageModes.REQUEST_VIEW || this.data.mode === RewardPageModes.REQUEST_EDIT;
    this.data.mode = RewardPageModes.REQUEST_EDIT;
    this.data.title = 'Edit Reward Request';

    this.rewardDetails.setEditMode();

    if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_AMOUNT &&
      this.conditionPurchase.offer === this.offerEnum.COUPON
    ) {
      this.purchaseAmountCoupon.setEditMode();
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_AMOUNT &&
      this.conditionPurchase.offer === this.offerEnum.GOODS
    ) {
      this.purchaseAmountGoods.setEditMode();
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_QUANTITY &&
      this.conditionPurchase.offer === this.offerEnum.COUPON
    ) {
      this.purchaseQuantityCoupon.setEditMode();
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.PURCHASE_QUANTITY &&
      this.conditionPurchase.offer === this.offerEnum.GOODS
    ) {
      this.purchaseQuantityGoods.setEditMode();
    } else if (
      this.conditionPurchase.condition === this.conditionEnum.SUPPLIER_COUPON &&
      this.conditionPurchase.offer === this.offerEnum.COUPON
    ) {
      this.supplierCoupon.setEditMode();
    }

    if (this.conditionPurchase.condition && this.conditionPurchase.offer) {
      this.selectStore.setEditMode();
    }

    this.checkRewardItem(this.rewardRequestResponse);
  }

  onExit() {
    this.isFormDirty = this.rewardForm.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }

  refreshRequestList() {
    if (this.data.mode === RewardPageModes.REQUEST_CREATE) {
      this.criteriaObject.page = 0;
    }

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new RewardRequestListRequestAction(this.criteriaObject));
    }
  }

  reject() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardRejectRequestedAction({
              id: this.rewardRequestResponse.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  approve() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        isRequiredConfirmMessage: false,
        label: 'Comment',
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardApproveRequestedAction({
              id: this.rewardRequestResponse.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onDeleteRewardRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new RewardRequestDeleteRequestAction(this.rewardRequestResponse.id));
        }
      });
  }

  get canView(): boolean {
    return [RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT].includes(this.data.mode);
  }

  get pageMode() {
    return RewardPageModes;
  }

  get conditionEnum() {
    return RewardCondition;
  }
  get offerEnum() {
    return RewardOffer;
  }

  get details(): FormGroup {
    return this.rewardForm.get('details') as FormGroup;
  }

  ngOnDestroy(): void {
    this.submitted = false;
    this.store.dispatch(new RewardRequestSaveResetAction());
    this.store.dispatch(new ResetRewardByIdRequestSelected());
    this.store.dispatch(new ResetRewardRequestValidateAction());
    this.refreshRequestList();
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
  }
}
