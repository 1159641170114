import { Action } from '@ngrx/store';
import {
  RequestRewardWithComment,
  RewardActiveSummary,
  RewardListResponse,
  RewardRequestResponse,
  RewardSearchCriteria
} from '../../models/reward.model';

export enum RewardActionTypes {
  REWARD_LIST_REQUEST = '[Reward] List Request',
  REWARD_LIST_RESPONSE = '[Reward] List Response',
  REWARD_LIST_ERROR = '[Reward] List Error',

  REWARD_GET_BY_NO_REQUEST = '[Reward] Request Reward by no',
  REWARD_GET_BY_NO_RESPONSE = '[Reward] Response Reward by no',
  REWARD_GET_BY_NO_RESET = '[Reward] Response Reward by no Reset',

  REWARD_CANCEL_REQUESTED = '[Reward] Cancel Requested',
  REWARD_CANCEL_SUCCESS = '[Reward] Cancel Success',

  REWARD_ACTIVE_LIST_REQUESTED = '[Reward] Active list Request',
  REWARD_ACTIVE_LIST_RESPONSE = '[Reward] Active list Response',
  REWARD_ACTIVE_SUMMARY_REQUESTED = '[Reward] Active Summary Request',
  REWARD_ACTIVE_SUMMARY_RESPONSE = '[Reward] Active Summary Response'
}

export class RewardListRequestAction implements Action {
  readonly type = RewardActionTypes.REWARD_LIST_REQUEST;

  constructor(public payload: RewardSearchCriteria) {}
}

export class RewardListResponseAction implements Action {
  readonly type = RewardActionTypes.REWARD_LIST_RESPONSE;

  constructor(public payload: RewardListResponse) {}
}

export class RewardRequestByNoRequestAction implements Action {
  readonly type = RewardActionTypes.REWARD_GET_BY_NO_REQUEST;

  constructor(public payload: { rewardNo: string }) {}
}

export class RewardRequestByNoResponseAction implements Action {
  readonly type = RewardActionTypes.REWARD_GET_BY_NO_RESPONSE;

  constructor(public payload: { rewardView: RewardRequestResponse }) {}
}

export class RewardRequestByNoResetAction implements Action {
  readonly type = RewardActionTypes.REWARD_GET_BY_NO_RESET;
}

export class RewardCancelRequest implements Action {
  readonly type = RewardActionTypes.REWARD_CANCEL_REQUESTED;
  constructor(public payload: RequestRewardWithComment) {}
}

export class RewardCancelSuccess implements Action {
  readonly type = RewardActionTypes.REWARD_CANCEL_SUCCESS;
  constructor(public payload: { isSuccess: boolean }) {}
}

export class RewardActiveListRequestAction implements Action {
  readonly type = RewardActionTypes.REWARD_ACTIVE_LIST_REQUESTED;

  constructor(public payload: { criteria: RewardSearchCriteria }) {}
}

export class RewardActiveListResponseAction implements Action {
  readonly type = RewardActionTypes.REWARD_ACTIVE_LIST_RESPONSE;
  constructor(public payload: RewardListResponse) {}
}

export class RewardActiveSummaryRequestAction implements Action {
  readonly type = RewardActionTypes.REWARD_ACTIVE_SUMMARY_REQUESTED;
}

export class RewardActiveSummaryResponseAction implements Action {
  readonly type = RewardActionTypes.REWARD_ACTIVE_SUMMARY_RESPONSE;
  constructor(public payload: RewardActiveSummary) {}
}

export type RewardAction =
  | RewardListRequestAction
  | RewardListResponseAction
  | RewardRequestByNoRequestAction
  | RewardRequestByNoResponseAction
  | RewardRequestByNoResetAction
  | RewardCancelRequest
  | RewardCancelSuccess
  | RewardActiveListRequestAction
  | RewardActiveSummaryRequestAction
  | RewardActiveSummaryResponseAction
  | RewardActiveListResponseAction;
