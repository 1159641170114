import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SearchProductModalComponent } from '../../../shared/components/search-product-modal/search-product-modal.component';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { PromotionItemTypeEnum } from '../../../shared/enum/promotion.enum';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { PromotionTypeEnum } from '../../../shared/models';
import { BarcodeResponse } from '../../../shared/models/barcode.model';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { selectRewardRequest } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseQuantityProductItemComponent } from '../purchase-quantity-product-item/purchase-quantity-product-item.component';

@Component({
  selector: 'app-purchase-quantity-goods',
  templateUrl: './purchase-quantity-goods.component.html',
  styleUrls: ['./purchase-quantity-goods.component.scss']
})
export class PurchaseQuantityGoodsComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;

  headerRow: string[];

  promotionConRetailPrice: string;
  promotionType: PromotionTypeEnum;
  promotionItemType: PromotionItemTypeEnum;
  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;

  @ViewChild('searchProductOfferGoodsModal', { static: false })
  searchProductOfferGoodsModal: SearchProductModalComponent;

  @ViewChild('productSelect', { static: false }) productSelect: NgSelectComponent;
  @ViewChild('purchaseQuantityGoodsProductItem', { static: false })
  purchaseQuantityProductItemComponent: PurchaseQuantityProductItemComponent;

  constructor(public fb: FormBuilder, protected readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.headerRow = ['No.', 'Offer Item', 'Article', 'Barcode', 'Unit', 'Unit Factor', 'Action'];
    this.createFormPurchaseQuantityCoupon();

    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }

    if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
      this.purchaseQuantityGoods.disable();
    }
  }

  createFormPurchaseQuantityCoupon() {
    const setForm = {
      purchaseQty: this.validateRequired,
      offerItem: [{ value: null, disabled: false }, Validators.required],
      wholesalePriceAmount: this.validateRequired,
      offerQty: this.validateRequired,
      free: [{ value: null, disabled: false }],
      redeemPriceAmount: this.validateRequired
    };

    this.parentForm.addControl('purchaseQtyDetails', this.fb.group(setForm));
  }

  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        if (req && req.purchaseQtyDetails) {
          this.purchaseQuantityGoods.patchValue({
            ...req.purchaseQtyDetails,
            redeemPriceFree: req.purchaseQtyDetails.free,
            wholesalePriceAmount: req.purchaseQtyDetails.wholesalePriceAmount
              ? req.purchaseQtyDetails.wholesalePriceAmount.amount
              : null,
            redeemPriceAmount: req.purchaseQtyDetails.redeemPriceAmount
              ? req.purchaseQtyDetails.redeemPriceAmount.amount
              : null,
            minPerBillAmount: req.purchaseQtyDetails.minPerBillAmount
              ? req.purchaseQtyDetails.minPerBillAmount.amount
              : null
          });
        }

        if ([RewardPageModes.REQUEST_EDIT].includes(this.mode)) {
          this.setEditMode();
        }
      });
  }
  setEditMode() {
    this.purchaseQuantityGoods.enable();

    if (this.purchaseQuantityGoods.controls['free'].value) {
      this.purchaseQuantityGoods.controls['redeemPriceAmount'].disable();
    }
  }

  onAddProductOfferGoodsItem(productItems: any[]) {
    if (!productItems) {
      return;
    }

    productItems.forEach(productItem => {
      this.purchaseQuantityGoods.get('offerItem').setValue(productItem);
      this.purchaseQuantityGoods.get('offerItem').updateValueAndValidity({ onlySelf: false });
    });
  }

  addProductOfferGoods() {
    this.searchProductOfferGoodsModal.openSelectProductModal();
  }

  deleteProductOfferGoodsItem() {
    this.purchaseQuantityGoods.get('offerItem').setValue(null);
    this.purchaseQuantityGoods.get('offerItem').updateValueAndValidity({ onlySelf: false });
  }

  onChangeRedeemPriceFree(event: any) {
    this.purchaseQuantityGoods.controls['redeemPriceAmount'].reset(null);
    if (event.target.checked) {
      this.purchaseQuantityGoods.controls['redeemPriceAmount'].disable();
    } else {
      this.purchaseQuantityGoods.controls['redeemPriceAmount'].setValidators([
        Validators.required,
        this.notAllowZeroValidator
      ]);
      this.purchaseQuantityGoods.controls['redeemPriceAmount'].enable();
    }

    this.purchaseQuantityGoods.controls['redeemPriceAmount'].updateValueAndValidity();
  }

  get getProductOfferGoodsItem(): BarcodeResponse {
    return this.purchaseQuantityGoods.get('offerItem').value as BarcodeResponse;
  }

  get validateRequired() {
    return [{ value: null, disabled: false }, [Validators.required, this.notAllowZeroValidator]];
  }

  get notAllowZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  get pageMode() {
    return RewardPageModes;
  }

  get purchaseQuantityGoods() {
    return this.parentForm.get('purchaseQtyDetails') as FormGroup;
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('purchaseQtyDetails');
  }
}
