import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ProductItemErrorEnum } from '../enum/product-type.enum';

export const applyProductTableItemError = (
  controlItem: AbstractControl,
  errorMessage: string,
  barcodeName: string,
  productName: string
) => {
  switch (errorMessage) {
    case ProductItemErrorEnum.INVALID_BARCODE:
    case ProductItemErrorEnum.INACTIVE_BARCODE:
      controlItem.setValidators(productItemsSetValidator(errorMessage));
      controlItem.get(barcodeName).setValidators(productItemsSetValidator(errorMessage));
      break;
    case ProductItemErrorEnum.RESTRICT_ITEM:
    case ProductItemErrorEnum.STATUS_IS_DELISTED:
    case ProductItemErrorEnum.NOT_ALLOW_FIX_ASSET:
    case ProductItemErrorEnum.NOT_ALLOW_INVENTORY:
    case ProductItemErrorEnum.NOT_ALLOW_STORE_USE:
    case ProductItemErrorEnum.REWARD_PURCHASE_ITEM_DUPLICATE:
      controlItem.setValidators(productItemsSetValidator(errorMessage));
      controlItem.get(productName).setValidators(productItemsSetValidator(errorMessage));
      break;
    default:
      break;
  }
};

export const productItemsSetValidator = (errorMessage: string): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    switch (errorMessage) {
      case ProductItemErrorEnum.INVALID_BARCODE:
        return { invalidBarcode: true };
      case ProductItemErrorEnum.INACTIVE_BARCODE:
        return { isInactive: true };
      case ProductItemErrorEnum.STATUS_IS_DELISTED:
        return { isStatusDelisted: true };
      case ProductItemErrorEnum.NOT_ALLOW_FIX_ASSET:
        return { isFixAssetItem: true };
      case ProductItemErrorEnum.NOT_ALLOW_STORE_USE:
        return { isStoreUseItem: true };
      case ProductItemErrorEnum.RESTRICT_ITEM:
        return { isRestrictItem: true };
      case ProductItemErrorEnum.NOT_ALLOW_ZERO:
        if (control.value !== null) {
          return control.value === 0 ? { isZero: true } : null;
        }
        return null;
      case ProductItemErrorEnum.REWARD_PURCHASE_ITEM_DUPLICATE:
        return { isPurchaseItemDuplicate: true };
      default:
        return null;
    }
  };
};
