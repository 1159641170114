<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-store"></em>
    <h4>Store Stock Planning</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="No data" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                Order Schedule Date: {{ result$.orderDate | dateDisplay: dateFormat }}
              </label>
              <span [ngClass]="getColorStatus(result$.status)" class="card-status ml-auto">{{
                'Z8_RESULT.STATUS.' + result$.status | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Warehouse:</span>
              <span class="result-value">{{ result$.warehouse?.displayName | dashDisplay }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{
                result$.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Updated By:</span>
              <span class="result-value">{{ result$.lastModifiedBy }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Last Updated:</span>
              <span class="result-value">{{
                result$.lastModifiedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Target Store:</span>
              <span class="result-value">{{ result$.targetStore }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Actual Store:</span>
              <span class="result-value">{{
                result$.actualStore === null ? '(None)' : (result$.actualStore | numberFormatDisplay: 0)
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button type="button" class="btn btn-link" (click)="onExport(result$.reportPath, result$.targetStore)">
              <em class="icon-export"></em>Export
            </button>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="
                hasManagePermission(result$.warehouse.code) &&
                !['PARTIAL_COMPLETED', 'COMPLETED'].includes(result$.status)
              "
              (click)="onImport(result$)"
            >
              <em class="icon-import"></em>Import
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(result$)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
