import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  RequestRewardWithComment,
  RewardExportCriteria,
  RewardListResponse,
  RewardRequestResponse,
  RewardSearchCriteria
} from '../models/reward.model';
import { BaseService } from './base.service';

@Injectable()
export class RewardService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.reward;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: RewardSearchCriteria): Observable<RewardListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  getByNo(rewardNo: string): Observable<RewardRequestResponse> {
    const url = this.getFullUrl(this.envService.get, { rewardNo: rewardNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  cancel(data: RequestRewardWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  activeSummary(): Observable<any> {
    const url = this.getFullUrl(this.envService.summary);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  activeList(criteria: RewardSearchCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.active);
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  exportReward(exportCriteria: RewardExportCriteria): Observable<any> {
    const params = this.getParams(exportCriteria, true);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
