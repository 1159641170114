import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RewardContent, RewardRequestResponse, RewardSearchCriteria } from '../../models/reward.model';
import { RewardAction, RewardActionTypes } from '../actions/reward.actions';

export interface RewardState extends EntityState<RewardContent> {
  isLoading: boolean;
  criteriaObject: RewardSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  selected: RewardRequestResponse | null;
}

export const adapter: EntityAdapter<RewardContent> = createEntityAdapter<RewardContent>();

export const initialRewardListState: RewardState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function RewardReducers(state = initialRewardListState, action: RewardAction): RewardState {
  switch (action.type) {
    case RewardActionTypes.REWARD_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case RewardActionTypes.REWARD_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RewardActionTypes.REWARD_GET_BY_NO_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload.rewardView }
      };
    case RewardActionTypes.REWARD_GET_BY_NO_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
