import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PromotionRequestStatusEnum } from '../../../shared/enum/promotion.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  TaskModuleUrl,
  VoucherRequest,
  VoucherRequestPageModes,
  VoucherRequestResponse,
  VoucherRequestSearchCriteria,
  VoucherRequestViewResponse
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { campaignList } from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { MerchantService } from '../../../shared/services/merchant.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  ResetVoucherByIdRequestSelected,
  VoucherApproveRequested,
  VoucherCancelRequestedAction,
  VoucherRejectRequested,
  VoucherRequestByIdRequestAction,
  VoucherRequestListRequestAction,
  VoucherRequestSubmitRequestAction,
  VoucherRequestSubmitResetAction
} from '../../../shared/store/actions/voucher-request.actions';
import {
  selectVoucherRequest,
  selectVoucherRequestListCriteria
} from '../../../shared/store/selectors/voucher-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDate } from '../../../shared/utils/date-util';

@Component({
  selector: 'app-voucher-request',
  templateUrl: './voucher-request.component.html',
  styleUrls: ['./voucher-request.component.scss']
})
export class VoucherRequestComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: VoucherRequestPageModes;
    requestId?: string;
    voucherId?: string;
    originPage?: string;
  };

  public voucherRequestView$: Observable<VoucherRequestViewResponse>;
  isFormDirty: boolean;
  private localStore: Observable<any>;

  public voucherForm: FormGroup;
  storeList: Observable<any[]>;
  storeSearchInput$ = new Subject<string>();
  storeSearchLoading = false;
  minEffectiveDate: Date = new Date();
  maxDate: Date;
  minDate: Date;
  campaignList: any[];
  isApprove: boolean;
  public criteriaObject: VoucherRequestSearchCriteria;
  public isRequestViewMode: boolean;
  public submitted: boolean;
  public environment: { [key: string]: any };
  public status;
  public prefixVoucherNo: string;
  public voucherRequestResponse: VoucherRequestResponse;
  public hasViewVoucherPermission = false;
  public hasEditVoucherPermission = false;
  public hasApproveVoucherPermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService,
    protected merchantService: MerchantService
  ) {
    super(store, modalService, false);
    this.prefixVoucherNo = this.yearYY + '-XXXXXX-';
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: environment.dateFormat,
      showWeekNumbers: false
    } as BsDatepickerConfig;
    this.environment = environment;
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();

    this.hasViewVoucherPermission = this.authGuardService.checkPermission(['voucher_v']);
    this.hasEditVoucherPermission = this.authGuardService.checkPermission(['voucher_m']);
    this.hasApproveVoucherPermission = this.authGuardService.checkPermission(['voucher_app']);
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNullAndZeroRequired = [
      { value: null, disabled: false },
      [Validators.required, this.isZeroValidator]
    ];
    this.voucherForm = this.fb.group({
      campaign: initialNullRequired,
      store: initialNullRequired,
      effectiveDate: initialNullRequired,
      expireDate: initialNullRequired,
      minimumBuyPerBill: initialNullRequired,
      voucherValue: initialNullAndZeroRequired,
      voucherAmount: initialNullAndZeroRequired,
      limitPerBill: initialNullRequired
    });
  }

  initData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectVoucherRequestListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
    this.isRequestViewMode = [VoucherRequestPageModes.REQUEST_VIEW, VoucherRequestPageModes.VIEW].includes(
      this.data.mode
    );
    this.campaignList = campaignList;
    this.loadStore('');
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if (this.data.requestId && this.canView) {
      this.store.dispatch(new VoucherRequestByIdRequestAction({ requestId: this.data.requestId }));
    }

    this.voucherRequestView$ = this.store.pipe(select(selectVoucherRequest));
    this.voucherRequestView$.subscribe(voucherRequest => {
      if (voucherRequest) {
        this.voucherRequestResponse = voucherRequest;
        this.status = voucherRequest.status as PromotionRequestStatusEnum;
        this.setVoucherFormValue(voucherRequest);
        this.voucherForm.disable();
      }
    });
  }

  setVoucherFormValue(voucherRequest: VoucherRequestViewResponse) {
    const effectiveDate = formatDate(voucherRequest.details.effectiveDate, this.environment.dateFormat) || null;
    const expireDate = formatDate(voucherRequest.details.expireDate, this.environment.dateFormat) || null;
    this.voucherForm.controls['campaign'].setValue(voucherRequest.details.campaign);
    this.voucherForm.controls['store'].setValue(
      `${voucherRequest.details.store.code}-${voucherRequest.details.store.name}`
    );
    this.voucherForm.controls['effectiveDate'].setValue(effectiveDate);
    this.voucherForm.controls['expireDate'].setValue(expireDate);
    this.voucherForm.controls['minimumBuyPerBill'].setValue(voucherRequest.details.minimumBuyPerBill.amount);
    this.voucherForm.controls['voucherValue'].setValue(voucherRequest.details.voucherValue.amount);
    this.voucherForm.controls['voucherAmount'].setValue(voucherRequest.details.voucherAmount);

    this.voucherForm.controls['limitPerBill'].setValue(voucherRequest.details.limitPerBill);
  }

  onExit() {
    this.isFormDirty = this.voucherForm.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  loadStore(initialTerm: string) {
    this.storeList = concat(
      of([]),
      this.storeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.merchantService.searchStoreByName(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.storeSearchLoading = false;
            })
          )
        )
      )
    );
  }

  onChangeEffectiveDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date();
    }
  }

  onChangeExpireDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onSubmit(): void {
    const dateFormat = 'YYYY-MM-DD';
    this.submitted = true;
    if (this.voucherForm.invalid) {
      return;
    }

    const value = this.voucherForm.getRawValue();
    const prepareData = {
      details: {
        ...value,
        effectiveDate: formatDate(value.effectiveDate, dateFormat),
        expireDate: formatDate(value.expireDate, dateFormat),
        voucherValue: { amount: value.voucherValue, currency: 'THB' },
        store: { code: value.store.code, name: value.store.name, no: value.store.no },
        minimumBuyPerBill: { amount: value.minimumBuyPerBill, currency: 'THB' }
      }
    } as VoucherRequest;

    this.sendToSubmit(prepareData);
  }

  sendToSubmit(data: VoucherRequest) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new VoucherRequestSubmitRequestAction(data));
        }
      });
  }

  reject() {
    this.isApprove = false;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherRejectRequested({
              id: this.voucherRequestResponse.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  approve() {
    this.isApprove = true;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        isRequiredConfirmMessage: false,
        label: 'Comment',
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherApproveRequested({
              id: this.voucherRequestResponse.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onCancelVoucherRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Voucher Request Number <strong>&quot;${this.voucherRequestResponse.requestNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherCancelRequestedAction({
              id: this.voucherRequestResponse.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }

  get isZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  get isMoreThanValueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        const voucherValue = this.voucherForm.get('voucherValue').value;
        if (voucherValue !== null) {
          return Number(control.value) > Number(voucherValue) ? { isMoreThan: true } : null;
        }
      }
      return null;
    };
  }

  get yearYY() {
    return moment().format('YY');
  }

  get pageMode() {
    return VoucherRequestPageModes;
  }

  get canView(): boolean {
    return [VoucherRequestPageModes.REQUEST_VIEW, VoucherRequestPageModes.VIEW].includes(this.data.mode);
  }

  get isCreatedRequestMode(): boolean {
    return [VoucherRequestPageModes.REQUEST_CREATE].includes(this.data.mode);
  }

  ngAfterViewInit(): void {}

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetVoucherByIdRequestSelected());
    this.store.dispatch(new VoucherRequestSubmitResetAction());

    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
    super.unsubscribeBase();

    if (this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.refreshVoucherRequestList();
    }
  }
  refreshVoucherRequestList() {
    if (this.isCreatedRequestMode) {
      this.criteriaObject.page = 0;
    }
    this.store.dispatch(new VoucherRequestListRequestAction(this.criteriaObject));
  }
}
