import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, fromEventPattern, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { FileModuleEnum } from '../../enum/file-url.enum';

export class ICustomFile extends File {
  errors?: { [key: string]: any };
  imgSrc?: string;
  filePath?: string | ArrayBuffer | null;
  id?: number;
  imgHeight?: number;
  imgWidth?: number;
  isImg?: boolean;
  imgLoadReplay?: ReplaySubject<[Event, ProgressEvent]>;
  textContent?: string;
  textLoadReplay?: ReplaySubject<ProgressEvent>;
}

type allowedType = RegExp | string | string[];

@Component({
  selector: 'app-file-submit-before-upload',
  templateUrl: './file-submit-before-upload.component.html',
  styleUrls: ['./file-submit-before-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileSubmitBeforeUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileSubmitBeforeUploadComponent),
      multi: true
    }
  ]
})
export class FileSubmitBeforeUploadComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  @HostBinding('class.is-invalid') get invalid() {
    return this.hasError;
  }

  constructor(
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly modalService: BsModalService,
    private readonly logger: NGXLogger
  ) {
    this.disabled = false;
    this.hasError = false;
    this.hasChange = false;
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  @Input()
  set allowedExt(value: allowedType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  get allowedExt(): allowedType {
    return this._allowedExt;
  }

  private _allowedExt: allowedType;
  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  validator: AsyncValidatorFn;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;

  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: allowedType;
  @Input() size: number;
  @Input() hasError: boolean;
  @Input() hasChange: boolean;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() controlName: string;
  @Input() initialFileList: ICustomFile[];
  @Input() isNew: boolean;
  @Input() fileTypeErrorTxt: string;
  @Input() fileSizeErrorTxt: string;
  @Input() descriptionTxt: string | null;
  @Input() isAddable: boolean;
  @Input() index: number;
  @Input() maxImages: number;
  @Input() lastIndex: number;
  @Input() fileModule: FileModuleEnum;

  @Output() uploadFileContent = new EventEmitter<any>();

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit() {
    this.fileList = this.initialFileList || null;
  }

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(fileList): void {
    if (fileList && fileList.length) {
      fileList
        .filter(file => !(file.filePath instanceof ArrayBuffer))
        .forEach(file => {
          this.fileService(file.filePath as string)
            .pipe(filter(data => Boolean(data)))
            .subscribe(signedUrlObject => (file.filePath = signedUrlObject.signedUrl));
        });
    }

    this.value = this.fileList = this.inputValue = fileList;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    if (this.value) {
      this.value.forEach(imgObj => {
        const regexpImageType = new RegExp(env.regexp.imageType, 'ig');
        imgObj.isImg = regexpImageType.test(imgObj.name);
      });
    }
    this.ngChange(this.value);
    this.ngTouched();

    return (customFiles: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const file of customFiles) {
        if (this.withMeta && FileReader) {
          const fileReader = new FileReader();
          this.generateFileMeta(file, fileReader);
        }
        file.errors = {};
        fileArr.push(file);
      }

      fn(fileArr);
    };
  }

  private generateFileMeta(customFile: ICustomFile, fileReader: FileReader) {
    if (customFile.type.match(/text.*/)) {
      customFile.textLoadReplay = this.setText(customFile, fileReader);
    } else if (customFile.type.match(/image.*/)) {
      customFile.imgLoadReplay = this.setImage(customFile, fileReader);
    }
  }

  private setText(customFile: ICustomFile, fileReader: FileReader): ReplaySubject<ProgressEvent> {
    const onloadReplay = new ReplaySubject<ProgressEvent>(1);
    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fileReader.addEventListener('load', handler),
      (handler: any) => fileReader.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    frLoadObs.subscribe(onloadReplay);
    frLoadObs.pipe(take(1)).subscribe(() => {
      customFile.textContent = fileReader.result + '';
    });

    fileReader.readAsText(customFile);

    return onloadReplay;
  }

  private setImage(customFile: ICustomFile, fileReader: FileReader): ReplaySubject<[Event, ProgressEvent]> {
    customFile.isImg = true;

    const img = new Image();

    const imgLoadObs = fromEventPattern<Event>(
      (handler: any) => img.addEventListener('load', handler),
      (handler: any) => img.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const frLoadObs = fromEventPattern<ProgressEvent>(
      (handler: any) => fileReader.addEventListener('load', handler),
      (handler: any) => fileReader.removeEventListener('load', handler)
    ).pipe(take(1), shareReplay());

    const onloadReplay = new ReplaySubject<[Event, ProgressEvent]>(1);
    const observables = [imgLoadObs, frLoadObs];

    forkJoin(observables)
      .pipe(take(1))
      .subscribe(onloadReplay);

    imgLoadObs.pipe(take(1)).subscribe(() => {
      customFile.imgHeight = img.height;
      customFile.imgWidth = img.width;
    });

    frLoadObs.pipe(take(1)).subscribe(() => {});

    fileReader.readAsDataURL(customFile);

    return onloadReplay;
  }

  private generateRegExp(pattern: allowedType): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }

    return null;
  }

  validate(files: AbstractControl) {
    if (!files.value || !files.value.length || files.disabled) {
      return null;
    }
    this.fileList = files.value;
  }

  onClickDelete() {
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue([]);
  }

  uploadFile() {
    const files = this.controlContainer.control.get(this.controlName);

    if (!files.value || !files.value.length || files.disabled) {
      return null;
    }

    const errors: ValidationErrors = {};
    const loaders: ReplaySubject<ProgressEvent>[] = [];

    for (const file of files.value) {
      if (file.isImg && (this.maxWidth || this.maxHeight)) {
        loaders.push(
          file.imgLoadReplay.pipe(
            take(1),
            map((event: ProgressEvent) => {
              if (this.maxWidth && file.imgWidth > this.maxWidth) {
                file.errors['imageWidth'] = true;
                errors['imageWidth'] = true;
              }
              if (this.maxHeight && file.imgHeight > this.maxHeight) {
                file.errors['imageHeight'] = true;
                errors['imageHeight'] = true;
              }
              return event;
            })
          )
        );
      }

      if (!this.allowedExt && !this.allowedTypes) {
        continue;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      if (extP && !extP.test(file.name)) {
        file.errors['fileExt'] = true;
        errors['fileExt'] = true;
      }

      if (typeP && file.type && !typeP.test(file.type)) {
        file.errors['fileType'] = true;
        errors['fileType'] = true;
      }
    }

    if (this.isLoad) {
      return;
    }

    this.fileList = files.value;
    if (Object.keys(errors).length) {
      this.controlContainer.control.get(this.controlName).setErrors(errors);
      return;
    }

    this.uploadFileContent.emit({ files });
  }
}
