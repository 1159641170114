<div class="row" [formGroup]="orderSchedule">
  <div
    formArrayName="orderScheduleList"
    *ngFor="let tdStore of orderScheduleList.controls; let i = index"
    [ngClass]="{
      'col-lg-7': page !== merchantPage.STORE_EDIT && page !== merchantPage.STORE_EDIT_ORDER_SCHEDULE,
      'full-width': page === merchantPage.STORE_EDIT || page === merchantPage.STORE_EDIT_ORDER_SCHEDULE
    }"
  >
    <div [formGroupName]="i">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md mb-3">
              <label for="warehouse">Warehouse<span class="text-danger">*</span></label>
              <ng-select
                id="warehouse"
                placeholder="Please select..."
                [items]="warehouseList$ | async"
                [searchable]="false"
                [clearable]="false"
                bindLabel="warehouseNameDisplay"
                bindValue="code"
                formControlName="warehouseCode"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.warehouseCode.errors }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.warehouseCode.errors?.required" class="invalid-feedback">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
            <div class="col-md mb-3">
              <label for="minOrder">Minimum Order (THB)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [negativeSign]="false"
                [format]="'0,0.00'"
                id="minOrder"
                formControlName="minOrder"
                class="form-control"
                placeholder="Minimum Order (THB)"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.minOrder.errors }"
              ></app-numeric-textbox>
              <div *ngIf="submitted && tdStore.controls.minOrder.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.minOrder.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-1 mb-3"></div>
          </div>
        </div>
      </div>
      <app-order-policy-schedule
        #orderPolicySchedule
        [parentForm]="tdStore"
        [submitted]="submitted"
        [mode]="mode"
        [index]="i"
        [page]="page"
      ></app-order-policy-schedule>
    </div>
  </div>
</div>
