<div [formGroup]="parentForm">
  <div formGroupName="details">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="rewardName">Reward Name<span class="text-danger">*</span></label>
        <input
          id="rewardName"
          name="rewardName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="rewardName"
          placeholder="Reward Name"
          trim="blur"
          [ngClass]="{
            'is-invalid': (saved || submitted) && formDetails.controls.rewardName.errors
          }"
        />
        <div *ngIf="(saved || submitted) && formDetails.controls.rewardName.errors" class="invalid-feedback">
          <div *ngIf="formDetails.controls.rewardName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="condition">Condition<span class="text-danger">*</span></label>
        <ng-select
          id="condition"
          placeholder="Please select..."
          [searchable]="false"
          [clearable]="false"
          [items]="conditionSelect"
          formControlName="condition"
          bindLabel="label"
          bindValue="value"
          (change)="onchangeCondition($event)"
          [ngClass]="{
            'is-invalid': submitted && formDetails.controls.condition.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && formDetails.controls.condition.errors" class="invalid-feedback">
          <div *ngIf="formDetails.controls.condition.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="offer">Offer<span class="text-danger">*</span></label>
        <ng-select
          id="offer"
          placeholder="Please select..."
          [searchable]="false"
          [clearable]="false"
          [items]="offerSelect"
          formControlName="offer"
          bindLabel="label"
          bindValue="value"
          (change)="onchangeConditionAndOffer()"
          [ngClass]="{
            'is-invalid': submitted && formDetails.controls.offer.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && formDetails.controls.offer.errors" class="invalid-feedback">
          <div *ngIf="formDetails.controls.offer.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="displaySupplier()">
        <div class="col-md-6 mb-3">
          <label for="supplier">Supplier<span class="text-danger">*</span></label>
          <ng-select
            id="supplier"
            [items]="supplierList | async"
            bindLabel="supplierNameDisplay"
            dropdownPosition="auto"
            labelForId="supplier"
            placeholder="Please select..."
            notFoundText="No Data"
            [loading]="supplierSearchLoading"
            [typeahead]="supplierSearchInput$"
            [virtualScroll]="true"
            formControlName="supplier"
            [ngClass]="{ 'is-invalid': submitted && formDetails.controls.supplier.errors }"
          >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <strong>Supplier Name:</strong>
                <span [ngOptionHighlight]="search" class="ml-1">
                  {{ item.supplierName }}
                </span>
              </div>
              <small>
                <strong>Vendor No:</strong
                ><span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span
                >{{ ' | ' }} <strong>Branch No:</strong
                ><span [ngOptionHighlight]="search" class="ml-1">{{ item.branchNo }}</span>
              </small>
            </ng-template>
          </ng-select>
          <div *ngIf="submitted && formDetails.controls.supplier.errors" class="invalid-feedback">
            <div *ngIf="formDetails.controls.supplier.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="ref1">Ref. 1<span class="text-danger">*</span></label>
          <input
            id="ref1"
            name="ref1"
            type="text"
            class="form-control"
            maxlength="100"
            formControlName="ref1"
            placeholder="Ref. 1"
            trim="blur"
            [ngClass]="{
              'is-invalid': submitted && formDetails.controls.ref1.errors
            }"
          />
          <div *ngIf="submitted && formDetails.controls.ref1.errors" class="invalid-feedback">
            <div *ngIf="formDetails.controls.ref1.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="ref2">Ref. 2</label>
          <input
            id="ref2"
            name="ref2"
            type="text"
            class="form-control"
            maxlength="100"
            formControlName="ref2"
            placeholder="Ref. 2"
            trim="blur"
            [ngClass]="{
              'is-invalid': (saved || submitted) && formDetails.controls.ref2.errors
            }"
          />
          <div *ngIf="(saved || submitted) && formDetails.controls.ref2.errors" class="invalid-feedback">
            <div *ngIf="formDetails.controls.ref2.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-md-3 mb-3">
        <label for="alwaysOffer">Always Offer<span class="text-danger">*</span></label>
        <ng-select
          id="alwaysOffer"
          placeholder="Please select..."
          [searchable]="false"
          [clearable]="false"
          [items]="alwaysOfferSelect"
          formControlName="alwaysOffer"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{
            'is-invalid': submitted && formDetails.controls.alwaysOffer.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && formDetails.controls.alwaysOffer.errors" class="invalid-feedback">
          <div *ngIf="formDetails.controls.alwaysOffer.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-0">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="offerFromDate">Offer from Date<span class="text-danger">*</span></label>
            <input
              id="offerFromDate"
              formControlName="offerFromDate"
              type="text"
              autocomplete="off"
              placeholder="{{ environment.dateFormat }}"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              (bsValueChange)="onChangeOfferFromDate($event)"
              [maxDate]="offerMaxDate"
              [minDate]="currentDate"
              readonly
              [ngClass]="{
                'is-invalid': submitted && formDetails.controls.offerFromDate.errors
              }"
            />
            <label for="offerFromDate" class="icon-calendar btn-calendar"></label>
            <div *ngIf="submitted && formDetails.controls.offerFromDate.errors" class="invalid-feedback">
              <div *ngIf="formDetails.controls.offerFromDate.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="formDetails.controls.offerFromDate.errors.beforeToday">
                Date is invalid.
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="offerFromDate">Offer to Date<span class="text-danger">*</span></label>
            <input
              id="offerToDate"
              formControlName="offerToDate"
              type="text"
              autocomplete="off"
              placeholder="{{ environment.dateFormat }}"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              readonly
              (bsValueChange)="onChangeOfferToDate($event)"
              [minDate]="offerMinDate"
              [ngClass]="{
                'is-invalid': submitted && formDetails.controls.offerToDate.errors
              }"
            />
            <label for="offerToDate" class="icon-calendar btn-calendar"></label>
            <div *ngIf="submitted && formDetails.controls.offerToDate.errors" class="invalid-feedback">
              <div *ngIf="formDetails.controls.offerToDate.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="limitPerBill">Redeem Limit Per Bill<span class="text-danger">*</span></label>
        <div class="float-right">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input-label checkbox-modal"
              id="unlimitedPerBill"
              formControlName="unlimitedPerBill"
              (click)="onChangeUnlimited($event)"
            />
          </div>
          <label for="unlimitedPerBill">
            Unlimited
          </label>
        </div>
        <app-numeric-textbox
          id="limitPerBill"
          [decimals]="0"
          [maxValue]="999"
          [minValue]="1"
          [min]="1"
          maxlength="3"
          formControlName="limitPerBill"
          [format]="'0,0'"
          [negativeSign]="false"
          placeholder="Redeem Limit Per Bill"
          [ngClass]="{
            'is-invalid': submitted && formDetails.controls.limitPerBill.errors
          }"
        ></app-numeric-textbox>
        <div *ngIf="submitted && formDetails.controls.limitPerBill.errors" class="invalid-feedback">
          <div *ngIf="formDetails.controls.limitPerBill.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="formDetails.controls.limitPerBill.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="redeemFromDate">Redeem from Date<span class="text-danger">*</span></label>
            <input
              id="redeemFromDate"
              formControlName="redeemFromDate"
              type="text"
              autocomplete="off"
              placeholder="{{ environment.dateFormat }}"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [maxDate]="redeemMaxDate"
              [minDate]="redeemCurrentDate"
              (bsValueChange)="onChangeRedeemDateFrom($event)"
              readonly
              [ngClass]="{
                'is-invalid': submitted && formDetails.controls.redeemFromDate.errors
              }"
            />
            <label for="redeemFromDate" class="icon-calendar btn-calendar"></label>
            <div *ngIf="submitted && formDetails.controls.redeemFromDate.errors" class="invalid-feedback">
              <div *ngIf="formDetails.controls.redeemFromDate.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="formDetails.controls.redeemFromDate.errors.beforeToday">
                Date is invalid.
              </div>
              <div *ngIf="formDetails.controls.redeemFromDate.errors.beforeOfferFrom">
                Must not less than Offer from Date.
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="redeemToDate">Redeem to Date<span class="text-danger">*</span></label>
            <input
              id="redeemToDate"
              formControlName="redeemToDate"
              type="text"
              autocomplete="off"
              placeholder="{{ environment.dateFormat }}"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateRedeemToDateConfig"
              [minDate]="redeemMinDate"
              readonly
              placement="bottom"
              (bsValueChange)="onChangeRedeemToDate($event)"
              [ngClass]="{
                'is-invalid': submitted && formDetails.controls.redeemToDate.errors
              }"
            />
            <label for="redeemToDate" class="icon-calendar btn-calendar"></label>
            <div *ngIf="submitted && formDetails.controls.redeemToDate.errors" class="invalid-feedback">
              <div *ngIf="formDetails.controls.redeemToDate.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="formDetails.controls.redeemToDate.errors.beforeOfferTo">
                Must not less than Offer to Date.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
