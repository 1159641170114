import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, from, Observable, Subscription } from 'rxjs';
import { concatMap, delay, expand, mergeMap, reduce, retry, take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithOptionModalComponent } from '../../../shared/layouts/modals/confirm-with-option-modal/confirm-with-option-modal.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { Z8ConditionsExistingResponse } from '../../../shared/models/z8-conditions.model';
import {
  Z8ParameterContent,
  Z8ParameterList,
  Z8ParameterRequest,
  Z8ParameterRequestUpdate
} from '../../../shared/models/z8-parameter.model';
import { AuthGuardService } from '../../../shared/services';
import { Z8ConditionsService } from '../../../shared/services/z8-conditions.service';
import { Z8ParameterDataService } from '../../../shared/services/z8-parameter-data.service';
import { Z8ParameterService } from '../../../shared/services/z8-parameter.service';
import { Z8ParameterListRequestAction } from '../../../shared/store/actions/z8-parameter.actions';
import { selectZ8ParameterById } from '../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { EditParameterComponent } from './edit-parameter/edit-parameter.component';

@Component({
  selector: 'app-view-parameter',
  templateUrl: './view-parameter.component.html',
  styleUrls: ['./view-parameter.component.scss']
})
export class ViewParameterComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('modalProgress', { static: false }) modalProgress: ModalDirective;
  @ViewChild('modalEditOption', { static: false }) modalEditOption: ModalDirective;
  @ViewChild('editParameter', { static: false }) editParameter: EditParameterComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: { title: string; mode: RequestPageModesEnum; guid: string };

  bsModalRef: BsModalRef;
  public submitted: boolean;

  public requestPageMode = RequestPageModesEnum;
  public mode: string = RequestPageModesEnum.REQUEST_VIEW;

  public dateTimeDisplay = environment.dateTimeDisplay;
  public parameterInfo: Z8ParameterList;
  public progressMode: string;
  public validateObj: any = [];

  private localStore: Observable<any>;
  private chunkSubmit$: Subscription;
  private guid: string;
  private chunkSize: number;
  private chunkNo: number;
  private chunkCompleted: number;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected bsModalService: BsModalService,
    private readonly z8ParameterDataService: Z8ParameterDataService,
    private readonly z8ParameterService: Z8ParameterService,
    private readonly z8ConditionsService: Z8ConditionsService,
    protected readonly authGuardService: AuthGuardService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, true);
  }

  ngOnDestroy() {
    if (this.chunkSubmit$) {
      this.chunkSubmit$.unsubscribe();
    }
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectZ8ParameterById)).subscribe(data => {
      this.parameterInfo = data;
    });

    if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
      this.onTriggerEdit();
    }
  }

  onSubmit() {
    if (this.mode === RequestPageModesEnum.REQUEST_VIEW) {
      return;
    }

    this.submitted = true;

    const data = this.z8ParameterDataService.dataList;

    if (data.length === 0) {
      this.showModalError('Please change at least 1 condition.');

      return;
    }

    this.handleConfirm(data);
  }

  onCancel() {
    if (this.mode === RequestPageModesEnum.REQUEST_EDIT && this.editParameter.data.length) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  chunkLoader() {
    this.getLoadingResult().subscribe(
      () => {},
      errorResponse => {
        this.logger.error(errorResponse);
        this.modalProgress.hide();

        this.showModalError('There was an issue on the server or network connection. Please try again.');
      },
      () => {
        this.sleep(1000);
        this.modalProgress.hide();
      }
    );
  }

  getLoadingResult(): Observable<any> {
    const criteriaObject = {
      size: environment.loadExistingChunkSize,
      guid: this.data.guid,
      chunkNo: this.chunkNo
    };

    return this.z8ConditionsService.loadExisting(criteriaObject).pipe(
      expand((res: Z8ConditionsExistingResponse) => {
        return this.isLoadNextPage(res) ? this.getLoadingResult() : EMPTY;
      }),
      concatMap((res: Z8ConditionsExistingResponse) => {
        if (res && res.z8Conditions && res.z8Conditions.length > 0) {
          this.chunkCompleted++;

          res.z8Conditions.forEach(v => {
            const key = this.getItemKey(v);
            v.id = uuid.v4();

            this.validateObj[key] = [
              ...(this.validateObj[key] || []),
              {
                startDate: v.startDate,
                endDate: v.endDate
              }
            ];
          });

          this.editParameter.validateObj = { ...this.validateObj };

          this.z8ParameterDataService.addData(res.z8Conditions);

          return res.z8Conditions;
        }

        return EMPTY;
      }),
      reduce((acc, val) => {
        acc.push(val);

        return acc;
      }, [])
    );
  }

  getItemKey(item: Z8ParameterContent) {
    return `${item.storeCode === 'ALL' ? 'All Stores' : item.storeCode}_${this.translate.instant(
      'Z8_PARAMETER_REQUEST.PRODUCT_LEVEL.' + item.productLevel
    )}_${item.productValue.code}`;
  }

  isLoadNextPage(res: Z8ConditionsExistingResponse) {
    if (res.totalConditions) {
      this.chunkSize =
        res.totalConditions > environment.loadExistingChunkSize
          ? Math.ceil(res.totalConditions / environment.loadExistingChunkSize)
          : 1;
    }

    if (this.chunkNo < this.chunkSize) {
      this.chunkNo++;

      return true;
    }

    return false;
  }

  chunkSubmit(data: Z8ParameterContent[]) {
    const totalItems = data.length;
    this.guid = uuid.v4();
    this.chunkSize =
      totalItems > environment.uploadChunkSize ? Math.ceil(totalItems / environment.uploadChunkSize) : 1;
    this.chunkNo = 1;
    this.chunkCompleted = 0;
    this.openProgressModal();

    const z8ParameterRequests: Array<Z8ParameterRequest> = [];

    for (let i = 0; i < this.chunkSize; i++) {
      const z8PameterRequest: Z8ParameterRequest = new Z8ParameterRequest();

      z8PameterRequest.guid = this.guid;
      z8PameterRequest.chunkSize = this.chunkSize;
      z8PameterRequest.parameter = this.getParameterType();
      z8PameterRequest.chunkNo = this.chunkNo;
      z8PameterRequest.totalChunkSize = environment.uploadChunkSize;
      z8PameterRequest.z8Conditions = this.paginate(data, environment.uploadChunkSize, this.chunkNo);

      z8ParameterRequests.push(z8PameterRequest);

      this.chunkNo++;
    }

    this.chunkSubmit$ = from(z8ParameterRequests)
      .pipe(
        mergeMap(
          request => this.z8ParameterService.submit(request).pipe(delay(200), retry(2)),
          environment.uploadConcurrent
        )
      )
      .subscribe(
        response => {
          this.chunkCompleted++;
          this.logger.log(
            `Chunk Size : ${this.chunkSize}, Chunk Submitted : ${
              this.chunkCompleted
            }, Percent Completed: ${this.progressPercentage()}`,
            response
          );
        },
        errorResponse => {
          this.logger.error(errorResponse);
          this.modalProgress.hide();

          this.showModalError('There was an issue on the server or network connection. Please try again.');
          this.updateSubmit('CANCELLED');
        },
        () => {
          if (this.chunkCompleted === this.chunkSize) {
            this.sleep(1000);

            this.modalProgress.hide();
            this.updateSubmit('COMPLETED');
          }
        }
      );
  }

  updateSubmit(status: string) {
    const z8ParameterRequestUpdate: Z8ParameterRequestUpdate = new Z8ParameterRequestUpdate();
    z8ParameterRequestUpdate.parameter = this.getParameterType();
    z8ParameterRequestUpdate.guid = this.guid;
    z8ParameterRequestUpdate.status = status;

    this.z8ParameterService.updateSubmit(z8ParameterRequestUpdate).subscribe(
      () => {
        this.logger.log('Submit Status : ' + status);

        if (status === 'COMPLETED') {
          this.alertModalSuccess();
        } else {
          this.chunkCompleted = 0;
        }
      },
      errorResponse => {
        this.logger.error(errorResponse);
      }
    );
  }

  cancelProgressSubmit() {
    if (this.chunkSubmit$) {
      this.chunkSubmit$.unsubscribe();
    }

    this.modalProgress.hide();
    this.updateSubmit('CANCELLED');
  }

  tryAgain() {}

  getProgressText() {
    const text = this.progressMode === 'EXISTING' ? 'Loading' : 'Completed';
    return `${text} : ${this.progressPercentage()}`;
  }

  getProgressIcon() {
    return this.progressMode === 'EXISTING' ? 'icon-download' : 'icon-upload';
  }

  progressPercentage() {
    const percentage = (this.chunkCompleted / this.chunkSize) * 100;
    return `${percentage.toFixed(0)}%`;
  }

  openProgressModal() {
    this.modalProgress.show();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  paginate(array, pageSize, pageNumber) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  getParameterType() {
    switch (this.parameterInfo.name) {
      case 'Product Factor':
        return 'PRODUCT_FACTOR';
      case 'Weekend':
        return 'WEEKEND';
      case 'Beginning Month':
        return 'BEGINNING_MONTH';
      case 'Promotion':
        return 'PROMOTION';
      case 'Max Target':
        return 'MAX_TARGET';
      case 'Rounding':
        return 'ROUNDING';
      default:
        return '';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  alertModalSuccess() {
    const initialState = {
      title: 'Success',
      message: 'The request has been created.'
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(new Z8ParameterListRequestAction({ page: 0, size: 20 }));

        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
      }
    });
  }

  handleConfirm(data: Z8ParameterContent[]) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.progressMode = 'NEW';
          this.chunkSubmit(data);
        }
      });
  }

  onTriggerEdit() {
    const confirmModalRef = this.modalService.show(ConfirmWithOptionModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Please select your edit type to continue.',
        okText: 'OK',
        okClass: 'btn btn-primary',
        options: [
          {
            label: 'Keep existing data',
            value: 'EXISTING'
          },
          {
            label: 'New data',
            value: 'NEW'
          }
        ]
      }
    });

    confirmModalRef.content.response.pipe(untilComponentDestroyed(this)).subscribe((result: any) => {
      if (result && result.action === ModalButtonResponseEnum.OK) {
        this.progressMode = result.value ? result.value : '';

        this.mode = RequestPageModesEnum.REQUEST_EDIT;
        this.data.title = `Edit ${this.parameterInfo.name} Parameter`;

        if (this.progressMode === 'EXISTING') {
          this.chunkNo = 1;
          this.chunkCompleted = 0;
          this.chunkSize = 1;

          this.openProgressModal();
        }
      }
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      if (this.progressMode === 'EXISTING') {
        this.chunkLoader();
      }
    });
  }

  hasEditPermission() {
    return this.authGuardService.checkPermission(['stk_plan_m_sto']);
  }

  doAfterVersionAlertModal() {}
}
