import { Routes } from '@angular/router';
import { AssortmentProductListComponent } from './pages/assortment-product/assortment-product-list/assortment-product-list.component';
import { AssortmentRequestListComponent } from './pages/assortment-request/assortment-request-list/assortment-request-list.component';
import { AssortmentRequestComponent } from './pages/assortment-request/assortment-request/assortment-request.component';
import { ClaimRequestListComponent } from './pages/claim-request/claim-request-list/claim-request-list.component';
import { ClaimRequestViewComponent } from './pages/claim-request/claim-request-view/claim-request-view.component';
import { MyTaskComponent } from './pages/dashboard/my-task/my-task.component';
import { DeliveryOrderEditComponent } from './pages/delivery-order/delivery-order-edit/delivery-order-edit.component';
import { DeliveryOrderListComponent } from './pages/delivery-order/delivery-order-list/delivery-order-list.component';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { LoginRightComponent } from './pages/login/login-right.component';
import { ManageStockListComponent } from './pages/manage-stock/manage-stock-list/manage-stock-list.component';
import { MemberCardListComponent } from './pages/member-card/member-card-list/member-card-list.component';
import { MerchantListComponent } from './pages/merchant/merchant-list/merchant-list.component';
import { MerchantRequestListComponent } from './pages/merchant/merchant-request-list/merchant-request-list.component';
import { MerchantRequestComponent } from './pages/merchant/merchant-request/merchant-request.component';
import { ViewMerchantComponent } from './pages/merchant/view-merchant/view-merchant.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderRequestListComponent } from './pages/order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from './pages/order/order-request/order-request.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { ClassMarkupComponent } from './pages/price-setting/class-markup/class-markup.component';
import { StackPricingViewComponent } from './pages/price-setting/stack-pricing/stack-pricing-view/stack-pricing-view.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductViewComponent } from './pages/products/product-view/product-view.component';
import { MonthlyPartnerProfitSharingListComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-list/monthly-partner-profit-sharing-list.component';
import { ProfitSharingListComponent } from './pages/profit-sharing/profit-sharing-list/profit-sharing-list.component';
import { ProfitSharingViewComponent } from './pages/profit-sharing/profit-sharing-view/profit-sharing-view.component';
import { PromotionListComponent } from './pages/promotion/promotion-list/promotion-list.component';
import { PromotionRequestListComponent } from './pages/promotion/promotion-request-list/promotion-request-list.component';
import { PromotionRequestComponent } from './pages/promotion/promotion-request/promotion-request.component';
import { GoodsReceiveListComponent } from './pages/purchase/goods-receive-list/goods-receive-list.component';
import { GoodsReceiveViewComponent } from './pages/purchase/goods-receive-view/goods-receive-view.component';
import { PurchaseOrderListComponent } from './pages/purchase/purchase-order-list/purchase-order-list.component';
import { PurchaseRequestListComponent } from './pages/purchase/purchase-request-list/purchase-request-list.component';
import { PurchaseRequestViewComponent } from './pages/purchase/purchase-request-view/purchase-request-view.component';
import { ReceiveOrderListComponent } from './pages/receive-order/receive-order-list/receive-order-list.component';
import { ReportListComponent } from './pages/reports/report-list.component';
import { RewardActiveListComponent } from './pages/reward/reward-active-list/reward-active-list.component';
import { RewardListComponent } from './pages/reward/reward-list/reward-list.component';
import { RewardRequestListComponent } from './pages/reward/reward-request-list/reward-request-list.component';
import { RewardRequestComponent } from './pages/reward/reward-request/reward-request.component';
import { RewardViewComponent } from './pages/reward/reward-view/reward-view.component';
import { RoleCreateComponent } from './pages/roles/role-create/role-create.component';
import { RoleListComponent } from './pages/roles/role-list/role-list.component';
import { ShelfListComponent } from './pages/shelf/shelf-list/shelf-list.component';
import { ShelfRequestListComponent } from './pages/shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from './pages/shelf/shelf-request/shelf-request.component';
import { ViewShelfComponent } from './pages/shelf/view-shelf/view-shelf.component';
import { ShipmentListComponent } from './pages/shipment/shipment-list/shipment-list.component';
import { StockInformationListComponent } from './pages/stock/stock-information-list/stock-information-list.component';
import { StockInformationComponent } from './pages/stock/stock-information/stock-information.component';
import { StoreAssortmentListComponent } from './pages/store-assortment/store-assortment-list/store-assortment-list.component';
import { StoreAssortmentRequestListComponent } from './pages/store-assortment/store-assortment-request-list/store-assortment-request-list.component';
import { StoreAssortmentRequestViewComponent } from './pages/store-assortment/store-assortment-request-view/store-assortment-request-view.component';
import { StoreAssortmentRequestComponent } from './pages/store-assortment/store-assortment-request/store-assortment-request.component';
import { ViewStoreAssortmentComponent } from './pages/store-assortment/view-store-assortment/view-store-assortment.component';
import { StoreGroupCreateComponent } from './pages/store-group/store-group-create/store-group-create.component';
import { StoreGroupListComponent } from './pages/store-group/store-group-list/store-group-list.component';
import { ViewParameterRequestComponent } from './pages/store-stock-planning/view-parameter-request/view-parameter-request.component';
import { ViewParameterComponent } from './pages/store-stock-planning/view-parameter/view-parameter.component';
import { Z8ParameterListComponent } from './pages/store-stock-planning/z8-parameter-list/z8-parameter-list.component';
import { Z8ParameterRequestListComponent } from './pages/store-stock-planning/z8-parameter-request-list/z8-parameter-request-list.component';
import { Z8ResultComponent } from './pages/store-stock-planning/z8-result/z8-result.component';
import { StoreListComponent } from './pages/store/store-list/store-list.component';
import { StoreRequestListComponent } from './pages/store/store-request-list/store-request-list.component';
import { StoreRequestComponent } from './pages/store/store-request/store-request.component';
import { ViewStoreComponent } from './pages/store/view-store/view-store.component';
import { UserCreateComponent } from './pages/user/user-create/user-create.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { VoucherListComponent } from './pages/voucher/voucher-list/voucher-list.component';
import { VoucherRequestListComponent } from './pages/voucher/voucher-request-list/voucher-request-list.component';
import { VoucherRequestComponent } from './pages/voucher/voucher-request/voucher-request.component';
import { LoginComponent } from './shared/layouts';
import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { grManagePermissions, grViewPermissions, poManagePermissions, poViewPermissions, prApprovePermissions, prManagePermissions, prViewPermissions } from './shared/permissions/permissions';
import { AuthGuardService } from './shared/services';
const ɵ0 = {
    title: 'HOME',
    group: 'HOME',
    icon: 'icon-td-home',
    active: true,
    order: 1,
    isShowOnMenu: true
}, ɵ1 = {
    title: 'MERCHANT_STORE',
    group: 'STORE',
    icon: 'icon-td-store',
    active: false,
    order: 2,
    isShowOnMenu: true
}, ɵ2 = {
    title: 'MERCHANT',
    isShowOnMenu: true,
    subgroup: 'MERCHANT',
    permissions: [
        'merchant_v',
        'merchant_vs',
        'merchant_key',
        'merchant_new_m',
        'merchant_edit_m',
        'merchant_order_m',
        'merchant_app'
    ]
}, ɵ3 = {
    subgroup: 'MERCHANT',
    permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
}, ɵ4 = {
    permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
}, ɵ5 = {
    title: 'STORE',
    isShowOnMenu: true,
    subgroup: 'STORE',
    permissions: [
        'store_v',
        'store_eq',
        'merchant_new_m',
        'merchant_edit_m',
        'merchant_order_m',
        'merchant_app'
    ]
}, ɵ6 = {
    subgroup: 'STORE',
    permissions: ['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']
}, ɵ7 = {
    title: 'STORE_ASSORTMENT',
    group: 'STORE',
    icon: 'icon-td-store-assortment',
    active: false,
    order: 3,
    isShowOnMenu: true,
    permissions: ['store_assortment_v', 'store_assortment_m']
}, ɵ8 = {
    subgroup: 'STORE',
    permissions: ['store_assortment_v', 'store_assortment_m']
}, ɵ9 = {
    permissions: ['store_assortment_m']
}, ɵ10 = {
    permissions: ['store_assortment_m']
}, ɵ11 = {
    permissions: ['store_assortment_m']
}, ɵ12 = {
    title: 'MEMBERSHIP',
    group: 'STORE',
    icon: 'icon-member',
    active: false,
    order: 4,
    isShowOnMenu: true
}, ɵ13 = {
    title: 'MEMBER_CARD',
    isShowOnMenu: true,
    subgroup: 'MEMBER_CARD',
    permissions: ['mem_card_m', 'mem_card_v']
}, ɵ14 = {
    title: 'ORDER',
    group: 'STORE',
    icon: 'icon-td-order',
    active: false,
    order: 5,
    isShowOnMenu: true
}, ɵ15 = {
    title: 'ORDER',
    isShowOnMenu: true,
    subgroup: 'ORDER',
    permissions: ['so_v', 'or_v', 'so_firstlot_m', 'so_special_m', 'so_app']
}, ɵ16 = {
    subgroup: 'ORDER',
    permissions: ['or_v', 'so_firstlot_m', 'so_special_m', 'so_app']
}, ɵ17 = {
    title: 'DELIVERY_ORDER_SHIPMENT',
    isShowOnMenu: true,
    subgroup: 'DELIVERY_ORDER_SHIPMENT',
    permissions: [
        '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
    ],
    isRegex: true
}, ɵ18 = {
    permissions: [
        '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
    ],
    isRegex: true
}, ɵ19 = {
    subgroup: 'DELIVERY_ORDER_SHIPMENT',
    permissions: [
        '^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
        '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
    ],
    isRegex: true
}, ɵ20 = {
    title: 'RECEIVE_ORDER',
    isShowOnMenu: true,
    subgroup: 'RECEIVE_ORDER',
    permissions: ['ro_v', 'cr_m']
}, ɵ21 = {
    permissions: ['ro_v', 'cr_m']
}, ɵ22 = {
    title: 'CLAIM',
    isShowOnMenu: true,
    subgroup: 'CLAIM',
    permissions: ['cr_v', 'cr_app', 'cr_m']
}, ɵ23 = {
    permissions: ['cr_v', 'cr_app']
}, ɵ24 = {
    title: 'BILLING',
    group: 'STORE',
    icon: 'icon-td-bill',
    active: false,
    order: 6,
    isShowOnMenu: true
}, ɵ25 = {
    title: 'PROFIT_SHARING',
    isShowOnMenu: true,
    subgroup: 'PROFIT_SHARING',
    permissions: ['profitsharing_v', 'profitsharing_m']
}, ɵ26 = {
    permissions: ['profitsharing_v', 'profitsharing_m']
}, ɵ27 = {
    title: 'MONTHLY_PROFIT_SHARING',
    subgroup: 'PROFIT_SHARING',
    permissions: ['profitsharing_m']
}, ɵ28 = {
    title: 'PRODUCT',
    group: 'ADMIN',
    icon: 'icon-td-product',
    active: false,
    order: 7,
    isShowOnMenu: true
}, ɵ29 = {
    title: 'PRODUCT_MASTER',
    isShowOnMenu: true,
    subgroup: 'PRODUCT_MASTER',
    permissions: ['cjproduct_m_v', 'cjproduct_nm_v', 'tdassort_inv_m']
}, ɵ30 = {
    permissions: ['cjproduct_m_v', 'cjproduct_nm_v', 'tdassort_inv_m']
}, ɵ31 = {
    // permissions: ['tdassort_m_m', 'tdassort_nm_m', 'tdassort_m_app', 'tdassort_nm_app']
    subgroup: 'PRODUCT_ASSORTMENT',
    permissions: [
        'tdassort_inv_m',
        'tdassort_asset_m',
        'tdassort_sto_m',
        'tdassort_inv_app',
        'tdassort_asset_app',
        'tdassort_sto_app'
    ]
}, ɵ32 = {
    // permissions: ['tdassort_m_m', 'tdassort_nm_m', 'tdassort_m_app', 'tdassort_nm_app']
    permissions: [
        'tdassort_inv_m',
        'tdassort_asset_m',
        'tdassort_sto_m',
        'tdassort_inv_app',
        'tdassort_asset_app',
        'tdassort_sto_app'
    ]
}, ɵ33 = {
    title: 'PRODUCT_ASSORTMENT',
    isShowOnMenu: true,
    // permissions: [
    //   'tdassort_m_v',
    //   'tdassort_nm_v',
    //   'tdassort_m_m',
    //   'tdassort_nm_m',
    //   'tdassort_m_app',
    //   'tdassort_nm_app'
    // ]
    subgroup: 'PRODUCT_ASSORTMENT',
    permissions: [
        'tdassort_inv_m',
        'tdassort_asset_m',
        'tdassort_sto_m',
        'tdassort_inv_app',
        'tdassort_asset_app',
        'tdassort_sto_app',
        'tdassort_inv_v',
        'tdassort_asset_v',
        'tdassort_sto_v'
    ]
}, ɵ34 = {
    permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
}, ɵ35 = {
    permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
}, ɵ36 = {
    title: 'SHELF',
    isShowOnMenu: true,
    subgroup: 'SHELF',
    permissions: ['shelf_v', 'shelf_m', 'shelf_app', 'shelf_firstlot_m']
}, ɵ37 = {
    subgroup: 'SHELF',
    permissions: ['shelf_m', 'shelf_app', 'shelf_firstlot_m']
}, ɵ38 = {
    title: 'CAMPAIGN',
    group: 'ADMIN',
    icon: 'icon-td-marketing',
    active: false,
    order: 8,
    isShowOnMenu: true
}, ɵ39 = {
    title: 'PROMOTION',
    isShowOnMenu: true,
    subgroup: 'PROMOTION',
    permissions: ['promotion_m', 'promotion_app', 'promotion_v']
}, ɵ40 = {
    subgroup: 'PROMOTION',
    permissions: ['promotion_m', 'promotion_app']
}, ɵ41 = {
    permissions: ['promotion_m', 'promotion_app']
}, ɵ42 = {
    title: 'VOUCHER',
    isShowOnMenu: true,
    subgroup: 'VOUCHER',
    permissions: ['voucher_m', 'voucher_v', 'voucher_app']
}, ɵ43 = {
    subgroup: 'VOUCHER',
    permissions: ['voucher_m', 'voucher_app']
}, ɵ44 = {
    permissions: ['voucher_m']
}, ɵ45 = {
    title: 'REWARD',
    isShowOnMenu: true,
    subgroup: 'REWARD',
    permissions: ['rw_v', 'rw_m', 'rr_v', 'rw_app']
}, ɵ46 = {
    title: 'REWARD',
    isShowOnMenu: false,
    subgroup: 'REWARD',
    permissions: ['rw_v', 'rw_m', 'rw_app']
}, ɵ47 = {
    subgroup: 'REWARD',
    permissions: ['rw_m', 'rw_app', 'rr_v']
}, ɵ48 = {
    permissions: ['rw_m', 'rw_app']
}, ɵ49 = {
    permissions: ['voucher_m']
}, ɵ50 = {
    title: 'PURCHASE',
    group: 'ADMIN',
    icon: 'icon-td-purchase',
    active: false,
    order: 9,
    isShowOnMenu: true
}, ɵ51 = {
    title: 'PR_PO_GR',
    isShowOnMenu: true,
    subgroup: 'PR_PO_GR',
    isRegex: true,
    permissions: [
        ...prViewPermissions,
        ...prManagePermissions,
        ...prApprovePermissions,
        ...poViewPermissions,
        ...poManagePermissions,
        ...grViewPermissions,
        ...grManagePermissions
    ]
}, ɵ52 = {
    isRegex: true,
    permissions: [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions]
}, ɵ53 = {
    subgroup: 'PR_PO_GR',
    isRegex: true,
    permissions: [...poViewPermissions, ...poManagePermissions, ...grManagePermissions]
}, ɵ54 = {
    subgroup: 'PR_PO_GR',
    isRegex: true,
    permissions: [...grViewPermissions, ...grManagePermissions]
}, ɵ55 = {
    isRegex: true,
    permissions: [...grViewPermissions, ...grManagePermissions]
}, ɵ56 = {
    title: 'STOCK',
    group: 'ADMIN',
    icon: 'icon-stock',
    active: false,
    order: 10,
    isShowOnMenu: true
}, ɵ57 = {
    title: 'STOCK_INFORMATION',
    isShowOnMenu: true,
    subgroup: 'STOCK_INFORMATION',
    permissions: ['^stk_v_[a-zA-Z]{2}[0-9]{1,3}$', 'stk_v_sto', 'stk_v_all'],
    isRegex: true
}, ɵ58 = {
    permissions: []
}, ɵ59 = {
    title: 'MANAGE_STOCK',
    isShowOnMenu: true,
    subgroup: 'MANAGE_STOCK',
    permissions: [
        'stkadj_m_sto',
        'stkadj_v_sto',
        '^stkadj_v_[a-zA-Z]{2}[0-9]{1,3}$',
        '^stkadj_m_[a-zA-Z]{2}[0-9]{1,3}$'
    ],
    isRegex: true
}, ɵ60 = {
    title: 'STORE_STOCK_PLANNING',
    isShowOnMenu: true,
    subgroup: 'STORE_STOCK_PLANNING',
    permissions: [
        'stk_plan_v_sto',
        'stk_plan_m_sto',
        '^z8_m_[a-zA-Z]{2}[0-9]{1,3}$',
        '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'
    ],
    isRegex: true
}, ɵ61 = {
    subgroup: 'STORE_STOCK_PLANNING',
    permissions: ['stk_plan_v_sto', 'stk_plan_m_sto']
}, ɵ62 = {
    subgroup: 'STORE_STOCK_PLANNING',
    permissions: ['stk_plan_m_sto']
}, ɵ63 = {
    title: 'USER_MANAGEMENT',
    group: 'ADMIN',
    icon: 'icon-td-user',
    active: false,
    order: 11,
    isShowOnMenu: true
}, ɵ64 = {
    title: 'ROLES',
    isShowOnMenu: true,
    subgroup: 'ROLES',
    permissions: ['role_m', 'role_v']
}, ɵ65 = {
    title: 'STORE_GROUP',
    isShowOnMenu: true,
    subgroup: 'STORE_GROUP',
    permissions: ['store_group_m', 'store_group_v']
}, ɵ66 = {
    title: 'USERS',
    isShowOnMenu: true,
    subgroup: 'USERS',
    permissions: ['user_bo_m', 'user_bo_v']
}, ɵ67 = {
    permissions: ['role_m']
}, ɵ68 = {
    permissions: ['user_bo_m']
}, ɵ69 = {
    permissions: ['store_group_m']
}, ɵ70 = {
    title: 'REPORT',
    group: 'ADMIN',
    icon: 'icon-td-report',
    active: false,
    order: 12,
    isShowOnMenu: true,
    permissions: [
        'rep_voucher_v',
        'rep_stockcard_v',
        'rep_saletx_v',
        'rep_deletesalesitem_v',
        'rep_sales_summary_v',
        'rep_totetx_v',
        'rep_promotion_compensate_v',
        'rep_inventorycount_tmp_v',
        'rep_tdmoving_avg_v',
        'rep_inventory_count_v',
        'rep_moneyin_v',
        'rep_inventory_adj_req_wp_v'
    ]
};
export const appRoutes = [
    {
        path: '',
        component: LoginComponent,
        children: [
            {
                path: '',
                component: LoginRightComponent
            },
            {
                path: '',
                outlet: 'leftsection',
                component: LoginLeftComponent
            }
        ],
        pathMatch: 'full'
    },
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: 'forgot-username',
                children: [
                    {
                        path: '',
                        component: ForgotUsernameComponent
                    },
                    {
                        path: '',
                        outlet: 'leftsection',
                        component: ForgotUsernameLeftComponent
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: 'forgot-password',
                children: [
                    {
                        path: '',
                        component: ForgotPasswordComponent
                    },
                    {
                        path: '',
                        outlet: 'leftsection',
                        component: ForgotPasswordLeftComponent
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: 'force-change-password',
                canActivate: [AuthGuardService],
                children: [
                    {
                        path: '',
                        component: ForceChangePasswordComponent
                    },
                    {
                        path: '',
                        outlet: 'leftsection',
                        component: ForceChangePasswordLeftComponent
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'dashboard/my-task',
                component: MyTaskComponent,
                canActivate: [AuthGuardService],
                data: ɵ0
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'merchant-store',
                data: ɵ1,
                children: [
                    {
                        path: 'merchant-list',
                        component: MerchantListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ2
                    },
                    {
                        path: 'merchant-request-list',
                        component: MerchantRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ3
                    },
                    {
                        path: 'merchant-request',
                        component: MerchantRequestComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ4
                    },
                    {
                        path: 'view-merchant',
                        component: ViewMerchantComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'store-list',
                        component: StoreListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ5
                    },
                    {
                        path: 'store-request-list',
                        component: StoreRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ6
                    },
                    {
                        path: 'view-store',
                        component: ViewStoreComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'store-request',
                        component: StoreRequestComponent,
                        canActivate: [AuthGuardService]
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'store-assortment/store-assortment-list',
                component: StoreAssortmentListComponent,
                data: ɵ7
            },
            {
                path: 'store-assortment/view-store-assortment',
                component: ViewStoreAssortmentComponent,
                canActivate: [AuthGuardService],
                data: ɵ8
            },
            {
                path: 'store-assortment/store-assortment-request-list',
                component: StoreAssortmentRequestListComponent,
                canActivate: [AuthGuardService],
                data: ɵ9
            },
            {
                path: 'store-assortment/store-assortment-request',
                component: StoreAssortmentRequestComponent,
                canActivate: [AuthGuardService],
                data: ɵ10
            },
            {
                path: 'store-assortment/store-assortment-request-view',
                component: StoreAssortmentRequestViewComponent,
                canActivate: [AuthGuardService],
                data: ɵ11
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'membership',
                data: ɵ12,
                children: [
                    {
                        path: 'member-card-list',
                        component: MemberCardListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ13
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'order',
                data: ɵ14,
                children: [
                    {
                        path: 'order-list',
                        component: OrderListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ15
                    },
                    {
                        path: 'order-request-list',
                        component: OrderRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ16
                    },
                    {
                        path: 'order-view',
                        component: OrderViewComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'order-request',
                        component: OrderRequestComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'delivery-order-list',
                        component: DeliveryOrderListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ17
                    },
                    {
                        path: 'delivery-order-edit',
                        component: DeliveryOrderEditComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ18
                    },
                    {
                        path: 'shipment-list',
                        component: ShipmentListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ19
                    },
                    {
                        path: 'receive-order-list',
                        component: ReceiveOrderListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ20
                    },
                    {
                        path: 'receive-order-view',
                        component: ReceiveOrderListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ21
                    },
                    {
                        path: 'claim-request-list',
                        component: ClaimRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ22
                    },
                    {
                        path: 'claim-request-view',
                        component: ClaimRequestViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ23
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'billing',
                data: ɵ24,
                children: [
                    // {
                    //   path: 'bill-payment-list',
                    //   component: CompensateListComponent,
                    //   canActivate: [AuthGuardService],
                    //   data: {
                    //     title: 'BILL_PAYMENT',
                    //     isShowOnMenu: true,
                    //     subgroup: 'BILL_PAYMENT'
                    //   }
                    // },
                    {
                        path: 'profit-sharing-list',
                        component: ProfitSharingListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ25
                    },
                    {
                        path: 'profit-sharing-view',
                        component: ProfitSharingViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ26
                    },
                    {
                        path: 'monthly-partner-profit-sharing-list',
                        component: MonthlyPartnerProfitSharingListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ27
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'products',
                data: ɵ28,
                children: [
                    {
                        path: 'master-list',
                        component: ProductListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ29
                    },
                    {
                        path: 'view',
                        component: ProductViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ30
                    },
                    {
                        path: 'request-list',
                        component: AssortmentRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ31
                    },
                    {
                        path: 'request-view',
                        component: AssortmentRequestComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ32
                    },
                    {
                        path: 'product-list',
                        component: AssortmentProductListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ33
                    },
                    // Temporary Hide until it came back
                    // {
                    //   path: 'stack-pricing',
                    //   component: StackPricingListComponent,
                    //   canActivate: [AuthGuardService],
                    //   data: {
                    //     title: 'PRICE',
                    //     isShowOnMenu: true,
                    //     permissions: ['price_m_v', 'price_m_m', 'price_nm_v', 'price_nm_m']
                    //   }
                    // },
                    {
                        path: 'stack-pricing-edit',
                        component: StackPricingViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ34
                    },
                    {
                        path: 'class-markup',
                        component: ClassMarkupComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ35
                    },
                    {
                        path: 'shelf-list',
                        component: ShelfListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ36
                    },
                    {
                        path: 'shelf-request-list',
                        component: ShelfRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ37
                    },
                    {
                        path: 'shelf-request',
                        component: ShelfRequestComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'view-shelf',
                        component: ViewShelfComponent,
                        canActivate: [AuthGuardService]
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'campaign',
                data: ɵ38,
                children: [
                    {
                        path: 'promotion-list',
                        component: PromotionListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ39
                    },
                    {
                        path: 'promotion-request-list',
                        component: PromotionRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ40
                    },
                    {
                        path: 'promotion-request-create',
                        component: PromotionRequestComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ41
                    },
                    {
                        path: 'voucher-list',
                        component: VoucherListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ42
                    },
                    {
                        path: 'voucher-request-list',
                        component: VoucherRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ43
                    },
                    {
                        path: 'voucher-request-create',
                        component: VoucherRequestComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ44
                    },
                    {
                        path: 'reward-active-list',
                        component: RewardActiveListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ45
                    },
                    {
                        path: 'reward-list',
                        component: RewardListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ46
                    },
                    {
                        path: 'reward-request-list',
                        component: RewardRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ47
                    },
                    {
                        path: 'reward-request-create',
                        component: RewardRequestComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ48
                    },
                    {
                        path: 'reward-view',
                        component: RewardViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ49
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'purchase',
                data: ɵ50,
                children: [
                    {
                        path: 'purchase-request-list',
                        component: PurchaseRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ51
                    },
                    {
                        path: 'purchase-request-view',
                        component: PurchaseRequestViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ52
                    },
                    {
                        path: 'purchase-order-list',
                        component: PurchaseOrderListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ53
                    },
                    {
                        path: 'goods-receive-list',
                        component: GoodsReceiveListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ54
                    },
                    {
                        path: 'goods-receive-view',
                        component: GoodsReceiveViewComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ55
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'stock',
                data: ɵ56,
                children: [
                    {
                        path: 'stock-information-list',
                        component: StockInformationListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ57
                    },
                    {
                        path: 'stock-information',
                        component: StockInformationComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ58
                    },
                    {
                        path: 'manage-stock',
                        component: ManageStockListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ59
                    },
                    {
                        path: 'z8-result',
                        component: Z8ResultComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ60
                    },
                    {
                        path: 'z8-parameter-list',
                        component: Z8ParameterListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ61
                    },
                    {
                        path: 'z8-parameter-request-list',
                        component: Z8ParameterRequestListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ62
                    },
                    {
                        path: 'view-parameter',
                        component: ViewParameterComponent,
                        canActivate: [AuthGuardService]
                    },
                    {
                        path: 'view-parameter-request',
                        component: ViewParameterRequestComponent,
                        canActivate: [AuthGuardService]
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'user-management',
                data: ɵ63,
                children: [
                    {
                        path: 'role-list',
                        component: RoleListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ64
                    },
                    {
                        path: 'store-group-list',
                        component: StoreGroupListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ65
                    },
                    {
                        path: 'user-list',
                        component: UserListComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ66
                    },
                    {
                        path: 'role-create',
                        component: RoleCreateComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ67
                    },
                    {
                        path: 'user-create',
                        component: UserCreateComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ68
                    },
                    {
                        path: 'store-group-create',
                        component: StoreGroupCreateComponent,
                        canActivate: [AuthGuardService],
                        data: ɵ69
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'reports',
                component: ReportListComponent,
                canActivate: [AuthGuardService],
                data: ɵ70
            }
        ]
    },
    { path: '**', redirectTo: '/' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50, ɵ51, ɵ52, ɵ53, ɵ54, ɵ55, ɵ56, ɵ57, ɵ58, ɵ59, ɵ60, ɵ61, ɵ62, ɵ63, ɵ64, ɵ65, ɵ66, ɵ67, ɵ68, ɵ69, ɵ70 };
