<div id="shipment-list" class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.DELIVERY_ORDER_SHIPMENT' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="d-md-flex">
      <div class="flex-item d-md-flex justify-content-between">
        <div class="form-group text-nowrap d-none d-md-block">
          <app-import-export-button
            class="ml-2"
            [buttons]="buttons"
            (export)="exportModal.show()"
            (import)="openImportModal()"
          ></app-import-export-button>
        </div>
        <div class="d-md-flex flex-fill justify-content-end">
          <div class="filter-search-box flex-item form-group ml-md-2">
            <div class="search-box position-relative">
              <input
                type="text"
                formControlName="searchCriteria"
                class="searchCriteria form-control"
                placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
                required
                maxlength="50"
                (keyup.backspace)="clearLastKeyUpSearchText($event)"
              />
              <em
                class="icon-close-mini"
                [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
                (click)="clearSearchText()"
              ></em>
              <button class="btn-search" type="submit">
                <em class="icon-search"></em>
              </button>
            </div>
          </div>
          <div class="filter-dropdown flex-item form-group ml-md-2">
            <ng-select
              [items]="shipmentStatusList"
              [searchable]="false"
              [clearable]="false"
              (change)="onChangeStatus($event)"
              class="select-status"
              bindLabel="label"
              bindValue="value"
              formControlName="status"
            ></ng-select>
          </div>
          <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
            <button
              class="btn advance-filter-button btn-advance-filter"
              [ngClass]="{ active: isShowAdvanceSearch }"
              type="button"
              (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
            >
              <em class="icon-filter"></em>
              {{ 'ADVANCED_FILTERS' | translate }}
            </button>
            <div
              class="box-dropdown"
              [ngClass]="{ open: isShowAdvanceSearch }"
              (appClickOutside)="onClickedOutside($event)"
              [exclude]="['.btn-advance-filter', '.is-highlighted']"
            >
              <div class="box-dropdown-list advance-filter-dropdown">
                <div class="box-dropdown-body px-4">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="mb-2">
                            Warehouse
                          </div>
                          <ng-select
                            placeholder="Please select..."
                            [items]="warehouseList"
                            [searchable]="false"
                            [clearable]="false"
                            [multiple]="true"
                            bindLabel="warehouseNameDisplay"
                            bindValue="code"
                            formControlName="warehouseCode"
                          >
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-2">
                          {{ 'SHIPMENT.DELIVERY_DATE' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="deliveryDateFrom"
                            formControlName="deliveryDateFrom"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="maxDate"
                            (bsValueChange)="onChangeDateFrom($event)"
                            readonly
                          />
                          <label for="deliveryDateFrom" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="deliveryDateTo"
                            formControlName="deliveryDateTo"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control w-100 calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [minDate]="minDate"
                            (bsValueChange)="onChangeDateTo($event)"
                            readonly
                          />
                          <label for="deliveryDateTo" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-2">
                          {{ 'SHIPMENT.PICKUP_DATE' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="pickupDateFrom"
                            formControlName="pickupDateFrom"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control w-100 calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="pickMaxDate"
                            (bsValueChange)="onChangePickDateFrom($event)"
                            readonly
                          />
                          <label for="pickupDateFrom" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="pickupDateTo"
                            formControlName="pickupDateTo"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control w-100 calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [minDate]="pickMinDate"
                            (bsValueChange)="onChangePickDateTo($event)"
                            readonly
                          />
                          <label for="pickupDateTo" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-2">
                          {{ 'CREATED_DATE' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="createdDateFrom"
                            formControlName="createdDateFrom"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="createdMaxDate"
                            (bsValueChange)="onChangeCreatedDateFrom($event)"
                            readonly
                          />
                          <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="createdDateTo"
                            formControlName="createdDateTo"
                            type="text"
                            placeholder="{{ environment.dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [minDate]="createdMinDate"
                            (bsValueChange)="onChangeCreatedDateTo($event)"
                            readonly
                          />
                          <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="advance-filter-footer text-center">
                  <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                    Cancel
                  </button>
                  <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                    {{ 'APPLY' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="createdDateStringTag || pickupDateStringTag || deliveryDateStringTag || warehouseTypeStringTag"
  >
    <div class="search-criteria" *ngIf="warehouseTypeTag">
      <a class="remove-criteria" (click)="clearFilterWarehouse()">
        <em class="icon-close"></em>
      </a>
      <span>{{ warehouseTypeStringTag }}: {{ warehouseTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="deliveryDateStringTag">
      <a class="remove-criteria" (click)="clearFilterDeliveryDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ deliveryDateStringTag }}: {{ deliveryDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="pickupDateStringTag">
      <a class="remove-criteria" (click)="clearFilterPickUpDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ pickupDateStringTag }}: {{ pickupDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="createdDateStringTag">
      <a class="remove-criteria" (click)="clearFilterCreatedDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ createdDateStringTag }}: {{ createdDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div
          class="col-12 col-md-10 cursor-pointer"
          (click)="showModalShipment(result$.shipmentNo, shipmentTypeEnum.VIEW)"
        >
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                <app-click-to-copy [text]="result$.shipmentNo"></app-click-to-copy>
              </label>
              <span [ngClass]="getColorStatus(result$.status)" class="card-status do-status ml-auto">{{
                'SHIPMENT.SHIPMENT_STATUS.' + result$.status | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Deliver By:</span>
              <span class="result-value">{{ result$.courierName }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Ship Charge:</span>
              <span class="result-value">{{ result$.shipCharge | numberFormatDisplay: 2 }} THB</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Plate No./Driver:</span>
              <span class="result-value">{{ result$.platNoDriverNameDisplay | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Warehouse:</span>
              <span class="result-value">{{ result$.warehouseDisplayName | dashDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Delivery Date:</span>
              <span class="result-value">{{ result$.deliveryDate | dateDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Pick up Date:</span>
              <span class="result-value">{{ result$.pickupDate | dateDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created By:</span>
              <span class="result-value">{{ result$.createdByName | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ result$.createdDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  permissionsUtil.determineLogisticPermission(result$.warehouseCode, [permissionAction.MANAGE]) &&
                  canEdit(result$.status)
                "
                (click)="
                  result$.status === shipmentStatusEnum.AWAITING
                    ? showModalShipment(result$.shipmentNo, shipmentTypeEnum.EDIT_AWAITING)
                    : showModalShipment(result$.shipmentNo, shipmentTypeEnum.EDIT_DISPATCH)
                "
              >
                <em class="icon-edit"></em>Edit
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  permissionsUtil.determineLogisticPermission(result$.warehouseCode, [permissionAction.MANAGE]) &&
                  canDelete(result$.status)
                "
                (click)="showModalShipment(result$.shipmentNo, shipmentTypeEnum.DELETE)"
              >
                <em class="icon-delete"></em>Delete
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="hasManageLogistic || hasManageDeliveryOrder || hasViewLogistic"
                (click)="showHistory(result$)"
              >
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>
          <button
            type="button"
            class="btn btn-standard btn-loaded"
            *ngIf="
              (permissionsUtil.determineLogisticPermission(result$.warehouseCode, [permissionAction.MANAGE]) ||
                permissionsUtil.determineDoPermission(result$.warehouseCode, [permissionAction.MANAGE])) &&
              canDispatch(result$.status)
            "
            (click)="showModalShipment(result$.shipmentNo, shipmentTypeEnum.DISPATCH)"
          >
            <em class="icon-dispatch"></em>
            Dispatched
          </button>
        </div>
      </div>
    </div>

    <!--pagination-->

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements <= 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="200" height="200" src="/assets/images/not-found.svg" alt="not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Modal Export -->
  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Export Data</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-row">
            <div class="col-12 px-0">Created Date</div>
            <div class="form-group col-md-6 pl-0">
              <label>{{ 'FROM' | translate }}:</label>
              <input
                id="export_createdDateFrom"
                formControlName="createdDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="createdShipmentMaxDate"
                (bsValueChange)="onChangeCreatedShipmentDateFrom($event)"
                readonly
              />
              <label for="export_createdDateFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>{{ 'TO' | translate }}:</label>
              <input
                id="export_createdDateTo"
                formControlName="createdDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateTo.errors
                }"
                [bsConfig]="bsDateConfig"
                [minDate]="createdShipmentMinDate"
                (bsValueChange)="onChangeCreatedShipmentDateTo($event)"
                readonly
              />
              <label for="export_createdDateTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 px-0">Delivery Date</div>
            <div class="form-group col-md-6 pl-0">
              <label>{{ 'FROM' | translate }}:</label>
              <input
                id="export_deliveryDateFrom"
                formControlName="deliveryDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.deliveryDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="createdDeliveryMaxDate"
                (bsValueChange)="onChangeCreatedDeliveryDateFrom($event)"
                readonly
              />
              <label for="export_deliveryDateFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.deliveryDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.deliveryDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>{{ 'TO' | translate }}:</label>
              <input
                id="export_deliveryDateTo"
                formControlName="deliveryDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.deliveryDateTo.errors
                }"
                [bsConfig]="bsDateConfig"
                [minDate]="createdDeliveryMinDate"
                (bsValueChange)="onChangeCreatedDeliveryDateTo($event)"
                readonly
              />
              <label for="export_deliveryDateTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.deliveryDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.deliveryDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Shipment Status
            </div>
            <div class="form-group">
              <ng-select
                id="export_status"
                placeholder="Please select..."
                [items]="shipmentStatusExportFilter"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
              >
              </ng-select>
            </div>
          </div>

          <div class="form-group">
            <div class="mb-2">
              Warehouse
            </div>
            <div class="form-group">
              <app-dropdown-multiple-select
                [parentForm]="exportForm"
                controlName="code"
                [items]="warehouseList"
                bindLabel="warehouseNameDisplay"
                bindValue="code"
              >
              </app-dropdown-multiple-select>
            </div>
          </div>

          <div *ngIf="errorExport" class="alert-danger form-group mb-0" role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExport()"
            [disabled]="exportFormInValid"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Export end -->
</div>
