import env from './.env';
import { environment as defaultEnv } from './base.environment';

export const environment = {
  ...defaultEnv,
  hmr: false,
  version: env.version + '-khamoo',
  tier: 'khamoo',
  serverUrl: 'https://api-moo.tdshop.io',
  cookies: {
    ...defaultEnv.cookies,
    domain: env.forceDomain || 'nest-moo.tdshop.io'
  }
};
