import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { RewardValidateResponse } from '../../models/reward.model';
import { RewardRequestService } from '../../services/reward-request.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  RewardApproveRequestedAction,
  RewardEditSaveRequestAction,
  RewardRejectRequestedAction,
  RewardRequestActionTypes,
  RewardRequestByIdRequestAction,
  RewardRequestByIdResponseAction,
  RewardRequestCancelRequestedAction,
  RewardRequestDeleteRequestAction,
  RewardRequestListRequestAction,
  RewardRequestListResponseAction,
  RewardRequestSaveRequestAction,
  RewardRequestSubmitErrorAction,
  RewardRequestSubmitRequestAction,
  RewardRequestValidateActionRequest,
  RewardRequestValidateActionResponse
} from '../actions/reward-request.actions';

@Injectable()
export class RewardRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly rewardRequestService: RewardRequestService
  ) {}

  @Effect()
  createRequestReward$ = this.actions$.pipe(
    ofType<RewardRequestSaveRequestAction>(RewardRequestActionTypes.REWARD_REQUEST_SAVE_REQUEST),
    tap(action =>
      this.logger.debug('@Effect Request Reward Create Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action => {
      return this.rewardRequestService.createRequest(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  editRequestReward$ = this.actions$.pipe(
    ofType<RewardEditSaveRequestAction>(RewardRequestActionTypes.REWARD_EDIT_SAVE_REQUEST),
    tap(action => this.logger.debug('@Effect Request Reward Edit Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.rewardRequestService.updateByRequestId(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  createSubmitReward$ = this.actions$.pipe(
    ofType<RewardRequestSubmitRequestAction>(RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_REQUEST),
    tap(action =>
      this.logger.debug('@Effect Request Reward Create Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action => {
      return this.rewardRequestService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been created.'
            })
        ),
        catchError(err => {
          return err && err.error && err.error.code === '07001'
            ? of(new RewardRequestSubmitErrorAction(err.error))
            : of(new LayoutActionLoadError(err));
        })
      );
    })
  );

  @Effect()
  validateRequestReward$ = this.actions$.pipe(
    ofType<RewardRequestValidateActionRequest>(RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_REQUEST),
    tap(action => this.logger.debug('@Effect Request Reward Validate Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.rewardRequestService.validateRequestItem(action.payload).pipe(
        map(res => {
          const data = {
            isError: res.length > 0,
            items: res
          } as RewardValidateResponse;
          return new RewardRequestValidateActionResponse(data);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getRewardRequestById$ = this.actions$.pipe(
    ofType<RewardRequestByIdRequestAction>(RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Reward Request By ID Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.rewardRequestService.getRewardRequestById(action.payload.requestId).pipe(
        map(RewardRequestView => {
          return new RewardRequestByIdResponseAction({ RewardRequestView });
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  searchRewardRequest$ = this.actions$.pipe(
    ofType<RewardRequestListRequestAction>(RewardRequestActionTypes.REWARD_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${RewardRequestActionTypes.REWARD_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.rewardRequestService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          return new RewardRequestListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  rejectRequestReward$ = this.actions$.pipe(
    ofType<RewardRejectRequestedAction>(RewardRequestActionTypes.REWARD_REQUEST_REJECT_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Reject: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.rewardRequestService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  approveRequestReward$ = this.actions$.pipe(
    ofType<RewardApproveRequestedAction>(RewardRequestActionTypes.REWARD_REQUEST_APPROVE_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Approve: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.rewardRequestService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  cancelPromotionRequest$ = this.actions$.pipe(
    ofType<RewardRequestCancelRequestedAction>(RewardRequestActionTypes.REWARD_REQUEST_CANCEL_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.rewardRequestService.requestCancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  deleteRequestRequest$ = this.actions$.pipe(
    ofType<RewardRequestDeleteRequestAction>(RewardRequestActionTypes.REWARD_REQUEST_DELETE_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Delete: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.rewardRequestService.delete(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
