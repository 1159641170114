<div class="row">
  <div class="col-md-12">
    <table *ngIf="purchaseQtyItems.controls.length" id="Table" class="table table-striped table-bordered w-100">
      <thead>
        <tr>
          <th class="text-center w-5">{{ headerRow[0] }}</th>
          <th class="text-center w-20">{{ headerRow[1] }}</th>
          <th class="text-center w-10">{{ headerRow[2] }}</th>
          <th class="text-center w-10">{{ headerRow[3] }}</th>
          <th class="text-center w-5">{{ headerRow[4] }}</th>
          <th class="text-center w-5" *ngIf="mode !== pageMode.VIEW">{{ headerRow[5] }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of purchaseQtyItems.controls; let i = index"
          [ngClass]="{
            'error-background':
              mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW && (item.errors || item.errors?.duplicated)
          }"
        >
          <td class="text-center align-top">{{ i + 1 }}</td>
          <td class="text-left align-top">
            {{ item.get('productName').value }}
            <ng-container *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isRestrictItem || item.get('productName').errors?.isRestrictItem"
              >
                {{ 'ERRORS.RESTRICT_ITEM' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isStatusDelisted || item.get('productName').errors?.isStatusDelisted"
              >
                {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isFixAssetItem || item.get('productName').errors?.isFixAssetItem"
              >
                {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isStoreUseItem || item.get('productName').errors?.isStoreUseItem"
              >
                {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="
                  item.errors?.isPurchaseItemDuplicate || item.get('productName').errors?.isPurchaseItemDuplicate
                "
              >
                {{ 'ERRORS.REWARD_PURCHASE_ITEM_DUPLICATE' | translate }}
              </small>
            </ng-container>
          </td>
          <td class="text-left align-top">{{ item.get('articleNo').value }}</td>
          <td class="text-left align-top">
            {{ item.get('barcode').value }}
            <ng-container *ngIf="!!mode && mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors && item.get('barcode').errors?.invalidBarcode"
              >
                {{ 'ERRORS.INVALID_BARCODE' | translate }}</small
              >
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors && item.get('barcode').errors?.isInactive"
              >
                {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.duplicated">
                {{ 'ERRORS.DUPLICATED_BARCODE_FIELD' | translate }}
              </small>
            </ng-container>
          </td>
          <td class="text-center align-top">
            {{ item.get('unit').value }}
          </td>
          <td class="text-center align-middle" *ngIf="mode !== pageMode.VIEW">
            <a
              *ngIf="mode !== pageMode.REQUEST_VIEW"
              id="delete-store"
              class="is-link text-muted"
              (click)="deletePromotionItem(i)"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
      <a class="btn-text-link d-inline-block mr-1 " (click)="addNewProductItem()">+Select Item </a> or
      <a class="btn-text-link d-inline-block ml-1 " (click)="addNewBarcode()">+Add Barcode </a>
    </div>
    <app-search-product-modal
      #searchProductModal
      [promotion]="{
        promotionConRetailPrice: promotionConRetailPrice,
        promotionType: promotionType,
        promotionItemType: promotionItemType
      }"
      [productType]="productType"
      (addItem)="onAddPromotionItem($event)"
    >
    </app-search-product-modal>
    <app-search-barcode-modal
      #searchBarcodeModal
      [promotion]="{
        promotionConRetailPrice: promotionConRetailPrice,
        promotionType: promotionType,
        promotionItemType: promotionItemType
      }"
      [allowProductType]="productType"
      (addItem)="onAddPromotionBarCode($event)"
    ></app-search-barcode-modal>
  </div>
</div>
