<div id="product-list" class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-product"></em>
    <h4>{{ 'CJ_PRODUCT.CJ_PRODUCT_MASTER' | translate }}</h4>
  </div>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="mt-5">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button
          type="button"
          class="btn btn-primary mr-2"
          *appHasPermission="allowEditPermissions"
          (click)="checkValidSelectedProduct()"
        >
          <em class="icon-addto"></em>
          Copy to TD
        </button>

        <button
          type="button"
          class="btn btn-secondary"
          *appHasPermission="allowEditPermissions"
          (click)="maskAsTdUnused()"
        >
          <em class="icon-forbidden"></em>
          Mask as TD Unused
        </button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>

        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="productsMasterStatus"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="ng-select-width"
            bindLabel="label"
            bindValue="value"
            formControlName="selectStatus"
          ></ng-select>
        </div>

        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'CJ_PRODUCT.IMPORTED_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="beginImportDate"
                          formControlName="beginImportDate"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="beginImportDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="endImportDate"
                          formControlName="endImportDate"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="endImportDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          {{ 'CJ_PRODUCT.CLASS' | translate }}
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="productsMasterClassCode"
                          [searchable]="true"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="classCode"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria mb-2" *ngIf="dateStringTag || requestClassCodeTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="requestClassCodeTag">
      <a class="remove-criteria" (click)="clearRequestClass()">
        <em class="icon-close"></em>
      </a>
      <span>{{ 'CJ_PRODUCT.CLASS' | translate }}: {{ requestClassCodeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">{{ 'CJ_PRODUCT.CLEAR_ALL' | translate }}</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async)?.totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="table-responsive">
      <table id="datatable" class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th *appHasPermission="allowEditPermissions">
              <input
                (change)="onClickCheckAllBox($event)"
                [(ngModel)]="selectedAllPage[currentPage]"
                id="checkAllId"
                type="checkbox"
              />
            </th>
            <th *ngFor="let header of headerRow">
              {{ header | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of resultList$ | async; index as i">
            <td *appHasPermission="allowEditPermissions">
              <input
                type="checkbox"
                [ngClass]="{ 'd-none': product.selectStatus === 'SELECTED' }"
                (change)="onClickCheckBox($event, product)"
                [(ngModel)]="checkedList[product.articleNo]"
              />
            </td>
            <td (click)="goToView(product)">
              <app-click-to-copy [text]="product.articleNo"></app-click-to-copy>
            </td>
            <td (click)="goToView(product)">
              <app-click-to-copy [text]="product.productName"></app-click-to-copy>
            </td>
            <td (click)="goToView(product)">
              {{ product.className }}
            </td>
            <td (click)="goToView(product)">
              {{ product.supplierName }}
            </td>
            <td (click)="goToView(product)">
              {{ 'CJ_PRODUCT.STATUS_DISPLAY.' + product.statusCJ | translate }}
            </td>
            <td (click)="goToView(product)">
              {{ product.lastModifiedDate | amFromUtc | amLocal | dateDisplay }}
            </td>
            <td class="status" [ngClass]="getColorStatus(product.selectStatus)" (click)="goToView(product)">
              {{ 'CJ_PRODUCT.REQUEST_STATUS_DISPLAY.' + product.selectStatus | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!-- [TODO]: change to use confirmModalComponent -->
  <div class="modal fade" bsModal #confirmChangeStatusModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Confirm</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="confirmChangeStatusModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid text-center">
            <span *ngIf="currentActionStatus === 'SELECTED'">Are you sure you want to use this product?</span>
            <span *ngIf="currentActionStatus === 'UNUSED'">Are you sure you do not want to use this product?</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-standard mr-2" (click)="confirmChangeStatusModal.hide()">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" (click)="confirmChangeStatus()" appPreventDoubleClick>
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- [TODO]: change to use AlertModalComponent -->
  <div class="modal fade" bsModal #successModal="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Success</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="successModal.hide(); doSearch()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form my-2 text-center">
          The status has been changed.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="successModal.hide(); doSearch()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--modal create pr-->
  <div class="modal fade" bsModal #modalSelectProductType="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Product Type</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalSelectProductType()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Product Type:</div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="productTypes"
                placeholder="Please select..."
                [(ngModel)]="selectedProductType"
                [ngClass]="{ 'is-invalid': requireSelectProductTypeError }"
                (ngModelChange)="onChangeProductType()"
              >
              </ng-select>
              <div *ngIf="requireSelectProductTypeError" class="invalid-display">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-standard mr-2" type="button" (click)="hideModalSelectProductType()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="showConfirmChangeStatusModal('SELECTED')"
            appPreventDoubleClick
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
