import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { RequestWithComment } from '../models';
import {
  RewardItemValidateResponse,
  RewardRequestListResponse,
  RewardRequestResponse,
  RewardRequestSearchCriteria,
  RewardValidate
} from '../models/reward.model';
import { BaseService } from './base.service';

@Injectable()
export class RewardRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.rewardRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  createRequest(data: RewardRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  updateByRequestId(data: RewardRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  submit(data: RewardRequestResponse): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);

    if (data.status === 'W') {
      return this.http.put<any>(url, data, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  validateRequestItem(data: RewardValidate): Observable<RewardItemValidateResponse[]> {
    const url = this.getFullUrl(this.envService.validate);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  getRewardRequestById(requestsId: string): Observable<RewardRequestResponse> {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: requestsId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  searchByCriteriaRequest(criteria: RewardRequestSearchCriteria): Observable<RewardRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public downloadFileStore(data: any, condition: string = null): Observable<any> {
    const url = this.getFullUrl(this.envService.export);
    const params = this.getParams({ condition: condition }, true);
    return this.http.post<any>(url, data, { headers: this.headers, responseType: 'blob' as 'json', params });
  }

  public importFileStoreRequest(file, condition: string = null): Observable<any> {
    const url = this.getFullUrl(this.envService.import);
    const params = this.getParams({ condition: condition }, true);

    let formData = null;
    formData = new FormData();
    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params
    });
  }

  approve(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  reject(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  /**
   * @description For delete request
   */
  delete(id: string) {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: id
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  /**
   * @description For cancel request
   */
  requestCancel(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
}
