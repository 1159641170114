<div class="" *ngIf="this.purchaseAmountGoods.controls.length > 0">
  <table id="data-table" class="table table-striped table-bordered table-hover w-100" [formGroup]="parentForm">
    <thead>
      <tr>
        <th class="text-center w-5">No.</th>
        <th class="text-center w-10">Purchase Amount<span class="text-danger">*</span></th>
        <th class="text-center w-20">Offer Item<span class="text-danger">*</span></th>
        <th class="text-center w-10">Barcode</th>
        <th class="text-center w-5">Unit</th>
        <th class="text-center w-10">Unit Factor</th>
        <th class="text-center w-15">Wholesale Price (Inc VAT)<span class="text-danger">*</span></th>
        <th class="text-center w-10">Offer quantity<span class="text-danger">*</span></th>
        <th class="text-center w-15">Redeem Price (Inc VAT)<span class="text-danger">*</span></th>
        <th class="text-center w-5" *ngIf="mode !== pageMode.VIEW">Action</th>
      </tr>
    </thead>
    <tbody formArrayName="purchaseAmountGoods">
      <tr *ngFor="let item of purchaseAmountGoods.controls; index as i" [formGroupName]="i">
        <td class="text-center">{{ i + 1 }}</td>
        <td class="text-center align-top">
          <app-numeric-textbox
            id="purchaseAmount"
            [minValue]="0"
            [decimals]="2"
            [maxValue]="999999.99"
            [min]="0"
            maxlength="9"
            formControlName="purchaseAmount"
            [format]="'0,0.00'"
            [negativeSign]="false"
            class="td-numeric-textbox"
            placeholder="0.00"
            [ngClass]="{ 'is-invalid': submitted && (item.errors || item.get('purchaseAmount').errors) }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && item.get('purchaseAmount').errors" class="invalid-feedback">
            <div *ngIf="item.get('purchaseAmount').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="item.get('purchaseAmount').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
          </div>
          <div
            *ngIf="submitted && item.errors && item.errors.duplicated"
            class="d-block invalid-feedback text-danger"
          >
            Duplicate data
          </div>
        </td>
        <td class="text-left align-top">
          {{ item.get('offerItem').value }}
          <span class="d-block">{{ item.get('articleNo').value }}</span>
          <ng-container *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.isRestrictItem || item.get('offerItem').errors?.isRestrictItem"
            >
              {{ 'ERRORS.RESTRICT_ITEM' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.isStatusDelisted || item.get('offerItem').errors?.isStatusDelisted"
            >
              {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.isFixAssetItem || item.get('offerItem').errors?.isFixAssetItem"
            >
              {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.isStoreUseItem || item.get('offerItem').errors?.isStoreUseItem"
            >
              {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
            </small>
          </ng-container>
        </td>
        <td class="text-left align-top">
          {{ item.get('barcode').value }}
          <ng-container *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors && item.get('barcode').errors?.invalidBarcode"
            >
              {{ 'ERRORS.INVALID_BARCODE' | translate }}</small
            >
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors && item.get('barcode').errors?.isInactive"
            >
              {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
            </small>
          </ng-container>
        </td>
        <td class="text-center">
          {{ item.get('unit').value }}
        </td>
        <td class="text-center">
          {{ item.get('unitFactor').value }}
        </td>
        <td class="text-center align-top">
          <app-numeric-textbox
            id="wholesalePriceIncVatAmount"
            [minValue]="0"
            [decimals]="2"
            [maxValue]="999999.99"
            [min]="0"
            maxlength="9"
            formControlName="wholesalePriceIncVatAmount"
            [format]="'0,0.00'"
            [negativeSign]="false"
            class="td-numeric-textbox"
            placeholder="0.00"
            [ngClass]="{ 'is-invalid': submitted && (item.errors || item.get('wholesalePriceIncVatAmount').errors) }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && item.get('wholesalePriceIncVatAmount').errors" class="invalid-feedback">
            <div *ngIf="item.get('wholesalePriceIncVatAmount').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="item.get('wholesalePriceIncVatAmount').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
          </div>
        </td>
        <td class="text-center align-top">
          <app-numeric-textbox
            id="offerQuantity"
            [decimals]="0"
            [maxValue]="999"
            [minValue]="1"
            [min]="1"
            maxlength="3"
            formControlName="offerQuantity"
            [format]="'0,0'"
            [negativeSign]="false"
            class="td-numeric-textbox-center"
            placeholder="0"
            [ngClass]="{ 'is-invalid': submitted && (item.errors || item.get('offerQuantity').errors) }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && item.get('offerQuantity').errors" class="invalid-feedback">
            <div *ngIf="item.get('offerQuantity').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="item.get('offerQuantity').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
          </div>
        </td>
        <td class="align-top">
          <div class="row">
            <div class="col-md-6   pr-1">
              <ng-select
                name="redeemPriceType"
                [items]="redeemPriceOption"
                placeholder="Please select..."
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                (change)="onchangeRedeemPriceType($event, i)"
                formControlName="redeemPriceType"
                [ngClass]="{ 'is-invalid': submitted && (item.errors || item.get('redeemPriceType').errors) }"
              ></ng-select>
              <div *ngIf="submitted && item.get('redeemPriceType').errors" class="invalid-feedback">
                <div *ngIf="item.get('redeemPriceType').errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6  pl-0">
              <app-numeric-textbox
                id="redeemPriceAmount"
                [minValue]="0"
                [decimals]="2"
                [maxValue]="999999.99"
                [min]="0"
                maxlength="9"
                formControlName="redeemPriceAmount"
                [format]="'0,0.00'"
                class="td-numeric-textbox"
                [negativeSign]="false"
                placeholder="0.00"
                [ngClass]="{ 'is-invalid': submitted && (item.errors || item.get('redeemPriceAmount').errors) }"
              ></app-numeric-textbox>
              <div *ngIf="submitted && item.get('redeemPriceAmount').errors" class="invalid-feedback">
                <div *ngIf="item.get('redeemPriceAmount').errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="item.get('redeemPriceAmount').errors.isZero">
                  {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center align-middle" *ngIf="mode !== pageMode.VIEW">
          <a *ngIf="mode !== pageMode.REQUEST_VIEW" class="btn-text-link" (click)="deleteItem(i)">
            <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row" *ngIf="this.purchaseAmountGoods.length < this.maximumRow && mode !== pageMode.VIEW">
  <div class="col-md-12">
    <a
      *ngIf="mode !== pageMode.REQUEST_VIEW"
      class="btn-text-link d-inline-block ml-1 float-left"
      (click)="addPurchaseTier()"
      >+Add Purchase Tier
    </a>
  </div>
</div>
<app-search-product-modal
  #searchProductModal
  [allowRestrictItem]="allowRestrict"
  [productType]="productType"
  (addItem)="onAddItem($event)"
>
</app-search-product-modal>
