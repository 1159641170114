import { Action } from '@ngrx/store';
import { ErrorResponse, RequestWithComment } from '../../models';
import {
  RewardRequestListResponse,
  RewardRequestResponse,
  RewardRequestSearchCriteria,
  RewardValidate,
  RewardValidateResponse
} from '../../models/reward.model';

export enum RewardRequestActionTypes {
  REWARD_REQUEST_LIST_REQUEST = '[Reward Request] List Request',
  REWARD_REQUEST_LIST_RESPONSE = '[Reward Request] List Response',
  REWARD_REQUEST_LIST_ERROR = '[Reward Request] List Error',

  REWARD_REQUEST_SAVE_REQUEST = '[Reward Request] Reward Save Request',
  REWARD_REQUEST_SAVE_RESPONSE = '[Reward Request] Reward Save Response',
  REWARD_REQUEST_SAVE_RESET = '[Reward Request] Reward Save Reset',

  REWARD_EDIT_SAVE_REQUEST = '[Reward Request] Edit Reward Save Request',
  REWARD_EDIT_SAVE_SUCCESS = '[Reward Request] Edit Reward Save Success',

  REWARD_REQUEST_SUBMIT_REQUEST = '[Reward Request] Reward Submit Request',
  REWARD_REQUEST_SUBMIT_RESPONSE = '[Reward Request] Reward Submit Response',
  REWARD_REQUEST_SUBMIT_RESET = '[Reward Request] Reward Submit Reset',
  REWARD_REQUEST_SUBMIT_ERROR = '[Reward Request] Reward Submit Error',

  REWARD_REQUEST_VALIDATE_REQUEST = '[Reward Request] Validate Request',
  REWARD_REQUEST_VALIDATE_RESPONSE = '[Reward Request] Validate Response',
  REWARD_REQUEST_VALIDATE_RESET = '[Reward Request] Validate Reset',
  REWARD_REQUEST_VALIDATE_RESET_AMOUNT_COUPON = '[Reward Request] Validate Reset Amount Coupon',

  REWARD_REQUEST_GET_BY_ID_REQUEST = '[Reward Request] Get Reward Request by Id Request',
  REWARD_REQUEST_GET_BY_ID_RESPONSE = '[Reward Request] Get Reward Request by Id Response',
  REWARD_REQUEST_GET_BY_ID_ERROR = '[Reward Request] Get Reward Request by Id Error',
  REWARD_REQUEST_GET_BY_ID_RESET = '[Reward Request] Get Reward Request by Id Reset',
  REWARD_REQUEST_GET_BY_ID_RESET_PURCHASE_TIER = '[Reward Request] Get Reward Request by Id Reset Purchase Tier',

  REWARD_REQUEST_APPROVE_REQUESTED = '[Reward Request] Reward Request Approve Requested',
  REWARD_REQUEST_REJECT_REQUESTED = '[Reward Request] Reward Request Reject Requested',
  REWARD_REQUEST_CANCEL_REQUESTED = '[Reward Request] Reward Request Cancel Requested',

  REWARD_REQUEST_DELETE_REQUESTED = '[Reward Request] Reward Request Delete Requested'
}

export class RewardApproveRequestedAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_APPROVE_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class RewardRejectRequestedAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_REJECT_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class RewardRequestCancelRequestedAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_CANCEL_REQUESTED;
  constructor(public payload: RequestWithComment) {}
}

export class RewardRequestListRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_LIST_REQUEST;

  constructor(public payload: RewardRequestSearchCriteria) {}
}

export class RewardRequestListResponseAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_LIST_RESPONSE;

  constructor(public payload: RewardRequestListResponse) {}
}

export class RewardRequestSaveRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SAVE_REQUEST;

  constructor(public payload: RewardRequestResponse) {}
}

export class RewardRequestSaveResponseAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SAVE_RESPONSE;

  constructor(public payload: any) {}
}

export class RewardRequestSaveResetAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SAVE_RESET;
}

export class RewardEditSaveRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_EDIT_SAVE_REQUEST;
  constructor(public payload: RewardRequestResponse) {}
}

export class RewardRequestSubmitRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_REQUEST;

  constructor(public payload: RewardRequestResponse) {}
}

export class RewardRequestSubmitResponseAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_RESPONSE;

  constructor(public payload: RewardRequestResponse) {}
}

export class RewardRequestSubmitResetAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_RESET;
}

export class RewardRequestSubmitErrorAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export class RewardRequestValidateActionRequest implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_REQUEST;
  constructor(public payload: RewardValidate) {}
}

export class RewardRequestValidateActionResponse implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESPONSE;
  constructor(public payload: RewardValidateResponse) {}
}

export class ResetRewardRequestValidateAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESET;
}

export class ResetRewardAmountCouponRequestValidateAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESET_AMOUNT_COUPON;
}

export class RewardEditSaveRequestActionSuccess implements Action {
  readonly type = RewardRequestActionTypes.REWARD_EDIT_SAVE_SUCCESS;
  constructor(public payload: { isSave: boolean }) {}
}

export class RewardRequestByIdRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: { requestId: string }) {}
}

export class RewardRequestByIdResponseAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: { RewardRequestView: RewardRequestResponse }) {}
}

export class RewardRequestByIdErrorAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetRewardByIdRequestSelected implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESET;
}

export class ResetPurchaseTierRewardByIdRequestSelected implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESET_PURCHASE_TIER;
}

export class RewardRequestDeleteRequestAction implements Action {
  readonly type = RewardRequestActionTypes.REWARD_REQUEST_DELETE_REQUESTED;

  constructor(public payload: any) {}
}

export type RewardRequestAction =
  | RewardRequestDeleteRequestAction
  | RewardApproveRequestedAction
  | RewardRejectRequestedAction
  | RewardRequestCancelRequestedAction
  | RewardRequestListRequestAction
  | RewardRequestListResponseAction
  | RewardRequestSaveRequestAction
  | RewardRequestSaveResponseAction
  | RewardRequestSaveResetAction
  | RewardEditSaveRequestAction
  | RewardEditSaveRequestActionSuccess
  | RewardRequestValidateActionRequest
  | RewardRequestValidateActionResponse
  | ResetRewardRequestValidateAction
  | RewardRequestByIdRequestAction
  | RewardRequestByIdResponseAction
  | RewardRequestByIdErrorAction
  | ResetRewardByIdRequestSelected
  | ResetPurchaseTierRewardByIdRequestSelected
  | RewardRequestSubmitRequestAction
  | RewardRequestSubmitResponseAction
  | RewardRequestSubmitResetAction
  | RewardRequestSubmitErrorAction
  | ResetRewardAmountCouponRequestValidateAction;
