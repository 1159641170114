<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Delivery Details</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="form">
    <div class="row">
      <div class="col-md-6 mb-3">
        <div class="form-group">
          <label for="storeOpenDate">Store Open Date<span class="text-danger">*</span></label>
          <input
            id="storeOpenDate"
            name="storeOpenDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': submitted && form.controls.storeOpenDate.errors
            }"
            bsDatepicker
            [bsConfig]="bsConfig"
            formControlName="storeOpenDate"
            readonly
          />
          <label for="storeOpenDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="submitted && form.controls.storeOpenDate.errors" class="invalid-feedback">
            <div *ngIf="form.controls.storeOpenDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="form.controls.storeOpenDate.errors.beforeToday">
              Date is invalid.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details">
      <div *ngFor="let segment of segmentList" class="form-card">
        <h6>{{ segment.title }}</h6>
        <div class="row">
          <div class="col-md-6" *ngFor="let prop of segment.properties">
            <app-delivery-schedule
              [parentForm]="deliveryDetailsByType"
              [submitted]="submitted"
              [openDate]="form.value.storeOpenDate"
              [segment]="prop"
              [noOfDateBefore]="segment.noOfDateBefore"
              (applyToAll)="applyToAll($event)"
              [mode]="mode"
            ></app-delivery-schedule>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="mode !== pageMode.REQUEST_VIEW">
    <button type="button" class="btn btn-standard" (click)="decline()" id="cancelBtn">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirm()" id="okBtn">
      OK
    </button>
  </div>
</div>
