<div class="admin-form mt-2">
  <div class="d-md-flex">
    <div class="page-title">
      <em class="page-title-icon icon-stock"></em>
      <h4>Stock Information</h4>
      <span class="selected-location" *ngIf="selectedLocation">: {{ selectedLocation.displayName }}</span>
    </div>

    <div class="select-location ml-auto" *ngIf="locations.length > 1">
      <button
        type="button"
        #pop="bs-popover"
        id="btnSelectLocation"
        name="btnSelectLocation"
        class="btn btn-standard"
        [popover]="moreLocation"
        placement="bottom"
        containerClass="fl"
        [outsideClick]="true"
        (click)="openPopover(pop)"
      >
        <em class="icon-pin"></em>Select Location
      </button>
      <ng-template #moreLocation>
        <div class="form-row">
          <button
            type="button"
            class="btn btn-link"
            *ngFor="let location of locations"
            (click)="selectLocation(location)"
          >
            {{ location.displayName }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>

  <hr class="header-divider" />

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()" *ngIf="selectedLocation">
    <div class="flex-item d-md-flex justify-content-between">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="openExportModal($event)"></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="productAssortmentStatus"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            bindLabel="label"
            bindValue="value"
            formControlName="productStatus"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Segment
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="listOfValue[this.assortmentAllType.SEGMENT]"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="false"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="segment"
                          (change)="onChangeSelectDropdown($event, assortmentAllType.SEGMENT)"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Family
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="listOfValue[this.assortmentAllType.FAMILY]"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="false"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="family"
                          (change)="onChangeSelectDropdown($event, assortmentAllType.FAMILY)"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Class
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="listOfValue[this.assortmentAllType.CLASS]"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="false"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="classCode"
                          (change)="onChangeSelectDropdown($event, assortmentAllType.CLASS)"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Sub Class
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="listOfValue[this.assortmentAllType.SUBCLASS]"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="false"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="subClass"
                          (change)="onChangeSelectDropdown($event, assortmentAllType.SUBCLASS)"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Product Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="productTypeSelect"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="productType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Delivery Method
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="deliveryMethodSelect"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="deliveryMethod"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="
      segmentStringTag ||
      familyStringTag ||
      classCodeStringTag ||
      subClassStringTag ||
      productTypeStringTag ||
      deliveryMethodStringTag
    "
  >
    <div class="search-criteria" *ngIf="segmentStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.SEGMENT)">
        <em class="icon-close"></em>
      </a>
      <span>{{ segmentStringTag }}: {{ segmentTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="familyStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.FAMILY)">
        <em class="icon-close"></em>
      </a>
      <span>{{ familyStringTag }}: {{ familyTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="classCodeStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.CLASS_CODE)">
        <em class="icon-close"></em>
      </a>
      <span>{{ classCodeStringTag }}: {{ classCodeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="subClassStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.SUB_CLASS)">
        <em class="icon-close"></em>
      </a>
      <span>{{ subClassStringTag }}: {{ subClassTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="productTypeStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.PRODUCT_TYPE)">
        <em class="icon-close"></em>
      </a>
      <span>{{ productTypeStringTag }}: {{ productTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="deliveryMethodStringTag">
      <a class="remove-criteria" (click)="clearAdvanceSearch(advanceFilterFormName.DELIVERY_METHOD)">
        <em class="icon-close"></em>
      </a>
      <span>{{ deliveryMethodStringTag }}: {{ deliveryMethodTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceSearchAll()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="selectedLocation && (listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let products of resultList$ | async; index as i">
      <div class="row">
        <div class="px-3 catalog-img-container">
          <div class="catalog-img">
            <img
              *ngIf="products.productImages && products.productImages.length > 0; else imageErr"
              appSrc="{{ products.productImages[0].smallSizeFilePath }}"
              alt="{{ products.productImages[0].fileName }}"
            />
            <ng-template #imageErr>
              <img width="200" height="200" src="/assets/images/not-found.svg" alt="not found" />
            </ng-template>
          </div>
        </div>

        <div class="col-12 col-md-10 flex-item d-flex flex-column cursor-pointer" (click)="goToView(products)">
          <div class="px-3">
            <div class="row">
              <label id="product-name" class="chk-container"
                ><app-click-to-copy [text]="products.productName"></app-click-to-copy
              ></label>
              <span
                [ngClass]="getColorStatus(products.productStatus)"
                class="card-status product-assortment-status ml-auto"
              >
                {{ 'PRODUCT_ASSORTMENT.PRODUCT_STATUS.' + products.productStatus | translate }}
              </span>
            </div>
            <div class="row">
              <label class="catalog-description">{{
                products.segmentName +
                  ' > ' +
                  products.familyName +
                  ' > ' +
                  products.className +
                  ' > ' +
                  products.subClassName
              }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-articleNo">Article No.</span>
              <span class="result-value" id="product-txt-articleNo"
                ><app-click-to-copy [text]="products.articleNo"></app-click-to-copy
              ></span>
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-total-stock">Total Stock</span>
              <span class="result-value" id="product-txt-total-stock"
                >{{ products.totalStock | numberFormatDisplay: 0 }}
              </span>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-productType">Product Type</span>
              <span class="result-value" id="product-txt-productType">{{
                products.productType
                  ? ('PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + products.productType | translate)
                  : (null | dashDisplay)
              }}</span>
            </div>
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="product-lb-lastUpdate">Delivery Method</span>
              <span class="result-value" id="product-txt-delivery-method"
                >{{
                  products.productType
                    ? ('PRODUCT_ASSORTMENT.DELIVERY_METHOD.' + products.deliveryMethod | translate)
                    : (null | dashDisplay)
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-2 action-box"></div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="selectedLocation && (listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!selectedLocation">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="assets/images/no-location-select.svg" alt="No Location Select" />
      </div>
    </div>
  </ng-container>

  <div class="modal fade" bsModal #exportProductModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Export Stock by Product</h5>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeExportModal(exportType.PRODUCT)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportProductForm" (ngSubmit)="onExportProduct()">
          <div class="form-row">
            <div class="form-group  col-md-12">
              <div class="mb-2">
                Segment
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="exportListOfValue[this.assortmentByExportFormAllType.SEGMENT]"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="nameEn"
                bindValue="code"
                formControlName="segment"
                (change)="onChangeSelectDropdownExport($event, assortmentByExportFormAllType.SEGMENT)"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Family
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="exportListOfValue[this.assortmentByExportFormAllType.FAMILY]"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="nameEn"
                bindValue="code"
                formControlName="family"
                (change)="onChangeSelectDropdownExport($event, assortmentByExportFormAllType.FAMILY)"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Class
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="exportListOfValue[this.assortmentByExportFormAllType.CLASS]"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="nameEn"
                bindValue="code"
                formControlName="classCode"
                (change)="onChangeSelectDropdownExport($event, assortmentByExportFormAllType.CLASS)"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Sub Class
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="exportListOfValue[this.assortmentByExportFormAllType.SUBCLASS]"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
                bindLabel="nameEn"
                bindValue="code"
                formControlName="subClass"
                (change)="onChangeSelectDropdownExport($event, assortmentByExportFormAllType.SUBCLASS)"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Product Type
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="productTypeSelect"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="productType"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Product Status
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="productStatus"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="productStatus"
              >
              </ng-select>
            </div>
          </div>

          <div class="alert-danger w-100" *ngIf="responseExportError">
            {{ responseExportError }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onExportProduct()" id="btnExport">
            Export
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" bsModal #exportLocationModal="bs-modal">
    <div id="modal-export-location" class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title pull-left">Export Stock by Location</h5>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeExportModal(exportType.LOCATION)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportLocationForm" (ngSubmit)="onExportLocation()">
          <div class="form-row">
            <div class="form-group  col-md-12">
              <div class="mb-2">Location<span class="text-danger">*</span></div>
              <ng-select
                #locationSelect
                [items]="locationList$ | async"
                placeholder="Please select..."
                [loading]="locationSearchLoading"
                [typeahead]="locationSearchInput$"
                [searchable]="true"
                [clearable]="true"
                bindLabel="label"
                formControlName="location"
              >
              </ng-select>
            </div>
            <div class="form-group  col-md-12">
              <div class="mb-2">
                Product Type
              </div>
              <ng-select
                placeholder="Please select..."
                [items]="productTypeSelect"
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="productType"
              >
              </ng-select>
            </div>

            <div class="form-group  col-md-12">
              <div class="mb-2">
                Product Status
              </div>
              <div class="form-group">
                <ng-select
                  placeholder="Please select..."
                  [items]="productStatus"
                  [searchable]="false"
                  [clearable]="false"
                  [multiple]="true"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="productStatus"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="alert-danger w-100" *ngIf="responseExportError">
            {{ responseExportError }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExportLocation()"
            id="btnExport-location"
            [disabled]="exportLocationFormInValid"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
