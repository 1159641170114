import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-assortment-select-warehouse-modal',
  templateUrl: './assortment-select-warehouse-modal.component.html',
  styleUrls: ['./assortment-select-warehouse-modal.component.scss']
})
export class AssortmentSelectWarehouseModalComponent implements OnInit {
  public productDetailsForm: FormGroup;
  public form: FormGroup;
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store.select(selectWarehouseList);

  constructor(
    private readonly fb: FormBuilder,
    public bsModalRef: BsModalRef,
    protected readonly store: Store<AppStates>
  ) {}

  ngOnInit() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: true }));
    this.form = this.fb.group({
      warehouses: []
    });
    if (this.productDetailsForm.controls.warehouses.value.length > 0) {
      this.form.controls.warehouses.setValue(this.productDetailsForm.controls.warehouses.value);
    }

    this.matchSelectedWarehouse();
  }

  closeSelectWarehouseModal() {
    this.form.controls.warehouses.setValue(null);
    this.bsModalRef.hide();
  }

  onAddSelectWarehouse() {
    this.matchSelectedWarehouse();

    this.bsModalRef.hide();
  }

  matchSelectedWarehouse() {
    this.warehouseList$
      .pipe(
        filter(Boolean),
        withLatestFrom((warehouseList: SelectWarehouseList[]) => {
          if (this.form.controls.warehouses.value && this.form.controls.warehouses.value.length > 0) {
            const matchedWarehouseList = warehouseList.filter(warehouse => {
              return this.form.controls.warehouses.value.some(selected => warehouse.id === selected.id);
            });
            return matchedWarehouseList;
          }
          return [];
        }),
        map(matchedWarehouse => {
          this.form.controls.warehouses.setValue(matchedWarehouse);
          this.productDetailsForm.controls.warehouses.setValue(matchedWarehouse);
        })
      )
      .subscribe();
  }
}
