import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RewardCondition, RewardOffer, RewardPageModes } from '../../../shared/enum/reward.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import * as rewardModel from '../../../shared/models/reward.model';
import { RewardRequestService } from '../../../shared/services/reward-request.service';
import { selectRewardRequest } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { StoreImportModalComponent } from './store-import-modal/store-import-modal.component';

@Component({
  selector: 'app-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.scss']
})
export class SelectStoreComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: rewardModel.ConditionPurchase;

  public formImport: FormGroup;
  public importStoreModal: BsModalRef;
  public storeSelect = filterDropdown.rewardStore;
  public storeSelected = null;
  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    },
    {
      type: ButtonType.IMPORT,
      name: 'Import'
    }
  ];

  public masterDataEnum = MasterDataEnum;

  public currentPage: number;
  public pageSize: number;
  public storeItems: rewardModel.Store[];

  headerRow: string[];
  public rewardRequestView$: Observable<rewardModel.RewardRequestResponse>;
  private localStore: Observable<any>;

  @ViewChild('importModal', { static: false }) importModal: ModalDirective;

  constructor(
    protected readonly modalService: BsModalService,
    public fb: FormBuilder,
    protected readonly rewardRequestService: RewardRequestService,
    protected readonly store: Store<AppStates>,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.headerRow = ['No.', 'Store Code ', 'Store Name', 'Store Type', 'Region', 'Province', 'Limit Offer'];
    this.storeItems = [];
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parentForm.addControl('selectStore', this.createForm());
    this.createFormImport();

    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }

    if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
      this.selectStore.disable();
    } else {
      this.disableLimitOfferSupplier();
    }

    this.currentPage = 1;
    this.pageSize = 20;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  createFormImport() {
    this.formImport = this.fb.group({
      files: [null, Validators.required]
    });
  }

  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(res => {
        this.selectStore.controls['selectStore'].setValue(res.selectStore);
        this.selectStore.controls['limitOffer'].setValue(res.limitOffer);
        this.selectStore.controls['unlimitedOffer'].setValue(res.unlimitedOffer);
        this.storeSelected = res.selectStore;

        if (this.storeSelected === 'SELECT_BY_STORE') {
          this.selectStore.controls['limitOffer'].clearValidators();
          this.selectStore.controls['limitOffer'].updateValueAndValidity();
        }

        this.generateStoreList(res.stores);
      });
  }

  createForm() {
    return this.fb.group({
      selectStore: [{ value: null, disabled: false }, [Validators.required]],
      limitOffer: this.validateRequired,
      unlimitedOffer: [{ value: false, disabled: false }],
      stores: this.fb.array([])
    });
  }

  createFormStoreArray() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    return this.fb.group({
      no: initialNull,
      code: initialNull,
      name: initialNull,
      type: initialNull,
      province: initialNull,
      region: initialNull,
      limitOffer: initialNull
    });
  }

  generateStoreList(stores: any[]) {
    if (stores && stores.length) {
      this.storeItems = stores;
      stores.forEach(store => {
        this.formControlStore.push(this.createFormStoreArray());
        const controlItem = this.formControlStore[this.formControlStore.length - 1];
        controlItem.patchValue(store);
        this.formArrayStore.updateValueAndValidity({ onlySelf: false });
      });
    }
  }

  setEditMode() {
    this.selectStore.enable();

    if (this.selectStore.controls['unlimitedOffer'].value) {
      this.selectStore.controls['limitOffer'].disable();
    }

    this.disableLimitOfferSupplier();
  }

  refreshControl(condition: any) {
    this.conditionPurchase = condition;
    const storeSelected = this.selectStore.controls['selectStore'].value;
    this.onchangeStore({ value: storeSelected });
  }

  onchangeStore(event): void {
    this.storeSelected = event.value;

    this.storeItems = [];
    this.formArrayStore.clear();

    // this.selectStore.controls['unlimitedOffer'].setValue(false);
    // this.selectStore.controls['limitOffer'].reset(null);

    if (this.storeSelected === 'SELECT_BY_STORE') {
      this.selectStore.controls['limitOffer'].disable();
    } else {
      this.selectStore.controls['limitOffer'].enable();
      this.selectStore.controls['limitOffer'].setValidators([Validators.required, isZeroValidator()]);

      if (this.disableLimitOfferSupplier()) {
        this.selectStore.controls['unlimitedOffer'].setValue(true);
      }
    }
    this.selectStore.controls['limitOffer'].updateValueAndValidity();
  }

  disableLimitOfferSupplier(): boolean {
    if (
      this.conditionPurchase.condition === RewardCondition.SUPPLIER_COUPON &&
      this.conditionPurchase.offer === RewardOffer.COUPON
    ) {
      this.selectStore.controls['limitOffer'].disable();
      this.selectStore.controls['unlimitedOffer'].disable();

      return true;
    } else {
      this.selectStore.controls['limitOffer'].enable();

      if (this.selectStore.controls['unlimitedOffer'].value) {
        this.selectStore.controls['limitOffer'].disable();
      } else {
        this.selectStore.controls['unlimitedOffer'].enable();
      }
    }

    return false;
  }

  onChangeUnlimited(event): void {
    this.selectStore.controls['limitOffer'].reset(null);
    if (event.target.checked) {
      this.selectStore.controls['limitOffer'].disable();
    } else {
      this.selectStore.controls['limitOffer'].setValidators([Validators.required, isZeroValidator()]);
      this.selectStore.controls['limitOffer'].enable();
    }

    this.selectStore.controls['limitOffer'].updateValueAndValidity();
  }

  checkAndConfirmImportStore() {
    if (this.storeItems.length === 0) {
      this.openImportModal();
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you to import new file? All existing data will be lost.`,
        okText: 'OK'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeItems = [];
          this.formArrayStore.clear();
          confirmModalRef.hide();
          this.openImportModal();
        }
      });
  }

  openImportModal() {
    const initialState = {
      conditionPurchase: this.conditionPurchase
    };
    this.importStoreModal = this.modalService.show(StoreImportModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState
    });

    this.importStoreModal.content.stores.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.generateStoreList(result);
      this.alertModal('The data have been imported.');
    });
  }

  onExport() {
    const rawDataDetails = (this.parentForm.get('details') as FormGroup).getRawValue();
    const rawData = this.selectStore.getRawValue();

    const storesData = rawData.stores;
    const fileName = rawDataDetails.rewardName
      ? rawDataDetails.rewardName
      : environment.fileName.exportRewardRequestConfigs.prefix;

    this.rewardRequestService.downloadFileStore(storesData, this.conditionPurchase.condition).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileName} ${this.suffixFileExport}`);
      },
      error => console.log(error.error.message)
    );
  }

  alertModal(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message
      }
    });
  }

  get showLimitOffer(): boolean {
    return !(
      this.conditionPurchase.condition === RewardCondition.SUPPLIER_COUPON &&
      this.conditionPurchase.offer === RewardOffer.COUPON
    );
  }

  get validateRequired() {
    return [{ value: null, disabled: false }, [Validators.required, isZeroValidator()]];
  }

  get selectStore() {
    return this.parentForm.get('selectStore') as FormGroup;
  }

  get formArrayStore() {
    return this.selectStore.get('stores') as FormArray;
  }

  get formControlStore(): AbstractControl[] {
    return (this.selectStore.get('stores') as FormArray).controls;
  }

  get suffixFileExport(): string {
    return `${moment().format(environment.fileName.exportRewardRequestConfigs.timeFormat)}.xlsx`;
  }

  get pageMode() {
    return RewardPageModes;
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('selectStore');
  }
}
