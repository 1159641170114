import { createSelector } from '@ngrx/store';
import * as fromRewardActiveState from '../reducers/reward-active.reducers';
import * as fromRewardState from '../reducers/reward.reducers';
import { AppStates } from '../state/app.states';

const selectRewardState = (state: AppStates) => state.reward;
const selectRewardActiveState = (state: AppStates) => state.rewardActive;

export const selectAllRewardList = createSelector(selectRewardState, fromRewardState.selectAll);

export const selectRewardList = createSelector(selectRewardState, (state: fromRewardState.RewardState) => state);

export const selectRewardListCriteria = createSelector(selectRewardState, state => {
  return state.criteriaObject;
});

export const selectReward = createSelector(selectRewardState, (state: fromRewardState.RewardState) => state.selected);

export const selectRewardActiveList = createSelector(
  selectRewardActiveState,
  (state: fromRewardActiveState.RewardActiveState) => state
);

export const selectRewardActiveListCriteria = createSelector(selectRewardActiveState, state => {
  return state.criteriaObject;
});

export const selectRewardActiveSummary = createSelector(selectRewardActiveState, state => {
  return state.summary;
});

export const selectRewardActiveListAll = createSelector(selectRewardActiveState, fromRewardActiveState.selectAll);
