import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { OrderPolicyComponent } from '../../../shared/components/td-store/order-policy/order-policy.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  ErrorResponse,
  StorePageTabs,
  StoreProfile,
  StoreRequestListSearchCriteria,
  StoreRequestTemplate,
  StoreRequestViewResponse,
  TaskModuleUrl
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  ResetStoreRequestSelected,
  StoreApproveRequestAction,
  StoreCreateResetAction,
  StoreCreateSaveRequestAction,
  StoreCreateSubmitRequestAction,
  StoreMerchantByIdRequestAction,
  StoreRequestByIdRequestAction,
  StoreRequestListRequestAction
} from '../../../shared/store/actions/store-request.actions';
import { StoreCreateResponseState } from '../../../shared/store/reducers/store-create.reducers';
import { selectStore, selectStoreApproveRejectStatus } from '../../../shared/store/selectors/store-create.selectors';
import {
  selectStoreRequestById,
  selectStoreRequestListCriteria
} from '../../../shared/store/selectors/store-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../shared/utils/date-util';
import { getFileId, getMultipleFileId } from '../../../shared/utils/get-fileId-util';
import { merchantColorStatus } from '../../../shared/utils/merchant-color-status-util';
import { removeObjectArray } from '../../../shared/utils/remove-object-array.util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';
@Component({
  selector: 'app-store-request',
  templateUrl: './store-request.component.html',
  styleUrls: ['./store-request.component.scss']
})
export class StoreRequestComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('storeProfile', { static: false }) storeProfile: StoreProfileComponent;
  @ViewChild('orderPolicy', { static: false }) orderPolicy: OrderPolicyComponent;

  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    merchant?: string;
    storeId?: string;
    originPage?: string;
  };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public saved: boolean;
  public page: TDStorePage;
  public type: RequestTypeEnum;
  public step: RequestStepEnum;
  public status: RequestStatusEnum;
  public taxID: string;
  public storeForm: FormGroup;
  public storeView$: Observable<StoreRequestViewResponse>;
  public submitted: boolean;

  private isApprove: boolean;
  private localStore: Observable<any>;
  private listSearchCriteria: StoreRequestListSearchCriteria;
  private requestNo: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected readonly modalService: BsModalService,
    protected spinner: NgxSpinnerService,
    private readonly translate: TranslateService,
    protected storeRequestService: StoreRequestService
  ) {}

  ngOnInit() {
    this.initControl();
  }

  ngAfterViewInit() {
    this.initState();
  }

  ngOnDestroy() {
    this.store.dispatch(new StoreCreateResetAction());
    this.store.dispatch(new ResetStoreRequestSelected());
  }

  refreshStoreList(isResetToFirstPage = false) {
    if (isResetToFirstPage) {
      this.listSearchCriteria.page = 0;
    }

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new StoreRequestListRequestAction(this.listSearchCriteria));
    }
  }

  get storePageTabs() {
    return StorePageTabs;
  }

  get TDStorePage() {
    return TDStorePage.STORE_REQUEST;
  }

  get storePageMode() {
    return RequestPageModesEnum;
  }

  get profile() {
    return this.storeForm.get('storeProfile')['controls'][0] as FormGroup;
  }

  get storeFormArray() {
    return this.storeForm.get('storeProfile') as FormArray;
  }

  getColorStatus(status: string): string {
    return merchantColorStatus(status);
  }

  initState() {
    this.page = TDStorePage.STORE_REQUEST;
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new StoreRequestByIdRequestAction({ storeId: this.data.storeId }));
    } else if (RequestPageModesEnum.REQUEST_CREATE === this.data.mode) {
      this.store.dispatch(new StoreMerchantByIdRequestAction({ merchant: this.data.merchant }));
    }

    // Current searchCriteria
    this.localStore
      .pipe(select(selectStoreRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore.pipe(select(selectStore)).subscribe((data: StoreCreateResponseState) => {
      if (data && data.result && data.result.response) {
        this.alertSuccessModal(this.getSubmitMessage(data.isSave));
      }

      if (RequestPageModesEnum.REQUEST_EDIT === this.data.mode) {
        this.onTriggerEdit();
      }
    });

    this.storeView$ = this.localStore.pipe(select(selectStoreRequestById));
    this.storeView$
      .pipe(
        filter(value => Boolean(value)),
        tap(value => {
          this.type = value.type || RequestTypeEnum.NEW;
          this.status = value.status || RequestStatusEnum.DRAFT;
          this.step = value.step || RequestStepEnum.PROFILE;
          this.requestNo = value.requestNo;
          this.taxID = value.taxId;

          this.storeFormArray.controls.forEach(item => {
            item.get('taxNo').setValue(this.taxID);
          });
        })
      )
      .subscribe();
  }

  initControl() {
    this.storeForm = this.fb.group({});
  }

  onSave() {
    this.saved = true;
    const storeProfile = this.storeForm.getRawValue().storeProfile[0];

    // require storeCode and storeName before saved
    if (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      (!storeProfile.storeCode || !storeProfile.storeName)
    ) {
      return;
    }

    this.store.dispatch(new StoreCreateSaveRequestAction(this.prepareStoreRequest()));
  }

  onSubmit() {
    this.submitted = true;

    if (this.storeForm.invalid) {
      return;
    }

    this.handleConfirm();

    return;
  }

  onCancel() {
    if (this.storeForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const storeRequestTemplate = this.prepareStoreRequest();
          this.store.dispatch(new StoreCreateSubmitRequestAction(storeRequestTemplate));
        }
      });
  }

  prepareStoreRequest(): StoreRequestTemplate {
    const storeRequestTemplate = new StoreRequestTemplate();
    const formData = this.storeForm.getRawValue();
    const store = formData.storeProfile[0];
    const phone: { internationalNumber; nationalNumber } = store.phone;
    let storeProfileData = new StoreProfile();

    this.storeView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          storeRequestTemplate.id = value.id;
          storeRequestTemplate.step = value.step;
          storeRequestTemplate.version = value.version;

          if (value.merchantInfo) {
            storeProfileData = value.merchantInfo.storeProfile[0];
          }
        })
      )
      .subscribe();

    storeRequestTemplate.merchantNo = this.data.merchant;
    storeRequestTemplate.storeProfile = {
      ...store,
      openDate: store.openDate ? formatDateStartOfDay(store.openDate, env.dateISO8601) : null,
      storeNo: storeProfileData && storeProfileData.storeNo,
      countryCode:
        phone && phone.internationalNumber
          ? phone.internationalNumber.split(' ')[0]
          : storeProfileData && storeProfileData.countryCode,
      phone:
        phone && phone.nationalNumber
          ? phone.nationalNumber.replace(/ /g, '')
          : storeProfileData && storeProfileData.phone,
      rentalFee:
        !this.profile.controls.rentalFee.disabled && store.rentalFee
          ? { amount: store.rentalFee, currency: 'THB' }
          : null,
      poiType: store.poiType && store.poiType.join(','),
      storeFrontPicture: store.storePicFront && getMultipleFileId(store.storePicFront),
      storeFrontVideo: getFileId(store.storeVDOFront),
      titleDeed: getFileId(store.titleDeed),
      houseRegistrationPicture: getFileId(store.houseRegistrationPicture),
      idCardPicture: getFileId(store.idCardPicture),
      consentLetterPicture: getFileId(store.consentLetterPicture),
      attachmentPicture: store.attachmentPicture && getMultipleFileId(store.attachmentPicture),
      deliveryTimes: removeObjectArray(store.deliveryTimes),
      nearByPois: removeObjectArray(store.nearByPois),
      nearByCompetitors: removeObjectArray(store.nearByCompetitors)
    };
    formData.orderSchedule.orderScheduleList[0].schedules = removeObjectArray(
      formData.orderSchedule.orderScheduleList[0].schedules
    );
    storeRequestTemplate.orderSchedule = formData.orderSchedule.orderScheduleList[0];

    return storeRequestTemplate;
  }

  doApproveRejectRequest(isApprove: boolean) {
    this.isApprove = isApprove;

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectStoreApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess({
                result: {
                  response: 'success',
                  errorResponse: null
                }
              });
            });
          this.store.dispatch(
            new StoreApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  cancelStoreRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteStoreRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeRequestService
            .deleteByRequestId({ storeId: this.data.storeId })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertApproveRejectModalSuccess(resp) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
        this.refreshStoreList(this.status === RequestStatusEnum.DRAFT);
      }

      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  hasSavePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSavePermission(this.type, this.page, this.step, this.status);
  }

  hasEditStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, this.status);
  }

  hasSubmitStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasApproveStorePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasApprovePermission(this.type, this.page, this.step, this.status);
  }

  hasCancelPermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasCancelPermission(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasDeletePermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasDeletePermission(this.type, this.page, this.step, this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditStorePermission() ||
      this.hasApproveStorePermission() ||
      this.hasSubmitStorePermission()
    );
  }

  onTriggerEdit() {
    this.data.title = 'Edit Store Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(this.page)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, editSection)) {
      this.storeProfile.toggleEditStoreProfile();
    }

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.ORDER_POLICY)) {
      this.scrollTo(RequestSectionEnum.ORDER_POLICY);
      this.orderPolicy.toggleEditOrderPolicy();
    }
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
        this.refreshStoreList(this.status === RequestStatusEnum.DRAFT);
      }

      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  scrollTo(anchor: RequestSectionEnum) {
    const element = document.getElementById(anchor);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
    }
  }

  getSubmitMessage(isSave: boolean): string {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === RequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.step === RequestStepEnum.ORDER_POLICY) {
      return 'The store has been created.';
    } else {
      return 'The request has been updated.';
    }
  }
}
