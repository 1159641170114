<div [formGroup]="parentForm">
  <div
    class="row"
    formArrayName="schedules"
    *ngFor="let tdStore of schedules.controls; let i = index; first as firstIndex; last as lastIndex"
  >
    <div class="col-md-12 inline-flex mb-3 align-items-baseline">
      <div class="row flex-item align-items-baseline" [formGroupName]="i">
        <div class="col">
          <label for="orderScheduleDate" *ngIf="firstIndex">
            Order Schedule Date<span class="text-danger">*</span>
          </label>
          <ng-select
            id="orderScheduleDate"
            placeholder="Please select..."
            [items]="orderScheduleDateOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="orderScheduleDate"
            (change)="onSelectedOrderScheduleDate(i)"
            (open)="updateOrderScheduleDateOptions(i)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.orderScheduleDate.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && tdStore.controls.orderScheduleDate.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.orderScheduleDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col pl-0">
          <label for="orderScheduleDate" *ngIf="firstIndex"> Order Time<span class="text-danger">*</span> </label>
          <ng-select
            id="orderScheduleTime"
            placeholder="Please select..."
            [items]="(merchantSelectValue$ | async)?.data?.orderScheduleTime"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="orderScheduleTime"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.orderScheduleTime.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && tdStore.controls.orderScheduleTime.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.orderScheduleTime.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col">
          <label for="deliverySchedule" *ngIf="firstIndex">Delivery Schedule<span class="text-danger">*</span></label>
          <ng-select
            id="deliverySchedule"
            placeholder="Please select..."
            [items]="deliveryScheduleDateOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="deliverySchedule"
            (change)="onSelectedDeliveryScheduleDate(i)"
            (open)="updateDeliveryScheduleDateOptions(i)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.deliverySchedule.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.deliverySchedule.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.deliverySchedule.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col pl-0">
          <label for="pickScheduleDate" *ngIf="firstIndex">Pick Schedule<span class="text-danger">*</span></label>
          <ng-select
            id="pickScheduleDate"
            placeholder="Please select..."
            [items]="pickScheduleOptions"
            [searchable]="false"
            [clearable]="false"
            [multiple]="false"
            bindLabel="nameTh"
            bindValue="code"
            formControlName="pickScheduleDate"
            (open)="updatePickScheduleOptions(tdStore.value.orderScheduleDate, tdStore.value.deliverySchedule)"
            [ngClass]="{
              'is-invalid': submitted && tdStore.controls.pickScheduleDate.errors
            }"
          ></ng-select>
          <div *ngIf="submitted && tdStore.controls.pickScheduleDate.errors" class="invalid-feedback">
            <div *ngIf="tdStore.controls.pickScheduleDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-1 pr-0 pl-0">
        <button
          type="button"
          id="delete-schedule"
          class="btn btn-special-reject ml-2"
          (click)="deleteSchedule(i)"
          title="Delete"
          appTippy
          [tippyOptions]="{ arrow: true }"
          [ngClass]="{
            'is-hidden': firstIndex || !(checkEditPermission() && canEditSection())
          }"
        >
          <em class="icon-delete"></em>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-3">
      <button type="button" id="add-schedule" *ngIf="showBtn()" class="btn btn-secondary" (click)="addSchedule()">
        <em class="icon-plus"></em>
        Add more schedule
      </button>
    </div>
  </div>
</div>
