<div [formGroup]="parentForm">
  <div formGroupName="selectStore">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="selectStore">Store<span class="text-danger">*</span></label>
        <ng-select
          id="selectStore"
          placeholder="Please select..."
          [searchable]="false"
          [clearable]="false"
          [items]="storeSelect"
          formControlName="selectStore"
          (change)="onchangeStore($event)"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{
            'is-invalid': submitted && selectStore.controls.selectStore.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && selectStore.controls.selectStore.errors" class="invalid-feedback">
          <div *ngIf="selectStore.controls.selectStore.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3" *ngIf="storeSelected === 'ALL_STORE'">
        <label for="limitOffer">Limit Offer<span class="text-danger">*</span></label>
        <div class="float-right">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input-label checkbox-modal"
              id="unlimitedOffer"
              formControlName="unlimitedOffer"
              (click)="onChangeUnlimited($event)"
            />
          </div>
          <label for="unlimitedOffer">
            Unlimited
          </label>
        </div>
        <app-numeric-textbox
          id="limitOffer"
          [decimals]="0"
          [maxValue]="999999"
          [minValue]="1"
          [min]="1"
          maxlength="6"
          formControlName="limitOffer"
          [format]="'0,0'"
          [negativeSign]="false"
          placeholder="Limit Offer"
          [ngClass]="{
            'is-invalid': submitted && selectStore.controls.limitOffer.errors
          }"
        ></app-numeric-textbox>
        <div *ngIf="submitted && selectStore.controls.limitOffer.errors" class="invalid-feedback">
          <div *ngIf="selectStore.controls.limitOffer.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="selectStore.controls.limitOffer.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
          </div>
        </div>
      </div>
      <div
        class="col-md-3 btn-import"
        *ngIf="storeSelected === 'SELECT_BY_STORE' && mode != pageMode.REQUEST_VIEW && mode !== pageMode.VIEW"
      >
        <app-import-export-button [buttons]="buttons" (export)="onExport()" (import)="checkAndConfirmImportStore()">
        </app-import-export-button>
      </div>
    </div>

    <div class="row" *ngIf="storeSelected === 'SELECT_BY_STORE' && storeItems.length">
      <div class="col-md-12">
        <table id="Table" class="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th class="text-center w-5">{{ headerRow[0] }}</th>
              <th class="text-center w-10">{{ headerRow[1] }}</th>
              <th class="text-center w-15">{{ headerRow[2] }}</th>
              <th class="text-center w-5">{{ headerRow[3] }}</th>
              <th class="text-center w-10">{{ headerRow[4] }}</th>
              <th class="text-center w-10">{{ headerRow[5] }}</th>
              <th class="text-center w-5" *ngIf="showLimitOffer">{{ headerRow[6] }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of paging.currentPageData; index as itemIndex">
              <tr
                [ngClass]="{
                  even: itemIndex % 2 === 0 && storeItems.length === 0,
                  'even-with-child': itemIndex % 2 === 0 && storeItems.length !== 0,
                  odd: itemIndex % 2 !== 0 && storeItems.length === 0,
                  'odd-with-child': itemIndex % 2 !== 0 && storeItems.length !== 0
                }"
              >
                <td class="text-center align-top">{{ paging.getItemNo(itemIndex) }}</td>
                <td class="align-top">
                  {{ item.code }}
                </td>
                <td class="align-top">
                  {{ item.name }}
                </td>
                <td class="align-top">
                  {{ 'STORE_TYPE.' + item.type | translate }}
                </td>
                <td class="align-top">
                  {{ item.region | masterDataDisplay: masterDataEnum.REGION | async }}
                </td>
                <td class="align-top">
                  {{ item.province | masterDataDisplay: masterDataEnum.STATE | async }}
                </td>
                <td class="text-center align-top" *ngIf="showLimitOffer">
                  {{ item.limitOffer > 0 ? (item.limitOffer | numberFormatDisplay: 0) : 'Unlimited' }}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <!--pagination-->
        <app-ui-pagination #paging [currentPage]="currentPage" [pageSize]="pageSize" [items]="storeItems">
        </app-ui-pagination>
      </div>
    </div>
  </div>
</div>
