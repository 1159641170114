<div [formGroup]="parentForm">
  <div formGroupName="merchantProfile">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="merchantType">Merchant Type<span class="text-danger">*</span></label>
        <ng-select
          id="merchantType"
          placeholder="Please select..."
          [items]="merchantSelectValue?.merchants"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="merchantType"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.merchantType.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && merchantProfile.controls.merchantType.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.merchantType.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantName">Merchant Name<span class="text-danger">*</span></label>
        <input
          id="merchantName"
          name="merchantName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="merchantName"
          placeholder="Merchant Name"
          [ngClass]="{
            'is-invalid': (saved || submitted) && merchantProfile.controls.merchantName.errors
          }"
        />
        <div *ngIf="(saved || submitted) && merchantProfile.controls.merchantName.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.merchantName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantCustomerType">Customer Type<span class="text-danger">*</span></label>
        <ng-select
          id="merchantCustomerType"
          placeholder="Please select..."
          (change)="onChangeMerchantCustomerType($event)"
          [items]="merchantSelectValue?.customers"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="customerType"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.customerType.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && merchantProfile.controls.customerType.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.customerType.errors">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantTaxId">Tax ID/ ID card<span class="text-danger">*</span></label>
        <input
          id="merchantTaxId"
          name="merchantTaxId"
          type="text"
          class="form-control is-invalid"
          maxlength="13"
          formControlName="taxId"
          placeholder="Tax ID/ ID card"
          (change)="onChangeMerchantTaxId($event.target.value)"
          [ngClass]="{
            'is-invalid': isShowDuplicatedError(merchantProfile.controls.taxId)
          }"
          appDigitOnly
        />
        <div *ngIf="merchantProfile.controls.taxId.errors?.duplicated" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.taxId.errors?.duplicated">
            {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Tax ID/ ID card' } }}
          </div>
        </div>
        <div *ngIf="submitted && merchantProfile.controls.taxId.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.taxId.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="merchantProfile.controls.taxId.errors.minlength">
            {{ 'ERRORS.INVALID_FORMAT' | translate: { value: 'Tax ID/ ID card' } }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="merchantAddress">Address<span class="text-danger">*</span></label>
        <input
          id="merchantAddress"
          name="merchantAddress"
          type="text"
          class="form-control"
          maxlength="250"
          formControlName="address"
          placeholder="Address"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.address.errors
          }"
        />
        <div *ngIf="submitted && merchantProfile.controls.address.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.address.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantStateProvince">State / Province<span class="text-danger">*</span></label>
        <input
          id="merchantStateProvince"
          name="merchantStateProvince"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="state"
          placeholder="State / Province"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.state.errors
          }"
        />
        <div *ngIf="submitted && merchantProfile.controls.state.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.state.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantPostCode">PostCode<span class="text-danger">*</span></label>
        <input
          id="merchantPostCode"
          name="merchantPostCode"
          type="text"
          class="form-control"
          maxlength="15"
          formControlName="postCode"
          placeholder="PostCode"
          [ngClass]="{ 'is-invalid': submitted && merchantProfile.controls.postCode.errors }"
        />
        <div *ngIf="submitted && merchantProfile.controls.postCode.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.postCode.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="merchantCountry">Country<span class="text-danger">*</span></label>
        <ng-select
          id="merchantCountry"
          placeholder="Please select..."
          [items]="merchantSelectValue?.countries"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="country"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.country.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && merchantProfile.controls.country.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.country.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="merchantBaseCurrency">Base Currency<span class="text-danger">*</span></label>
        <ng-select
          id="merchantBaseCurrency"
          placeholder="Please select..."
          [items]="listOfValue['baseCurrency']"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="currency"
          [ngClass]="{
            'is-invalid': submitted && merchantProfile.controls.currency.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && merchantProfile.controls.currency.errors" class="invalid-feedback">
          <div *ngIf="merchantProfile.controls.currency.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
