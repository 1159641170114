<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-marketing"></em>
    <h4>Reward</h4>
  </div>
  <app-tab [routeLinkTabs]="listRoute"></app-tab>
  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="exportModal.show()"> </app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="100"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="statusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter" aria-hidden="true"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="condition">Condition</label>
                        <ng-select
                          id="condition"
                          placeholder="Please select..."
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          [items]="conditionSelect"
                          formControlName="condition"
                          bindLabel="label"
                          bindValue="value"
                        >
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="Offer">Offer</label>
                        <ng-select
                          id="offer"
                          placeholder="Please select..."
                          [searchable]="false"
                          [clearable]="false"
                          [items]="offerSelect"
                          [multiple]="true"
                          formControlName="offer"
                          bindLabel="label"
                          bindValue="value"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'REWARD.OFFER_FROM_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="offerFromDateFrom"
                          formControlName="offerFromDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="offerFromDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="offerFromDateTo"
                          formControlName="offerFromDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="offerFromDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'REWARD.OFFER_TO_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="offerToDateFrom"
                          formControlName="offerToDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="offerToMaxDate"
                          (bsValueChange)="onChangeOfferToDateFrom($event)"
                          readonly
                        />
                        <label for="offerToDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="offerToDateTo"
                          formControlName="offerToDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="offerToMinDate"
                          (bsValueChange)="onChangeOfferToDateTo($event)"
                          readonly
                        />
                        <label for="offerToDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="offerFromDateStringTag || offerToDateStringTag || conditionTag || offerTag"
  >
    <div class="search-criteria" *ngIf="offerFromDateStringTag">
      <a class="remove-criteria" (click)="clearFilterOfferFromDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ offerFromDateStringTag }}: {{ offerFromDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="offerToDateStringTag">
      <a class="remove-criteria" (click)="clearFilterOfferToDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ offerToDateStringTag }}: {{ offerToDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="criteriaObject.condition">
      <a class="remove-criteria" (click)="clearFilterCondition()">
        <em class="icon-close"></em>
      </a>
      <span>Condition : {{ conditionTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="criteriaObject.offer">
      <a class="remove-criteria" (click)="clearFilterOffer()">
        <em class="icon-close"></em>
      </a>
      <span>Offer : {{ offerTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let item$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(item$)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">{{ item$.rewardName }}</label>
              <span [ngClass]="getColorStatus(item$.status)" class="card-status reward-status ml-auto">
                {{ item$.status.replace('_', ' ') | titlecase }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Reward No.:</span><span class="result-value">{{ item$.rewardNo }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property">No. of Store:</span
              ><span class="result-value">{{ item$.noOfStore }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Condition:</span
              ><span class="result-value">{{ getRewardCondition(item$.condition) }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Offer:</span
              ><span class="result-value">{{ getRewardOffer(item$.offer) }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Offer from Date:</span
              ><span class="result-value">{{ item$.offerFromDate | dateDisplay: environment.dateFormat }}</span>
            </div>
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Offer to Date:</span
              ><span class="result-value">{{ item$.offerToDate | dateDisplay: environment.dateFormat }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5 col-md-6">
              <span class="result-property">Created Date:</span
              ><span class="result-value">{{ item$.createdDate | dateDisplay: environment.dateFormat }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em
          ></a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                type="button"
                class="btn btn-link"
                *ngIf="showCancelButton(item$.status) && hasEditRewardPermission"
                (click)="goToCancel(item$)"
              >
                <em class="icon-forbidden"></em>Cancel Reward
              </button>
              <button type="button" class="btn btn-link" (click)="showHistory(item$)">
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!-- Modal Export -->
  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Export Reward</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="condition">Condition</label>
              <ng-select
                id="exportCondition"
                placeholder="Please select..."
                [searchable]="false"
                [clearable]="false"
                [items]="conditionSelect"
                formControlName="condition"
                (change)="onExportConditionChange($event)"
                bindLabel="label"
                bindValue="value"
              >
              </ng-select>
            </div>
            <div class="form-group col-md-6">
              <label for="exportOffer">Offer</label>
              <ng-select
                id="exportOffer"
                placeholder="Please select..."
                [searchable]="false"
                [clearable]="false"
                [items]="offerExportSelect"
                formControlName="offer"
                bindLabel="label"
                bindValue="value"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 px-0">Offer from Date</div>
            <div class="form-group col-md-6 pl-0">
              <label>From:</label>
              <input
                id="exportOfferFromDateFrom"
                formControlName="offerFromDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                [maxDate]="exportOfferFromMaxDate"
                (bsValueChange)="onChangeExportOfferFromDateFrom($event)"
                readonly
              />
              <label for="exportOfferFromDateFrom" class="icon-calendar btn-calendar"></label>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>To:</label>
              <input
                id="exportOfferFromDateTo"
                formControlName="offerFromDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                [minDate]="exportOfferFromMinDate"
                (bsValueChange)="onChangeExportOfferFromDateTo($event)"
                readonly
              />
              <label for="exportOfferFromDateTo" class="icon-calendar btn-calendar"></label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12 px-0">Offer to Date</div>
            <div class="form-group col-md-6 pl-0">
              <label>From:</label>
              <input
                id="exportOfferToDateFrom"
                formControlName="offerToDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                [maxDate]="exportOfferToMaxDate"
                (bsValueChange)="onChangeExportOfferToDateFrom($event)"
                readonly
              />
              <label for="exportOfferToDateFrom" class="icon-calendar btn-calendar"></label>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>To:</label>
              <input
                id="exportOfferToDateTo"
                formControlName="offerToDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                [minDate]="exportOfferToMinDate"
                (bsValueChange)="onChangeExportOfferToDateTo($event)"
                readonly
              />
              <label for="exportOfferToDateTo" class="icon-calendar btn-calendar"></label>
            </div>
          </div>

          <div class="form-group">
            <div class="mb-2">
              Status
            </div>
            <div class="form-group">
              <ng-select
                [items]="exportStatusList"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                class="select-status"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
              >
              </ng-select>
            </div>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0" role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="exportFormInValid"
            (click)="onExport()"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
