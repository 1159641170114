import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  RequestTypeEnum,
  RewardCondition,
  RewardOffer,
  RewardPageModes,
  RewardRequestStatusEnum
} from '../../../shared/enum/reward.enum';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  ConditionPurchase,
  RewardRequestResponse,
  RewardRequestSearchCriteria
} from '../../../shared/models/reward.model';
import { AuthGuardService } from '../../../shared/services';
import {
  RewardCancelRequest,
  RewardRequestByNoRequestAction,
  RewardRequestByNoResetAction
} from '../../../shared/store/actions/reward.actions';
import { selectRewardRequestListCriteria } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PurchaseAmountCouponComponent } from '../purchase-amount-coupon/purchase-amount-coupon.component';
import { PurchaseAmountGoodsComponent } from '../purchase-amount-goods/purchase-amount-goods.component';
import { PurchaseQuantityCouponComponent } from '../purchase-quantity-coupon/purchase-quantity-coupon.component';
import { PurchaseQuantityGoodsComponent } from '../purchase-quantity-goods/purchase-quantity-goods.component';
import { RewardDetailsComponent } from '../reward-details/reward-details.component';
import { SelectStoreComponent } from '../select-store/select-store.component';
import { SupplierCouponComponent } from '../supplier-coupon/supplier-coupon.component';

@Component({
  selector: 'app-reward-view',
  templateUrl: './reward-view.component.html',
  styleUrls: ['./reward-view.component.scss']
})
export class RewardViewComponent implements OnInit, OnDestroy {
  @ViewChild('rewardDetails', { static: false }) rewardDetails: RewardDetailsComponent;
  @ViewChild('purchaseAmountCoupon', { static: false }) purchaseAmountCoupon: PurchaseAmountCouponComponent;
  @ViewChild('purchaseAmountGoods', { static: false }) purchaseAmountGoods: PurchaseAmountGoodsComponent;
  @ViewChild('purchaseQuantityCoupon', { static: false }) purchaseQuantityCoupon: PurchaseQuantityCouponComponent;
  @ViewChild('purchaseQuantityGoods', { static: false }) purchaseQuantityGoods: PurchaseQuantityGoodsComponent;
  @ViewChild('supplierCoupon', { static: false }) supplierCoupon: SupplierCouponComponent;
  @ViewChild('selectStore', { static: false }) selectStore: SelectStoreComponent;

  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RewardPageModes;
    rewardNo?: string;
    originPage?: string;
  };

  public rewardForm: FormGroup;
  public status;
  public conditionPurchase: ConditionPurchase;
  version: number;
  rewardType: RequestTypeEnum;
  public criteriaObject: RewardRequestSearchCriteria;
  public isRequestViewMode: boolean;
  public rewardView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  public rewardRequestResponse: RewardRequestResponse;
  isRequestCreateMode: boolean;
  isRequestEditMode: boolean;
  public isEditFirst: boolean;

  hasViewRewardPermission: boolean;
  hasEditRewardPermission: boolean;
  hasApproveRewardPermission: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService
  ) {
    this.hasViewRewardPermission = this.authGuardService.checkPermission(['rw_v']);
    this.hasEditRewardPermission = this.authGuardService.checkPermission(['rw_m']);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.initControl();
    this.initData();
    this.initState();
  }

  initControl() {
    this.rewardForm = this.fb.group({});
  }

  initData() {
    this.version = 0;

    this.status = RewardRequestStatusEnum.DRAFT;
    this.rewardType = RequestTypeEnum.NEW;

    this.isRequestViewMode = [RewardPageModes.REQUEST_VIEW, RewardPageModes.VIEW].includes(this.data.mode);
    this.isRequestCreateMode = this.data.mode === RewardPageModes.REQUEST_CREATE;
    this.isRequestEditMode = this.data.mode === RewardPageModes.REQUEST_EDIT;

    this.localStore
      .pipe(select(selectRewardRequestListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  initState() {
    if (this.data.rewardNo && this.canView) {
      this.store.dispatch(new RewardRequestByNoRequestAction({ rewardNo: this.data.rewardNo }));
    }

    this.rewardView$ = this.localStore.pipe(
      select(selectReward),
      filter(data => data !== null)
    );

    this.rewardView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response)
      )
      .subscribe(req => {
        if (req) {
          this.rewardRequestResponse = req;
          this.version = this.rewardRequestResponse.version;
          this.status = req.status as RewardRequestStatusEnum;
          this.setFromValue(req);
        }
      });
  }

  setFromValue(value: RewardRequestResponse): void {
    this.conditionPurchase = {
      condition: value.details.condition,
      offer: value.details.offer
    } as ConditionPurchase;
  }

  showCancelButton(status: string): boolean {
    if (status && (status.toLocaleLowerCase() === 'awaiting_schedule' || status.toLocaleLowerCase() === 'active')) {
      return true;
    }

    return false;
  }

  goToCancel() {
    const item = this.rewardRequestResponse;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Reward No. <strong>&quot;${item.rewardNo}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RewardCancelRequest({
              id: item.rewardNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onExit() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  get canView(): boolean {
    return [RewardPageModes.VIEW].includes(this.data.mode);
  }

  get conditionEnum() {
    return RewardCondition;
  }

  get offerEnum() {
    return RewardOffer;
  }

  get pageMode() {
    return RewardPageModes;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new RewardRequestByNoResetAction());
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
  }
}
