import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { WarehouseListResponse } from '../../models/warehouse.model';
import { WarehouseService } from '../../services/warehouse.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { SelectWarehouseListResponseAction, WarehouseActionTypes, WarehouseListResponseAction } from '../actions/warehouse.actions';
export class WarehouseEffects {
    constructor(actions$, logger, warehouseService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.warehouseService = warehouseService;
        this.warehouseList$ = this.actions$.pipe(ofType(WarehouseActionTypes.WAREHOUSE_LIST_REQUEST), tap(() => {
            this.logger.debug(`@Effect ${WarehouseActionTypes.WAREHOUSE_LIST_REQUEST}`);
        }), switchMap(action => {
            return this.warehouseService.listWarehouse().pipe(map(response => {
                const warehouseListResponse = new WarehouseListResponse();
                const warehouseListArray = [];
                Object.keys(response.data).forEach(deliveryLocationType => {
                    response.data[deliveryLocationType].forEach(deliveryLocation => {
                        const warehouseObject = {
                            id: null,
                            code: null,
                            name: null,
                            type: null,
                            wmsCode: null,
                            warehouseNameDisplay: null
                        };
                        warehouseObject.id = deliveryLocation.id;
                        warehouseObject.code = deliveryLocation.code;
                        warehouseObject.name = deliveryLocation.nameTh;
                        warehouseObject.wmsCode = deliveryLocation.wmsCode;
                        warehouseObject.type = deliveryLocation.__typename;
                        warehouseObject.warehouseNameDisplay = `${deliveryLocation.wmsCode}${action.delimiter}${deliveryLocation.nameTh}`;
                        warehouseListArray.push(warehouseObject);
                    });
                });
                warehouseListResponse.content = warehouseListArray;
                return new WarehouseListResponseAction(warehouseListResponse);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.selectWarehouseList$ = this.actions$.pipe(ofType(WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST), tap(() => {
            this.logger.debug(`@Effect ${WarehouseActionTypes.SELECT_WAREHOUSES_LIST_REQUEST}`);
        }), switchMap(action => {
            return this.warehouseService.selectWarehouseList(action.payload).pipe(map((warehouses) => {
                return new SelectWarehouseListResponseAction(warehouses);
            }), catchError(err => of(new LayoutActionLoadError(err))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], WarehouseEffects.prototype, "warehouseList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], WarehouseEffects.prototype, "selectWarehouseList$", void 0);
