export interface AuditLog {
  activity: string;
  description?: string;
  editBy: string;
  editByName: string;
  editDate: string;
  activities?: Activity[];
}

export interface Activity {
  activity: string;
  descriptions: string[];
}
export enum HistoryType {
  REQUEST = 'REQUEST',
  PRODUCT_ASSORTMENT = 'PRODUCT_ASSORTMENT',
  COMPENSATE = 'COMPENSATE',
  PRICE_SETTING_CLASS = 'PRICE_SETTING_CLASS',
  STACK_PRICING = 'STACK_PRICING',
  MERCHANT_REQUEST = 'MERCHANT_REQUEST',
  STORE = 'STORE',
  CLAIM_REQUEST = 'CLAIM_REQUEST',
  STORE_REQUEST = 'STORE_REQUEST',
  PROMOTION = 'PROMOTION',
  MERCHANT = 'MERCHANT',
  VOUCHER = 'VOUCHER',
  REWARD = 'REWARD',
  SUPPLIER_GROUP = 'SUPPLIER_GROUP',
  SUPPLIER_GROUP_REQUEST = 'SUPPLIER_GROUP_REQUEST',
  SHELF_REQUEST = 'SHELF_REQUEST',
  SHELF = 'SHELF',
  ORDER_REQUEST = 'ORDER_REQUEST',
  STORE_ASSORTMENT = 'STORE_ASSORTMENT',
  PROFIT_SHARING = 'PROFIT_SHARING',
  Z8_PARAMETER = 'Z8_PARAMETER',
  Z8_RESULT = 'Z8_RESULT'
}
