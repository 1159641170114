import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../services';

@Component({
  selector: 'app-reference-document-link',
  templateUrl: './reference-document-link.component.html',
  styleUrls: ['./reference-document-link.component.scss']
})
export class ReferenceDocumentLinkComponent implements OnInit {
  @Input() module: string;
  @Input() text: string;
  @Input() referenceNo?: string;
  @Input() referenceID?: string;
  @Input() permissions?: string[];

  constructor(protected router: Router, protected authGuardService: AuthGuardService) {}

  ngOnInit() {}

  linkToComponent(event) {
    if (!this.isAllowToLink()) {
      return;
    }

    event.stopPropagation();

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/dashboard/my-task'], {
        queryParams: { module: this.module, referenceNo: this.referenceNo, referenceID: this.referenceID }
      })
    );

    window.open(url, '_blank');
  }

  isAllowToLink() {
    if (this.permissions && !this.authGuardService.checkPermission(this.permissions)) {
      return false;
    }

    return !this.text || this.text === '(None)' || this.text === '-' ? false : true;
  }
}
