<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left" (click)="logForm()">
      <!--      {{ editingSupplierPriceIndex !== null ? 'Edit Supplier Price' : 'Add Supplier Price' }}-->
      {{
        [assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) || useCJProduct
          ? 'View Supplier Price'
          : editingSupplierPriceIndex !== null
          ? 'Edit Supplier Price'
          : 'Add Supplier Price'
      }}
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalAddSupplierPrice()">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body" [formGroup]="addSupplierPriceForm">
    <div class="container-fluid">
      <div class="d-inline-flex mr-auto pl-0 col-md-12">
        <h6 class="pr-2">
          {{ productDetailsForm?.controls.articleNo.value }}: {{ productDetailsForm?.controls.productName.value }}
        </h6>
      </div>
      <div class="form-group">
        <label for="supplier">Supplier Name<span class="text-danger">*</span></label>
        <ng-select
          id="supplier"
          [items]="supplierList | async"
          bindLabel="supplier"
          dropdownPosition="auto"
          labelForId="supplier"
          placeholder="Please select..."
          notFoundText="No Data"
          [loading]="supplierSearchLoading"
          [typeahead]="supplierSearchInput$"
          [virtualScroll]="true"
          (change)="onChangeSupplierName($event)"
          formControlName="supplier"
          (blur)="onBlurSupplierName()"
          [ngClass]="{
            'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors,
            'bg-h-change':
              isRequestViewMode &&
              productDetailsForm.controls.deliveryMethod.value !== 'SUPPLIER' &&
              supplierPriceForm.changes &&
              supplierPriceForm.changes['supplierName']
          }"
        >
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div>
              <strong>Supplier Name:</strong>
              <span [ngOptionHighlight]="search" class="ml-1">
                {{ item.supplierName }}
              </span>
            </div>
            <small>
              <strong>Vendor No:</strong>
              <span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span>
              {{ ' | ' }}
              <strong>Branch No:</strong>
              <span class="ml-1">{{ item.branchNo }}</span>
            </small>
          </ng-template>
          <ng-template ng-label-tmp let-item="item">
            {{
              productDetailsForm.controls.deliveryMethod.value !== 'SUPPLIER'
                ? item.supplierCode + ' ' + item.supplierName + ' (' + item.branchNo + ')'
                : 'Supplier Group'
            }}
          </ng-template>
        </ng-select>
        <div
          *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors"
          class="invalid-feedback"
        >
          <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors?.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div
            *ngIf="
              isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors?.duplicateWarehouseSamePeriod
            "
          >
            Duplicate Supplier Name in warehouse within same period.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="supplierPrice">Order Barcode<span class="text-danger">*</span></label>
            <input
              id="orderBarcode"
              type="text"
              class="form-control"
              placeholder="Barcode Number"
              formControlName="orderBarcode"
              maxlength="25"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.orderBarcode.errors,
                'bg-h-change':
                  isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['orderBarcode']
              }"
            />
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.orderBarcode.errors"
              class="invalid-display"
            >
              <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.orderBarcode.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="supplierUnit">Order Unit<span class="text-danger">*</span></label>
            <ng-select
              *ngIf="
                !addSupplierPriceForm.controls.supplierUnit.value &&
                  addSupplierPriceForm.controls.sourceSupplierUnit.value;
                else defaultSupplierUnit
              "
              id="sourceSupplierUnit"
              [searchable]="false"
              [clearable]="false"
              dropdownPosition="auto"
              labelForId="sourceSupplierUnit"
              placeholder="Please select..."
              formControlName="sourceSupplierUnit"
            >
              <ng-option value="addSupplierPriceForm.controls.sourceSupplierUnit.value ">
                {{ addSupplierPriceForm.controls.sourceSupplierUnit.value }}
              </ng-option>
            </ng-select>
            <div
              class="sub-header-text text-warning d-block"
              *ngIf="
                !useCJProduct &&
                !addSupplierPriceForm.controls.supplierUnit.value &&
                addSupplierPriceForm.controls.sourceSupplierUnit.value
              "
            >
              Invalid unit mapping.
            </div>
            <ng-template #defaultSupplierUnit>
              <ng-select
                id="supplierUnit"
                [items]="listOfValue[assortmentAllType.UNIT]"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameEn"
                bindValue="code"
                dropdownPosition="auto"
                labelForId="supplierUnit"
                placeholder="Please select..."
                formControlName="supplierUnit"
                [ngClass]="{
                  'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierUnit.errors,
                  'bg-h-change':
                    isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['supplierUnit']
                }"
              >
              </ng-select>
            </ng-template>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierUnit.errors"
              class="invalid-display"
            >
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-3 pl-0">
          <div class="form-group">
            <label for="supplierUnitFactor">Unit Factor<span class="text-danger">*</span></label>
            <app-numeric-textbox
              id="supplierUnitFactor"
              formControlName="supplierUnitFactor"
              [negativeSign]="false"
              placeholder="No. of piece"
              [format]="'0,0'"
              [decimals]="0"
              maxlength="10"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.supplierUnitFactor.errors,
                'bg-h-change':
                  isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['supplierUnitFactor']
              }"
            ></app-numeric-textbox>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierUnitFactor?.errors"
              class="invalid-display"
            >
              <span *ngIf="addSupplierPriceForm.controls.supplierUnitFactor?.errors.required; else zero">
                {{ 'ERRORS.REQUIRED' | translate }}
              </span>
              <ng-template #zero>
                <span
                  *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierUnitFactor?.errors.isZero"
                >
                  {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="supplierPrice">Supplier Price (ex VAT)<span class="text-danger">*</span></label>
            <app-numeric-textbox
              id="supplierPrice"
              formControlName="supplierPrice"
              placeholder="0.00"
              [format]="'0,0.00'"
              [maxValue]="9999999999.99"
              [negativeSign]="false"
              maxlength="15"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.supplierPrice.errors,
                'bg-h-change':
                  isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['supplierPrice']
              }"
            ></app-numeric-textbox>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.supplierPrice.errors"
              class="invalid-display"
            >
              <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierPrice.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div
                *ngIf="
                  isSubmitAddSupplierPrice &&
                  addSupplierPriceForm.controls.supplierPrice.errors?.differentPriceSamePeriod
                "
              >
                Difference Supplier Price is not allow within same period.
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="avgSupplierPrice">Avg. Supplier Price (ex VAT)/Base Unit</label>
            <app-numeric-textbox
              id="avgSupplierPrice"
              formControlName="avgSupplierPrice"
              [negativeSign]="false"
              placeholder="0.00"
              [format]="'0,0.00'"
              [maxValue]="9999999999.99"
              maxlength="10"
            ></app-numeric-textbox>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="supplierPriceEffectiveDate">Effective Date<span class="text-danger">*</span></label>
            <input
              id="supplierPriceEffectiveDate"
              type="text"
              placeholder="{{ dateFormat }}"
              class="form-control calendar"
              placement="bottom"
              autocomplete="off"
              bsDatepicker
              [bsConfig]="bsConfig"
              formControlName="effectiveDate"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.effectiveDate.errors,
                'bg-h-change':
                  isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['effectiveDate']
              }"
              (bsValueChange)="clearDateError($event)"
              readonly
            />
            <label for="supplierPriceEffectiveDate" class="icon-calendar btn-calendar"></label>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.effectiveDate.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>

              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.duplicated">
                Not allow Overlapped period.
              </div>
              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.invalidDate">
                Invalid Date.
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="supplierPriceExpireDate">Expire Date<span class="text-danger">*</span></label>
            <input
              id="supplierPriceExpireDate"
              type="text"
              placeholder="{{ dateFormat }}"
              class="form-control calendar"
              placement="bottom"
              bsDatepicker
              [bsConfig]="bsConfig"
              [minDate]="expireDateMinDate"
              formControlName="expireDate"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.expireDate.errors,
                'bg-h-change':
                  isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['expireDate']
              }"
              (bsValueChange)="clearDateError($event)"
              readonly
            />
            <label for="supplierPriceExpireDate" class="icon-calendar btn-calendar"></label>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.expireDate.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>

              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.duplicated">
                Not allow Overlapped period.
              </div>
              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.invalidDate">
                Invalid Date.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="productDetailsForm.get('deliveryMethod').value !== 'SUPPLIER'">
        <div class="col-12">
          <app-custom-listbox
            [source]="source"
            [destination]="target"
            [addSupplierPriceForm]="addSupplierPriceForm"
            [isSubmitAddSupplierPrice]="isSubmitAddSupplierPrice"
            height="300px"
            sourceName="Available Stations"
            targetName="Selected Stations"
            [sort]="true"
            [filter]="true"
            [key]="key"
            [display]="display"
            [mode]="mode"
            [disabled]="
              [assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) || useCJProduct
            "
            [useCJProduct]="useCJProduct"
            [isChange]="isRequestViewMode && supplierPriceForm.changes && supplierPriceForm.changes['warehouses']"
          >
          </app-custom-listbox>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) && !useCJProduct"
      type="button"
      class="btn btn-standard mr-2"
      (click)="hideModalAddSupplierPrice()"
      id="supplierPrice-modal-cancelBtn"
    >
      Cancel
    </button>
    <button
      *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) && !useCJProduct"
      type="submit"
      class="btn btn-primary mr-3"
      id="supplierPrice-modal-addBtn"
      (click)="saveSupplierPrice()"
      appPreventDoubleClick
    >
      {{ editingSupplierPriceIndex !== null ? 'Update' : 'Add' }}
    </button>
  </div>
</div>
