<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-marketing"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="showCancelButton((rewardView$ | async)?.status) && hasEditRewardPermission"
    (click)="goToCancel()"
  >
    <em class="icon-forbidden"></em>
    Cancel Reward
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form" [formGroup]="rewardForm">
  <div class="info-header-container">
    <div class="info-header-item mr-4" *ngIf="(rewardView$ | async)?.rewardNo">
      <div class="info-header-label">
        <span>Reward No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (rewardView$ | async)?.rewardNo }}</span>
      </div>
    </div>

    <div class="info-header-item">
      <div class="info-header-label">
        <span>Reward Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(status)" class="request-status">
          {{ 'REWARD.STATUS.' + status | translate | titlecase }}</span
        >
      </div>
    </div>
  </div>
  <div>
    <div class="row section-header">
      <span>Reward Details</span>
    </div>
    <app-reward-details #rewardDetails [parentForm]="rewardForm" [mode]="data.mode"></app-reward-details>
    <ng-container *ngIf="this.conditionPurchase?.condition && this.conditionPurchase?.offer">
      <hr class="section-divider" />
      <div class="row section-header">
        <span *ngIf="this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT">Purchase Tier</span>
        <span *ngIf="this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY">Purchase Item</span>
      </div>
      <!--      Purchase Amount Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-purchase-amount-coupon
          #purchaseAmountCoupon
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [mode]="data.mode"
          [conditionPurchase]="conditionPurchase"
        ></app-purchase-amount-coupon>
      </ng-container>
      <!--      Purchase Amount Goods   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT &&
          this.conditionPurchase?.offer === this.offerEnum.GOODS
        "
      >
        <app-purchase-amount-goods
          #purchaseAmountGoods
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-purchase-amount-goods>
      </ng-container>
      <!--      Purchase Quantity Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-purchase-quantity-coupon
          #purchaseQuantityCoupon
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-purchase-quantity-coupon>
      </ng-container>
      <!--      Purchase Quantity Goods   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY &&
          this.conditionPurchase?.offer === this.offerEnum.GOODS
        "
      >
        <app-purchase-quantity-goods
          #purchaseQuantityGoods
          [parentForm]="rewardForm"
          [mode]="data.mode"
          [conditionPurchase]="conditionPurchase"
          [isEditFirst]="isEditFirst"
        ></app-purchase-quantity-goods>
      </ng-container>

      <!--      Supplier Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.SUPPLIER_COUPON &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-supplier-coupon
          #supplierCoupon
          [parentForm]="rewardForm"
          [maximumRow]="1"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-supplier-coupon>
      </ng-container>

      <hr class="section-divider" />
      <div class="row section-header">
        <span>Select Store</span>
      </div>
      <app-select-store
        #selectStore
        [parentForm]="rewardForm"
        [mode]="data.mode"
        [conditionPurchase]="conditionPurchase"
      ></app-select-store>
    </ng-container>
  </div>
</div>
