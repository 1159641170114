<div [formGroup]="parentForm">
  <div formGroupName="supplierCouponDetails">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="applyTo">Apply to<span class="text-danger">*</span></label>
        <ng-select
          id="applyTo"
          placeholder="Please select..."
          [searchable]="false"
          [clearable]="false"
          [items]="applyToSelect"
          formControlName="applyTo"
          bindLabel="label"
          bindValue="value"
          (change)="onChangedApplyTo($event.value)"
          [ngClass]="{
            'is-invalid': submitted && supplierCouponDetails.controls.applyTo.errors
          }"
        >
        </ng-select>
        <div *ngIf="submitted && supplierCouponDetails.controls.applyTo.errors" class="invalid-feedback">
          <div *ngIf="supplierCouponDetails.controls.applyTo.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="couponAmount">Coupon Value (THB)<span class="text-danger">*</span></label>
        <app-numeric-textbox
          id="couponAmount"
          [minValue]="0"
          [decimals]="2"
          [maxValue]="999999.99"
          [min]="0"
          maxlength="9"
          formControlName="couponAmount"
          [format]="'0,0.00'"
          [negativeSign]="false"
          placeholder="Coupon Value (THB)"
          [ngClass]="{ 'is-invalid': submitted && supplierCouponDetails.controls.couponAmount.errors }"
        ></app-numeric-textbox>
        <div *ngIf="submitted && supplierCouponDetails.controls.couponAmount.errors" class="invalid-feedback">
          <div *ngIf="supplierCouponDetails.controls.couponAmount.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="supplierCouponDetails.controls.couponAmount.errors.isZero">
            {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="minPerBillAmount">Minimum per Bill<span class="text-danger">*</span></label>
        <app-numeric-textbox
          id="minPerBillAmount"
          [minValue]="0"
          [decimals]="2"
          [maxValue]="999999.99"
          [min]="0"
          maxlength="9"
          formControlName="minPerBillAmount"
          [format]="'0,0.00'"
          [negativeSign]="false"
          placeholder="Minimum per Bill"
          [ngClass]="{ 'is-invalid': submitted && supplierCouponDetails.controls.minPerBillAmount.errors }"
        ></app-numeric-textbox>
        <div *ngIf="submitted && supplierCouponDetails.controls.minPerBillAmount.errors" class="invalid-feedback">
          <div *ngIf="supplierCouponDetails.controls.minPerBillAmount.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="this.supplierCouponItems.controls.length > 0">
    <table id="data-table" class="table table-striped table-bordered table-hover w-100" [formGroup]="parentForm">
      <thead>
        <tr>
          <th class="text-center w-5">No.</th>
          <th class="text-center w-30">Product Name</th>
          <th class="text-center w-10">Article</th>
          <th class="text-center w-10">Barcode</th>
          <th class="text-center w-5">Unit</th>
          <th class="text-center w-10">Purchase Quantity<span class="text-danger">*</span></th>
          <th class="text-center w-5" *ngIf="mode !== pageMode.VIEW">Action</th>
        </tr>
      </thead>
      <tbody formArrayName="supplierCouponItems">
        <tr *ngFor="let item of supplierCouponItems.controls; index as i" [formGroupName]="i">
          <td class="text-center">{{ i + 1 }}</td>
          <td class="text-left align-top">
            {{ item.get('productName').value }}
            <ng-container *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isRestrictItem || item.get('productName').errors?.isRestrictItem"
              >
                {{ 'ERRORS.RESTRICT_ITEM' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isStatusDelisted || item.get('productName').errors?.isStatusDelisted"
              >
                {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isFixAssetItem || item.get('productName').errors?.isFixAssetItem"
              >
                {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
              </small>
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors?.isStoreUseItem || item.get('productName').errors?.isStoreUseItem"
              >
                {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
              </small>
            </ng-container>
          </td>
          <td class="text-left align-top">
            {{ item.get('articleNo').value }}
          </td>
          <td class="text-left align-top">
            {{ item.get('barcode').value }}
            <ng-container *ngIf="mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW">
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors && item.get('barcode').errors?.invalidBarcode"
              >
                {{ 'ERRORS.INVALID_BARCODE' | translate }}</small
              >
              <small
                class="d-block invalid-feedback text-danger"
                *ngIf="item.errors && item.get('barcode').errors?.isInactive"
              >
                {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
              </small>
            </ng-container>
          </td>
          <td class="text-center">
            {{ item.get('unit').value }}
          </td>
          <td>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0'"
              [decimals]="0"
              [min]="1"
              placeholder="0"
              formControlName="purchaseQuantity"
              maxlength="3"
              id="purchaseQuantity"
              [negativeSign]="false"
              class="td-numeric-textbox-center"
              [maxValue]="999"
              ngDefaultControl
              [ngClass]="{
                'is-invalid': submitted && (item.errors || item.get('purchaseQuantity').errors)
              }"
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && item.get('purchaseQuantity').errors" class="invalid-feedback">
              <div *ngIf="item.get('purchaseQuantity').errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="item.get('purchaseQuantity').errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
              </div>
            </div>
          </td>
          <td class="text-center align-middle" *ngIf="mode !== pageMode.VIEW">
            <a *ngIf="mode !== pageMode.REQUEST_VIEW" class="btn-text-link" (click)="deleteItem(i)">
              <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    class="row"
    *ngIf="
      applyToValue == 'SELECT_BY_PRODUCT' &&
      this.supplierCouponItems.length < this.maximumRow &&
      mode !== pageMode.VIEW
    "
  >
    <div class="col-md-12">
      <a
        *ngIf="mode !== pageMode.REQUEST_VIEW"
        class="btn-text-link d-inline-block ml-1 float-left"
        (click)="addProduct()"
        >+Select Item
      </a>
    </div>
  </div>
  <app-search-product-modal
    #searchProductModal
    [allowRestrictItem]="allowRestrict"
    [productType]="productType"
    (addItem)="onAddItem($event)"
  >
  </app-search-product-modal>
</div>
