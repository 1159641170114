import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RewardRequestContent, RewardRequestResponse, RewardRequestSearchCriteria } from '../../models/reward.model';
import { RewardRequestAction, RewardRequestActionTypes } from '../actions/reward-request.actions';

export interface RewardRequestState extends EntityState<RewardRequestContent> {
  isLoading: boolean;
  selected: RewardRequestResponse;
  criteriaObject: RewardRequestSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<RewardRequestContent> = createEntityAdapter<RewardRequestContent>();

export const initialRewardRequestListState: RewardRequestState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  auditLogs: null
});

export function RewardRequestReducers(
  state = initialRewardRequestListState,
  action: RewardRequestAction
): RewardRequestState {
  switch (action.type) {
    case RewardRequestActionTypes.REWARD_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case RewardRequestActionTypes.REWARD_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload.RewardRequestView }
      };
    case RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case RewardRequestActionTypes.REWARD_REQUEST_GET_BY_ID_RESET_PURCHASE_TIER:
      return {
        ...state,
        selected: {
          ...state.selected,
          purchaseAmtCouponItems: null,
          purchaseAmtGoodsItems: null,
          purchaseQtyDetails: null,
          purchaseQtyItems: null,
          supplierCouponDetails: null,
          supplierCouponItems: null
        }
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
