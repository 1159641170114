import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { LegalDocumentComponent } from '../../../shared/components/td-store/legal-document/legal-document.component';
import { MerchantProfileComponent } from '../../../shared/components/td-store/merchant-profile/merchant-profile.component';
import { OrderPolicyComponent } from '../../../shared/components/td-store/order-policy/order-policy.component';
import { OwnerProfileComponent } from '../../../shared/components/td-store/owner-profile/owner-profile.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { MerchantSelectValueEnum } from '../../../shared/enum/merchant-select-value.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  ErrorResponse,
  LegalDocument,
  MerchantInfo,
  MerchantRequestListSearchCriteria,
  MerchantRequestTemplate,
  MerchantRequestViewResponse,
  StoreProfile,
  TaskModuleUrl,
  Wallet
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { MerchantRequestService } from '../../../shared/services/merchant-request.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  MerchantApproveRequestAction,
  MerchantCreateResetAction,
  MerchantCreateSaveRequestAction,
  MerchantCreateSubmitRequestAction,
  MerchantRequestByIdRequestAction,
  MerchantRequestListRequestAction
} from '../../../shared/store/actions/merchant.actions';
import { MerchantCreateResponseState } from '../../../shared/store/reducers/merchant-create.reducers';
import {
  selectMerchant,
  selectMerchantApproveRejectStatus
} from '../../../shared/store/selectors/merchant-create.selector';
import { selectMerchantRequestListCriteria } from '../../../shared/store/selectors/merchant-request.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../shared/utils/date-util';
import { getFileId, getMultipleFileId } from '../../../shared/utils/get-fileId-util';
import { getSelectByPage } from '../../../shared/utils/get-select-by-page-util';
import { merchantColorStatus } from '../../../shared/utils/merchant-color-status-util';
import { removeObjectArray } from '../../../shared/utils/remove-object-array.util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-merchant-request',
  templateUrl: './merchant-request.component.html',
  styleUrls: ['./merchant-request.component.scss']
})
export class MerchantRequestComponent implements OnInit, OnDestroy {
  @ViewChild('storeProfile', { static: false }) storeProfile: StoreProfileComponent;
  @ViewChild('merchantProfile', { static: false }) merchantProfile: MerchantProfileComponent;
  @ViewChild('ownerProfile', { static: false }) ownerProfile: OwnerProfileComponent;
  @ViewChild('legalDocument', { static: false }) legalDocument: LegalDocumentComponent;
  @ViewChild('orderPolicy', { static: false }) orderPolicy: OrderPolicyComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    merchantRequestId?: string;
    originPage?: string;
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly logger: NGXLogger,
    protected spinner: NgxSpinnerService,
    private readonly merchantRequestService: MerchantRequestService
  ) {
    this.uploadUrl = `${env.serverUrl}${env.services.productAssortment.uploadUrl}`;
    this.uploadHeaders = env.services.productAssortment.headers;
    this.baseStorageUrl = `${env.storageUrl}${env.services.productAssortment.bucketName}`;
  }

  get merchantSelectValueEnum() {
    return MerchantSelectValueEnum;
  }

  get merchantRequestPageMode() {
    return RequestPageModesEnum;
  }

  private localStore: Observable<any>;
  private listSearchCriteria: MerchantRequestListSearchCriteria;
  private isApprove: boolean;
  private requestNo: string;
  public merchantRequestForm: FormGroup;
  public saved: boolean;
  public submitted: boolean;
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  public ownerIdCardReadOnly: boolean;
  public uploadUrl: string;
  public uploadHeaders: any;
  public baseStorageUrl: string;
  public submittedLabel: string;
  public page: TDStorePage;
  public type: RequestTypeEnum;
  public step: RequestStepEnum;
  public status: RequestStatusEnum;
  public merchantSectionEnum = RequestSectionEnum;

  ngOnDestroy(): void {
    this.store.dispatch(new MerchantCreateResetAction());
  }

  ngOnInit() {
    this.initState();
    this.initControl();
    this.checkAuthorization();
  }

  initState() {
    this.page = TDStorePage.MERCHANT_REQUEST;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;
    this.type = RequestTypeEnum.NEW;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    // Current searchCriteria
    this.localStore
      .pipe(select(selectMerchantRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectMerchant),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: MerchantCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          this.alertErrorModal(value.result.errorResponse);
        }

        if (RequestPageModesEnum.REQUEST_EDIT === this.data.mode) {
          this.onTriggerEdit();
        }
      });

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new MerchantRequestByIdRequestAction({ merchantRequestId: this.data.merchantRequestId }));
    }

    this.merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));
    this.merchantRequestView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          this.requestNo = value.requestNo;
          this.type = value.type || RequestTypeEnum.NEW;
          this.step = value.step || RequestStepEnum.PROFILE;
          this.status = value.status || RequestStatusEnum.DRAFT;
        })
      )
      .subscribe();
  }

  initControl() {
    this.merchantRequestForm = this.fb.group({});
  }

  onChangeOwnerIdCard(value: boolean) {
    this.ownerIdCardReadOnly = value;
  }

  getColorStatus(status: string): string {
    return merchantColorStatus(status);
  }

  onCancel() {
    if (this.merchantRequestForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSave() {
    this.saved = true;
    const merchantProfile = this.merchantRequestForm.getRawValue().merchantProfile;

    // require merchant name before saved
    if (this.data.mode !== RequestPageModesEnum.REQUEST_VIEW && !merchantProfile.merchantName) {
      return;
    }

    const reqData = this.prepareMerchantRequestData();
    this.store.dispatch(new MerchantCreateSaveRequestAction({ merchantCreatRequest: reqData }));
  }

  onSubmit() {
    this.submitted = true;

    if (this.merchantRequestForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareMerchantRequestData();
          this.store.dispatch(new MerchantCreateSubmitRequestAction({ merchantCreatRequest: reqData }));
        }
      });
  }

  prepareMerchantRequestData(): MerchantRequestTemplate {
    const formData = this.merchantRequestForm.getRawValue();
    let merchantNo = null;
    let mobilePhone = null;
    let countryCode = null;
    let storeProfileData = [];
    const merchantRequestTemplate = new MerchantRequestTemplate();

    this.merchantRequestView$.subscribe(value => {
      if (value) {
        merchantRequestTemplate.id = value.id;
        merchantRequestTemplate.version = value.version;
        merchantRequestTemplate.step = value.step;
        merchantRequestTemplate.type = value.type;
        if (value.merchantInfo) {
          mobilePhone = value.merchantInfo.ownerProfile.mobilePhone;
          countryCode = value.merchantInfo.ownerProfile.countryCode;
          storeProfileData = value.merchantInfo.storeProfile;
          merchantNo = value.merchantInfo.merchantNo;
        }
      } else {
        merchantRequestTemplate.step = this.step;
        merchantRequestTemplate.type = this.type;
      }
    });

    merchantRequestTemplate.merchantInfo = {
      merchantNo,
      merchantProfile: formData.merchantProfile,
      ownerProfile: {
        ...formData.ownerProfile,
        countryCode:
          formData.ownerProfile.mobilePhone && formData.ownerProfile.mobilePhone.internationalNumber
            ? formData.ownerProfile.mobilePhone.internationalNumber.split(' ')[0]
            : countryCode,
        mobilePhone:
          formData.ownerProfile.mobilePhone && formData.ownerProfile.mobilePhone.nationalNumber
            ? formData.ownerProfile.mobilePhone.nationalNumber.replace(/ /g, '')
            : mobilePhone,
        birthDate:
          formData.ownerProfile.birthDate &&
          moment(formData.ownerProfile.birthDate, env.dateFormat).format(env.dateFormat),
        ownerPicture: getFileId(formData.ownerProfile.ownerPicture)
      },
      ...(formData.storeProfile &&
        this.type === RequestTypeEnum.NEW && {
          storeProfile: this.generateStoreProfile(formData.storeProfile, storeProfileData)
        })
    } as MerchantInfo;

    merchantRequestTemplate.legalDocument = {
      idCardFront: getFileId(formData.legalDocument.idCardFront),
      idCardBack: getFileId(formData.legalDocument.idCardBack),
      houseRegistration: getFileId(formData.legalDocument.houseRegistration),
      letterOfIntent: getFileId(formData.legalDocument.letterOfIntent),
      contractSignOff: getFileId(formData.legalDocument.contractSignOff),
      creditBureauSignOff: getFileId(formData.legalDocument.creditBureauSignOff),
      criminalCheck: getFileId(formData.legalDocument.criminalCheck)
    } as LegalDocument;

    merchantRequestTemplate.wallet = {
      bankName: formData.legalDocument.bankName,
      accountNo: formData.legalDocument.accountNo,
      accountName: formData.legalDocument.accountName,
      bookBank: getFileId(formData.legalDocument.bookBank),
      dipChip: getFileId(formData.legalDocument.dipChip),
      jcNumber: formData.legalDocument.jcNumber
    } as Wallet;

    if (formData.orderSchedule && this.type === RequestTypeEnum.NEW) {
      formData.orderSchedule.orderScheduleList[0].schedules = removeObjectArray(
        formData.orderSchedule.orderScheduleList[0].schedules
      );
      merchantRequestTemplate.orderSchedule = formData.orderSchedule;
    }

    return merchantRequestTemplate;
  }

  generateStoreProfile(formData, storeProfileData: StoreProfile[]) {
    const storeProfiles = new Array<StoreProfile>();
    const profile = this.merchantRequestForm.get('storeProfile') as FormArray;

    formData.forEach((store, i) => {
      const storeProfile = {
        ...store,
        openDate: store.openDate ? formatDateStartOfDay(store.openDate, env.dateISO8601) : null,
        countryCode:
          store.phone && store.phone.internationalNumber
            ? store.phone.internationalNumber.split(' ')[0]
            : storeProfileData[i] && storeProfileData[i].countryCode,
        phone:
          store.phone && store.phone.nationalNumber
            ? store.phone.nationalNumber.replace(/ /g, '')
            : storeProfileData[i] && storeProfileData[i].phone,
        rentalFee:
          !profile.controls[i]['controls']['rentalFee'].disabled && store.rentalFee
            ? { amount: store.rentalFee, currency: 'THB' }
            : null,
        storeFrontPicture: store.storePicFront && getMultipleFileId(store.storePicFront),
        storeFrontVideo: getFileId(store.storeVDOFront),
        titleDeed: getFileId(store.titleDeed),
        houseRegistrationPicture: getFileId(store.houseRegistrationPicture),
        idCardPicture: getFileId(store.idCardPicture),
        consentLetterPicture: getFileId(store.consentLetterPicture),
        attachmentPicture: store.attachmentPicture && getMultipleFileId(store.attachmentPicture),
        deliveryTimes: removeObjectArray(store.deliveryTimes),
        nearByPois: removeObjectArray(store.nearByPois),
        nearByCompetitors: removeObjectArray(store.nearByCompetitors)
      } as StoreProfile;

      storeProfiles.push(storeProfile);
    });

    return storeProfiles;
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
        this.refreshMerchantList(this.status === RequestStatusEnum.DRAFT);
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.refreshMerchantList(this.status === RequestStatusEnum.DRAFT);
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  alertApproveRejectModalSuccess(resp) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
        this.refreshMerchantList(this.status === RequestStatusEnum.DRAFT);
      }
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  refreshMerchantList(isResetToFirstPage = false) {
    if (isResetToFirstPage) {
      this.listSearchCriteria.page = 0;
    }

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new MerchantRequestListRequestAction(this.listSearchCriteria));
    }
  }

  onTriggerEdit() {
    this.data.title = 'Edit Merchant Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.PROFILE)) {
      this.logger.debug('EDIT PROFILE');

      this.merchantProfile.toggleEditMerchantProfile();
      this.ownerProfile.toggleEditOwnerProfile();

      if (this.isShowSection(RequestSectionEnum.STORE_PROFILE)) {
        this.storeProfile.toggleEditStoreProfile();
      }

      if (this.isShowSection(RequestSectionEnum.LEGAL_DOCUMENT)) {
        this.legalDocument.toggleEditLegalDocument();
      }

      this.scrollTo(RequestSectionEnum.PROFILE);
    }

    if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.ORDER_POLICY)) {
      this.logger.debug('EDIT ORDER POLICY');

      if (this.isShowSection(RequestSectionEnum.ORDER_POLICY)) {
        this.orderPolicy.toggleEditOrderPolicy();
      }

      this.scrollTo(RequestSectionEnum.ORDER_POLICY);
    }
  }

  scrollTo(anchor: RequestSectionEnum) {
    const element = document.getElementById(anchor);
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
    }
  }

  isShowSection(sectionName: RequestSectionEnum): boolean {
    return this.tdStoreWorkflowUtil.isShowSection(this.type, this.page, this.step, sectionName);
  }

  hasEditPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, this.status);
  }

  hasSubmitPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasApprovePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasApprovePermission(this.type, this.page, this.step, this.status);
  }

  hasSavePermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSavePermission(this.type, this.page, this.step, this.status);
  }

  hasCancelPermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasCancelPermission(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasDeletePermission(): boolean {
    return (
      this.tdStoreWorkflowUtil.hasDeletePermission(this.type, this.page, this.step, this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission() ||
      this.hasSubmitPermission()
    );
  }

  canEditSection(): boolean {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.PROFILE)
    );
  }

  checkAuthorization() {
    this.merchantRequestView$.pipe(filter(Boolean)).subscribe(() => {
      if (this.step === RequestStepEnum.WALLET && this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
        if (this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.PROFILE)) {
          this.scrollTo(RequestSectionEnum.PROFILE);
        } else if (
          this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.ORDER_POLICY)
        ) {
          this.scrollTo(RequestSectionEnum.ORDER_POLICY);
        }
      }
    });
  }

  doApproveRejectRequest(isApprove: boolean) {
    this.isApprove = isApprove;

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectMerchantApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess({
                result: {
                  response: 'success',
                  errorResponse: null
                }
              });
            });

          this.store.dispatch(
            new MerchantApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  cancelMerchantRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.merchantRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              step: this.step,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }
        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deleteMerchantRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.merchantRequestService
            .deleteByRequestId({ merchantRequestId: this.data.merchantRequestId })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
          if (confirmModalRef.content.actions) {
            confirmModalRef.content.actions.unsubscribe();
          }
        }
      });
  }

  getSubmitMessage(isSave: boolean) {
    const ownerProfile = this.merchantRequestForm.get('ownerProfile') as FormGroup;

    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === RequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.step === RequestStepEnum.ORDER_POLICY) {
      return 'The merchant has been created.';
    } else {
      return 'The request has been updated.';
    }
  }
}
