import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { RewardService } from '../../services/reward.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { RewardActionTypes, RewardActiveListResponseAction, RewardActiveSummaryResponseAction, RewardListResponseAction, RewardRequestByNoResponseAction } from '../actions/reward.actions';
export class RewardEffects {
    constructor(actions$, logger, rewardService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.rewardService = rewardService;
        this.searchReward$ = this.actions$.pipe(ofType(RewardActionTypes.REWARD_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${RewardActionTypes.REWARD_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.rewardService.searchByCriteria(payload).pipe(map(requests => {
                return new RewardListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getRewardByNo$ = this.actions$.pipe(ofType(RewardActionTypes.REWARD_GET_BY_NO_REQUEST), tap(action => this.logger.debug('@Effect Reward  By No: ' + JSON.stringify(action.payload))), mergeMap(action => this.rewardService.getByNo(action.payload.rewardNo).pipe(map(rewardView => {
            return new RewardRequestByNoResponseAction({ rewardView });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.cancelReward$ = this.actions$.pipe(ofType(RewardActionTypes.REWARD_CANCEL_REQUESTED), tap(action => this.logger.debug('@Effect Reward Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.rewardService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This reward has been cancelled.'
        })), catchError(err => {
            return err.error && err.error.code === '00004'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
        this.searchRewardActive$ = this.actions$.pipe(ofType(RewardActionTypes.REWARD_ACTIVE_LIST_REQUESTED), map(action => {
            this.logger.debug(`@Effect ${RewardActionTypes.REWARD_ACTIVE_LIST_REQUESTED}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.rewardService.activeList(payload.criteria).pipe(map(requests => {
                return new RewardActiveListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.rewardActiveSummary$ = this.actions$.pipe(ofType(RewardActionTypes.REWARD_ACTIVE_SUMMARY_REQUESTED), map(() => {
            this.logger.debug(`@Effect ${RewardActionTypes.REWARD_ACTIVE_SUMMARY_REQUESTED}`);
        }), switchMap(() => {
            return this.rewardService.activeSummary().pipe(map(res => {
                return new RewardActiveSummaryResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RewardEffects.prototype, "searchReward$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RewardEffects.prototype, "getRewardByNo$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RewardEffects.prototype, "cancelReward$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RewardEffects.prototype, "searchRewardActive$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RewardEffects.prototype, "rewardActiveSummary$", void 0);
