import { ElementRef, EventEmitter, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as numeral from 'numeral';
const ɵ0 = (properties, changes) => {
    for (let _i = 0, _properties = properties; _i < _properties.length; _i++) {
        const property = _properties[_i];
        if (changes[property] !== undefined) {
            return true;
        }
    }
    return false;
};
export const Helper = {
    anyChanges: ɵ0,
    createNumericRegex(hasDecimal, hasSign) {
        let regexString = '^';
        if (hasSign) {
            regexString += '-?';
        }
        regexString += '(?:(?:\\d+';
        if (hasDecimal) {
            regexString += '(\\.\\d*)?';
        }
        regexString += ')|(?:\\.\\d*))?$';
        return new RegExp(regexString);
    }
};
export function createMinValidator(min) {
    return function (control) {
        if (_.isNumber(control.value) && control.value < min) {
            return {
                minError: {
                    minValue: min,
                    value: control.value
                }
            };
        }
        return null;
    };
}
export function createMaxValidator(max) {
    return function (control) {
        if (_.isNumber(control.value) && control.value > max) {
            return {
                maxError: {
                    maxValue: max,
                    value: control.value
                }
            };
        }
        return null;
    };
}
export class NumericTextboxComponent {
    constructor(renderer2) {
        this.negativeSign = true;
        this.renderer2 = renderer2;
        this.min = Number.MIN_SAFE_INTEGER;
        this.max = Number.MAX_SAFE_INTEGER;
        this.minValue = null;
        this.maxValue = null;
        this.cssClass = '';
        this.decimals = 2;
        this.disabled = false;
        this.format = '0,0.00';
        this.autoCorrect = false;
        this.rangeValidation = true;
        this.valueChange = new EventEmitter();
        this.focus = new EventEmitter();
        this.blur = new EventEmitter();
        this.enter = new EventEmitter();
        this.escape = new EventEmitter();
        this.minValidateFn = Validators.nullValidator;
        this.maxValidateFn = Validators.nullValidator;
        this.focused = false;
        this.previousValue = undefined;
        this.ngChange = _value => { };
        this.ngTouched = () => { };
        this.allowPaste = true;
        numeral.options.scalePercentBy100 = false;
    }
    onPaste(event) {
        if (!this.allowPaste) {
            event.preventDefault();
            return;
        }
        const pattern = `^0-9${this.negativeSign ? '\\-' : ''}`;
        const regexp = new RegExp(`[${pattern}.]`, 'g');
        const pastedInput = event.clipboardData.getData('text/plain').replace(regexp, ''); // get a digit-only string
        event.preventDefault();
        document.execCommand('insertText', false, pastedInput);
    }
    ngOnInit() { }
    focusInput() {
        this.numericInput.nativeElement.focus();
    }
    blurInput() {
        this.numericInput.nativeElement.blur();
    }
    validate(control) {
        return this.minValidateFn(control) || this.maxValidateFn(control);
    }
    writeValue(value) {
        const newValue = this.restrictModelValue(value);
        this.value = newValue;
        this.setInputValue();
    }
    registerOnChange(fn) {
        this.ngChange = fn;
    }
    registerOnTouched(fn) {
        this.ngTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    ngOnChanges(changes) {
        this.verifySettings();
        if (Helper.anyChanges(['autoCorrect', 'decimals'], changes)) {
            delete this.numericRegex;
        }
        if (Helper.anyChanges(['min', 'max', 'rangeValidation'], changes)) {
            if (_.isNumber(this.min) && this.rangeValidation) {
                this.minValidateFn = createMinValidator(this.min);
            }
            else {
                this.minValidateFn = Validators.nullValidator;
            }
            if (_.isNumber(this.max) && this.rangeValidation) {
                this.maxValidateFn = createMaxValidator(this.max);
            }
            else {
                this.maxValidateFn = Validators.nullValidator;
            }
            this.ngChange(this.value);
        }
        if (Helper.anyChanges(['format'], changes)) {
            this.setInputValue();
        }
    }
    handleInput() {
        const element = this.numericInput.nativeElement;
        const selectionStart = element.selectionStart;
        const selectionEnd = element.selectionEnd;
        const value = element.value;
        if (!this.isValidInput(value)) {
            element.value = this.inputValue;
            this.setSelection(selectionStart - 1, selectionEnd - 1);
        }
        else {
            const orginalInputValue = this.parseNumber(value);
            let limitInputValue = this.restrictDecimals(orginalInputValue);
            if (this.autoCorrect) {
                limitInputValue = this.limitValue(limitInputValue);
            }
            if (orginalInputValue !== limitInputValue) {
                this.setInputValue(limitInputValue);
                this.setSelection(selectionStart, selectionEnd);
            }
            else {
                this.inputValue = value;
            }
            this.updateValue(limitInputValue);
        }
    }
    handleFocus() {
        if (!this.focused) {
            this.focused = true;
            this.setInputValue();
            setTimeout(() => {
                return this.setSelection(0, this.inputValue.length);
            });
        }
        this.focus.emit();
    }
    handleBlur() {
        if (this.focused) {
            this.focused = false;
            this.ngTouched();
            this.setInputValue();
        }
        this.blur.emit(this.value);
    }
    handleKeyDown(event) {
        if (!this.disabled) {
            switch (event.key) {
                case 'Enter':
                    this.enter.emit();
                    break;
                case 'Esc': // IE/Edge specific value
                case 'Escape':
                    this.escape.emit();
                    break;
                default:
            }
        }
        const element = this.numericInput.nativeElement;
        const selectionStart = element.selectionStart;
        const selectionEnd = element.selectionEnd;
        const isNewValue = selectionEnd - selectionStart === 0;
        const currentValue = Number(`${this.numericInput.nativeElement.value}` + event.key);
        const isInvalidMinValue = this.minValue && isNewValue && currentValue < this.minValue;
        const isInvalidMaxValue = this.maxValue && isNewValue && currentValue > this.maxValue;
        const isInvalidNegativeSign = !this.negativeSign && event.key === '-';
        if (isInvalidMinValue || isInvalidMaxValue || isInvalidNegativeSign) {
            event.preventDefault();
        }
    }
    verifySettings() {
        if (_.isNumber(this.min) && _.isNumber(this.max) && this.min > this.max) {
            throw new Error('The max value should be bigger than the min value');
        }
        if (_.isNumber(this.decimals) && this.decimals < 0) {
            throw new Error('The decimals value should be bigger than 0');
        }
    }
    isValidInput(input) {
        let numericRegex = this.numericRegex;
        if (_.isNil(numericRegex)) {
            let hasDecimal = true;
            if (_.isNumber(this.decimals) && this.decimals === 0) {
                hasDecimal = false;
            }
            let hasSign = true;
            if (_.isNumber(this.min) && this.min >= 0 && this.autoCorrect) {
                hasSign = false;
            }
            numericRegex = Helper.createNumericRegex(hasDecimal, hasSign);
        }
        return numericRegex.test(input);
    }
    parseNumber(input) {
        return numeral(input).value();
    }
    limitValue(value) {
        if (_.isNumber(this.max) && value > this.max) {
            return this.max;
        }
        if (_.isNumber(this.min) && value < this.min) {
            return this.min;
        }
        return value;
    }
    isInRange(value) {
        if (_.isNumber(value)) {
            if (_.isNumber(this.min) && value < this.min) {
                return false;
            }
            if (_.isNumber(this.max) && value > this.max) {
                return false;
            }
        }
        return true;
    }
    restrictModelValue(value) {
        let newValue = this.restrictDecimals(value);
        if (this.autoCorrect && this.limitValue(newValue) !== newValue) {
            newValue = null;
        }
        return newValue;
    }
    restrictDecimals(value) {
        if (_.isNumber(this.decimals)) {
            const words = String(value).split('.');
            if (words.length === 2) {
                const decimalPart = words[1];
                if (decimalPart.length > this.decimals) {
                    value = +`${words[0]}.${decimalPart.substr(0, this.decimals)}`;
                }
            }
        }
        return value;
    }
    setInputValue(value = this.value) {
        const inputValue = this.formatValue(value);
        this.renderer2.setProperty(this.numericInput.nativeElement, 'value', inputValue);
        this.inputValue = inputValue;
    }
    updateValue(value) {
        if (this.value !== value) {
            this.previousValue = this.value;
            this.value = value;
            this.ngChange(value);
            this.valueChange.emit(value);
        }
    }
    formatValue(value) {
        if (!value && value !== 0) {
            return '';
        }
        if (this.focused) {
            return this.formatInputValue(value);
        }
        else {
            return this.formatNumber(value);
        }
    }
    formatInputValue(value) {
        return String(value);
    }
    formatNumber(value) {
        return numeral(value).format(this.format);
    }
    setSelection(start, end) {
        this.numericInput.nativeElement.setSelectionRange(start, end);
    }
    isNumber(input) {
        return +input || input === '0';
    }
    get classValue() {
        this.cssClass += this.cssClass.search(/form\-control/gi) === -1 ? ' form-control' : '';
        this.cssClass += this.cssClass.search(/w\-100/gi) === -1 ? ' w-100' : '';
        return this.cssClass;
    }
}
export { ɵ0 };
