import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../../environments/environment';
import { FileSubmitBeforeUploadComponent } from '../../../../shared/components/file-submit-before-upload/file-submit-before-upload.component';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import * as rewardModel from '../../../../shared/models/reward.model';
import { RewardRequestService } from '../../../../shared/services/reward-request.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { b64toBlob } from '../../../../shared/utils/b64toBlob-util';

@Component({
  selector: 'app-store-import-modal',
  templateUrl: './store-import-modal.component.html',
  styleUrls: ['./store-import-modal.component.scss']
})
export class StoreImportModalComponent implements OnInit, OnDestroy {
  @ViewChild(FileSubmitBeforeUploadComponent, { static: false })
  fileUpload: FileSubmitBeforeUploadComponent;
  @Output() submitUpload: EventEmitter<any> = new EventEmitter();
  @Output() stores: EventEmitter<any> = new EventEmitter();
  public form: FormGroup;

  @Input() loading: boolean;
  @Input() isUploadSuccess: boolean;
  @Input() conditionPurchase: rewardModel.ConditionPurchase;
  public errorFile: Blob;
  public errorMessage: string;
  private fileName;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly logger: NGXLogger,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly rewardRequestService: RewardRequestService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.get('importItems').setValue(null);
  }

  createForm() {
    this.form = this.fb.group({
      importItems: [null]
    });
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportRewardRequestConfigs.timeFormat);
  }

  onSubmit() {}

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  onDownloadErrorExcel() {
    saveAs(this.errorFile, `${this.fileName}`);
  }

  onUpload($event) {
    const fileList = $event.files;
    this.errorFile = null;
    this.rewardRequestService.importFileStoreRequest(fileList.value[0], this.conditionPurchase.condition).subscribe(
      res => {
        this.stores.emit(res);
        this.bsModalRef.hide();
      },
      errorResponse => {
        this.fileName = fileList.value[0].name;
        this.form.get('importItems').setErrors({ importFail: true });
        if (errorResponse.error.fileBase64String) {
          this.errorMessage = `${errorResponse.error.rowErrorNo} Error Found. Download this file to edit or delete invalid row then re-import.`;
          this.errorFile = b64toBlob(errorResponse.error.fileBase64String);
        } else {
          this.errorMessage = errorResponse.error.message;
        }
      }
    );
  }
}
