/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reference-document-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./reference-document-link.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/auth-guard.service";
var styles_ReferenceDocumentLinkComponent = [i0.styles];
var RenderType_ReferenceDocumentLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReferenceDocumentLinkComponent, data: {} });
export { RenderType_ReferenceDocumentLinkComponent as RenderType_ReferenceDocumentLinkComponent };
export function View_ReferenceDocumentLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "reference-link"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkToComponent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { text: 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, _co.isAllowToLink()); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.isAllowToLink() ? "Click to open reference document" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.text; _ck(_v, 5, 0, currVal_2); }); }
export function View_ReferenceDocumentLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reference-document-link", [], null, null, null, View_ReferenceDocumentLinkComponent_0, RenderType_ReferenceDocumentLinkComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReferenceDocumentLinkComponent, [i4.Router, i5.AuthGuardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReferenceDocumentLinkComponentNgFactory = i1.ɵccf("app-reference-document-link", i3.ReferenceDocumentLinkComponent, View_ReferenceDocumentLinkComponent_Host_0, { module: "module", text: "text", referenceNo: "referenceNo", referenceID: "referenceID", permissions: "permissions" }, {}, []);
export { ReferenceDocumentLinkComponentNgFactory as ReferenceDocumentLinkComponentNgFactory };
