<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ getPageTitle() }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onAddCondition()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Store Condition<span class="text-danger">*</span></label>
            <ng-select
              [items]="storeList"
              bindLabel="storeCodeName"
              bindValue="storeCode"
              dropdownPosition="auto"
              labelForId="storeList"
              placeholder="Please select..."
              notFoundText="No Data"
              formControlName="store"
              (change)="onSelectStore($event)"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Product Level<span class="text-danger">*</span></label>
            <ng-select
              [items]="productLevelList"
              bindLabel="label"
              bindValue="value"
              dropdownPosition="auto"
              labelForId="productLevelList"
              placeholder="Please select..."
              notFoundText="No Data"
              formControlName="productLevel"
              (change)="onChangeProductLevel($event)"
            >
            </ng-select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Product Value<span class="text-danger">*</span></label>
            <ng-select
              [items]="productValueList"
              bindLabel="name"
              bindValue="code"
              dropdownPosition="auto"
              labelForId="productValueList"
              placeholder="Please select..."
              notFoundText="No Data"
              formControlName="productValue"
              (change)="onSelectProductValue($event)"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Parameter Value<span class="text-danger">*</span></label>
            <app-numeric-textbox
              id="parameterValue"
              formControlName="parameterValue"
              [format]="getParameterFactor().format"
              [minValue]="getParameterFactor().minValue"
              [maxValue]="getParameterFactor().maxValue"
              [negativeSign]="getParameterFactor().allowNegative"
              [decimals]="getParameterFactor().decimals"
              maxlength="{{
                getParameterFactor().allowNegative && form.value.parameterValue < 0
                  ? getParameterFactor().maxLength + 1
                  : getParameterFactor().maxLength
              }}"
              [ngClass]="{
                'is-invalid': form.get('parameterValue').errors?.isZero
              }"
              [allowPaste]="false"
            >
            </app-numeric-textbox>
            <label for="parameterValuePercentage" class="percentage" *ngIf="parameterInfo.type === 'PERCENT'"
              >%</label
            >

            <div class="invalid-display text-danger" *ngIf="form.get('parameterValue').errors?.isZero">
              <div>
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-1">
        <div class="col-12">
          <span class="active-period">Active Period</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Start Date<span class="text-danger">*</span></label>
            <input
              id="addConditionStartDate"
              formControlName="startDate"
              type="text"
              placeholder="{{ dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [minDate]="minDateFrom"
              [maxDate]="maxDate"
              (bsValueChange)="onChangeDateFrom($event)"
              readonly
            />
            <label for="addConditionStartDate" class="icon-calendar btn-calendar"></label>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>End Date<span class="text-danger">*</span></label>
            <input
              id="addConditionEndDate"
              formControlName="endDate"
              type="text"
              placeholder="{{ isExpired ? '--/--/--' : dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [minDate]="minDateTo"
              (bsValueChange)="onChangeDateTo($event)"
              [isDisabled]="isExpired"
              readonly
            />
            <label for="addConditionEndDate" class="icon-calendar btn-calendar"></label>

            <div class="d-block mt-1">
              <input
                id="noExpired"
                name="noExpired"
                type="checkbox"
                [(ngModel)]="isExpired"
                [ngModelOptions]="{ standalone: true }"
                (change)="onToggleNoExpire()"
              />
              <label for="noExpired" class="mb-0 ml-1 label-text">No Expired</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="responseError" class="alert-danger form-group mb-0">
        {{ responseError }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!errorData; else error">
      <button type="button" class="btn btn-standard mr-2" (click)="closeModal()" id="btnCancel">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="mode === 'REQUEST_CREATE'"
        (click)="onAddCondition()"
        id="btnAddCondition"
        [disabled]="form.invalid"
      >
        Add
      </button>
      <button
        type="button"
        class="btn btn-primary"
        *ngIf="mode === 'REQUEST_EDIT'"
        (click)="onUpdateCondition()"
        id="btnUpdateCondition"
        [disabled]="form.invalid"
      >
        Update
      </button>
    </ng-container>
    <ng-template #error>
      <button type="button" class="btn btn-primary" (click)="closeModal()" id="btnOk">
        OK
      </button>
    </ng-template>
  </div>
</div>
