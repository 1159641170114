import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RewardPageModes } from '../../../shared/enum/reward.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import { RewardActiveSummary, RewardContent, RewardSearchCriteria } from '../../../shared/models/reward.model';
import { AuthGuardService } from '../../../shared/services';
import {
  RewardActiveListRequestAction,
  RewardActiveSummaryRequestAction
} from '../../../shared/store/actions/reward.actions';
import { RewardActiveState } from '../../../shared/store/reducers/reward-active.reducers';
import {
  selectRewardActiveList,
  selectRewardActiveListAll,
  selectRewardActiveListCriteria,
  selectRewardActiveSummary
} from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { RewardViewComponent } from '../reward-view/reward-view.component';

@Component({
  selector: 'app-reward-active-list',
  templateUrl: './reward-active-list.component.html',
  styleUrls: ['./reward-active-list.component.scss']
})
export class RewardActiveListComponent extends BaseSearchComponent<
  RewardSearchCriteria,
  RewardContent,
  RewardActiveState
> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;
  listRoute: Array<RouteLinkTab>;
  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;

  public activeListState$: Observable<RewardActiveState>;
  public resultActiveItemList$: Observable<RewardContent[]>;
  public activeSummaryState$: Observable<RewardActiveSummary>;
  public dateFormat = environment.dateFormat;
  isShowView: boolean;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectRewardActiveListAll, selectRewardActiveList);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    this.setRouteTab();
    this.setInitialCriteriaObject();
    this.initState();
    // this.search();
    this.initialData();
  }

  createForm() {}
  onAdvanceSubmit() {}
  prepareSearchCriteriaTags() {}

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.store.dispatch(new RewardActiveSummaryRequestAction());
    this.search();
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
  setRouteTab(): void {
    const hasListPagePermission = this.authGuardService.checkPermission(['rw_m', 'rw_app', 'rw_v']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['rw_app', 'rw_m', 'rr_v']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Active Reward', url: '/campaign/reward-active-list' });
      this.listRoute.push({ tabName: 'Reward List', url: '/campaign/reward-list' });
    }
    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Reward Request', url: '/campaign/reward-request-list' });
    }
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore.pipe(select(selectRewardActiveListCriteria)).subscribe(criteriaObject => {
      this.currentPage = criteriaObject.page + 1;
    });

    this.store.dispatch(new RewardActiveSummaryRequestAction());
  }

  initialData() {
    this.activeListState$ = this.localStore.pipe(select(selectRewardActiveList));
    this.activeSummaryState$ = this.localStore.pipe(select(selectRewardActiveSummary));
    this.resultActiveItemList$ = this.localStore.pipe(select(selectRewardActiveListAll));
  }

  goToView(viewParams?: any) {
    console.log(viewParams);

    this.isShowView = true;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        RewardViewComponent,
        {
          title: 'View Reward',
          mode: RewardPageModes.VIEW,
          rewardNo: viewParams.rewardNo
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });

    this.bsModalRef.content.action.pipe(untilComponentDestroyed(this)).subscribe(result => {
      if (ModalButtonResponseEnum.CLOSE === result) {
        this.isShowView = false;
      }
    });
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    this.criteriaObject = {
      page: 0,
      size: this.pageSize
    };
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  search() {
    this.store.dispatch(new RewardActiveListRequestAction({ criteria: this.criteriaObject }));
  }

  doDestroy() {}
}
