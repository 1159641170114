<div [formGroup]="parentForm">
  <div formGroupName="ownerProfile">
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="ownerContactFirstName">Contact First Name (EN)<span class="text-danger">*</span></label>
        <input
          id="ownerContactFirstName"
          name="ownerContactFirstName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="contactFirstName"
          placeholder="Contact First Name (EN)"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.contactFirstName.errors
          }"
          appAlphabetOnly
        />
        <div *ngIf="submitted && ownerProfile.controls.contactFirstName.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.contactFirstName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="ownerContactLastName">Contact Last Name (EN)<span class="text-danger">*</span></label>
        <input
          id="ownerContactLastName"
          name="ownerContactLastName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="contactLastName"
          placeholder="Contact Last Name (EN)"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.contactLastName.errors
          }"
          appAlphabetOnly
        />
        <div *ngIf="submitted && ownerProfile.controls.contactLastName.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.contactLastName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="ownerContactFirstNameLocal">Contact First Name (Local)<span class="text-danger">*</span></label>
        <input
          id="ownerContactFirstNameLocal"
          name="ownerContactFirstNameLocal"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="contactLocalFirstName"
          placeholder="Contact First Name (Local)"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.contactLocalFirstName.errors
          }"
        />
        <div *ngIf="submitted && ownerProfile.controls.contactLocalFirstName.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.contactLocalFirstName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="ownerContactLastNameLocal">Contact Last Name (Local)<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="ownerContactLastNameLocal"
          name="ownerContactLastNameLocal"
          maxlength="100"
          formControlName="contactLocalLastName"
          placeholder="Contact Last Name (Local)"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.contactLocalLastName.errors
          }"
        />
        <div *ngIf="submitted && ownerProfile.controls.contactLocalLastName.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.contactLocalLastName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="ownerIdCard">ID card<span class="text-danger">*</span></label>
        <input
          id="ownerIdCard"
          name="ownerIdCard"
          type="text"
          class="form-control is-invalid"
          maxlength="13"
          formControlName="cardId"
          placeholder="ID card"
          [readOnly]="ownerIdCardReadOnly"
          [ngClass]="{
            'is-invalid': isShowDuplicatedError(ownerProfile.controls.cardId)
          }"
          appDigitOnly
        />
        <div *ngIf="ownerProfile.controls.cardId.errors?.duplicated" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.cardId.errors?.duplicated">
            {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'ID card' } }}
          </div>
        </div>
        <div *ngIf="submitted && ownerProfile.controls.cardId.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.cardId.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="ownerProfile.controls.cardId.errors.minlength">
            {{ 'ERRORS.INVALID_FORMAT' | translate: { value: 'ID card' } }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="mobilePhone">Mobile Number<span class="text-danger">*</span></label>
        <ngx-intl-tel-input
          [cssClass]="
            'form-control d-block ' + (isShowDuplicatedError(ownerProfile.controls.mobilePhone) ? 'is-invalid' : '')
          "
          [preferredCountries]="['th']"
          [enableAutoCountrySelect]="true"
          [maxlength]="'25'"
          id="mobilePhone"
          formControlName="mobilePhone"
          [ngClass]="{ disabled: ownerProfile.controls.mobilePhone.disabled }"
        >
        </ngx-intl-tel-input>
        <div *ngIf="ownerProfile.controls.mobilePhone.errors?.duplicated" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.mobilePhone.errors?.duplicated">
            {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Mobile Phone' } }}
          </div>
        </div>
        <div *ngIf="submitted && ownerProfile.controls.mobilePhone.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.mobilePhone.errors.required; else validatePhoneNumber">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <ng-template #validatePhoneNumber>
            <div *ngIf="ownerProfile.controls.mobilePhone.errors.validatePhoneNumber">
              {{ 'ERRORS.INVALID_PHONE' | translate }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="email">Email</label>
        <input
          name="email"
          type="email"
          class="form-control"
          maxlength="100"
          formControlName="email"
          placeholder="Email"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.email.errors
          }"
        />
        <div *ngIf="submitted && ownerProfile.controls.email.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.email.errors.email">
            {{ 'ERRORS.INVALID_EMAIL' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <label for="ownerGender">Gender</label>
        <ng-select
          id="ownerGender"
          placeholder="Please select..."
          [items]="listOfValue['gender']"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="gender"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.gender.errors
          }"
        >
        </ng-select>
      </div>
      <div class="col-md-3 mb-3">
        <label for="ownerBirthDate">BirthDate<span class="text-danger">*</span></label>
        <input
          id="ownerBirthDate"
          name="ownerBirthDate"
          type="text"
          placeholder="{{ dateFormat }}"
          class="form-control calendar"
          autocomplete="off"
          [ngClass]="{
            'is-invalid': submitted && ownerProfile.controls.birthDate.errors
          }"
          bsDatepicker
          [bsConfig]="bsConfig"
          formControlName="birthDate"
          readonly
        />
        <label for="ownerBirthDate" class="icon-calendar btn-calendar"></label>
        <div *ngIf="submitted && ownerProfile.controls.birthDate.errors" class="invalid-feedback">
          <div *ngIf="ownerProfile.controls.birthDate.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="ownerPicture">Owner Picture</label>
        <app-files-upload
          id="ownerPicture"
          formControlName="ownerPicture"
          class="form-control"
          [hasError]="submitted && ownerProfile.controls.ownerPicture.errors"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'ownerPicture'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [fileModule]="fileModule.MERCHANT_REQUEST"
        ></app-files-upload>
      </div>
    </div>
  </div>
</div>
