<div class="table-responsive" *ngIf="this.purchaseAmountCoupon.controls.length > 0">
  <table id="data-table" class="table table-striped table-bordered table-hover w-100" [formGroup]="parentForm">
    <thead>
      <tr>
        <th class="text-center w-5">No.</th>
        <th class="text-center w-25">Purchase Amount<span class="text-danger">*</span></th>
        <th class="text-center w-25">Coupon<span class="text-danger">*</span></th>
        <th class="text-center w-25">Minimum per Bill<span class="text-danger">*</span></th>
        <th class="text-center w-5" *ngIf="mode !== pageMode.VIEW">Action</th>
      </tr>
    </thead>
    <tbody formArrayName="purchaseAmountCoupon">
      <tr *ngFor="let tdStore of purchaseAmountCoupon.controls; index as i" [formGroupName]="i">
        <td class="text-center p-1 w-5">{{ i + 1 }}</td>
        <td class="p-1 w-25">
          <app-numeric-textbox
            id="amount"
            [minValue]="0"
            [decimals]="2"
            [maxValue]="999999.99"
            [min]="0"
            maxlength="9"
            formControlName="amount"
            [format]="'0,0.00'"
            [negativeSign]="false"
            class="td-numeric-textbox"
            placeholder="0.00"
            [ngClass]="{ 'is-invalid': submitted && (tdStore.errors || tdStore.get('amount').errors) }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && tdStore.get('amount').errors" class="invalid-feedback">
            <div *ngIf="tdStore.get('amount').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.get('amount').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
            <div *ngIf="tdStore.get('amount').errors.amount_duplicate">
              Purchase Item is duplicate with other Reward.
            </div>
          </div>
          <div
            *ngIf="submitted && tdStore.errors && tdStore.errors.duplicated"
            class="d-block invalid-feedback text-danger"
          >
            Duplicate data
          </div>
        </td>
        <td class="p-1 w-25">
          <app-numeric-textbox
            id="coupon"
            [minValue]="0"
            [decimals]="2"
            [maxValue]="999.99"
            [min]="0"
            maxlength="6"
            formControlName="coupon"
            [format]="'0,0.00'"
            class="td-numeric-textbox"
            [negativeSign]="false"
            placeholder="0.00"
            [ngClass]="{ 'is-invalid': submitted && tdStore.get('coupon').errors }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && tdStore.get('coupon').errors" class="invalid-feedback">
            <div *ngIf="tdStore.get('coupon').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.get('coupon').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
          </div>
        </td>
        <td class="p-1 w-25">
          <app-numeric-textbox
            id="minPerBill"
            [minValue]="0"
            [decimals]="2"
            [maxValue]="999999.99"
            class="td-numeric-textbox"
            [min]="0"
            maxlength="9"
            formControlName="minPerBill"
            [format]="'0,0.00'"
            [negativeSign]="false"
            placeholder="0.00"
            [ngClass]="{ 'is-invalid': submitted && tdStore.get('minPerBill').errors }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && tdStore.get('minPerBill').errors" class="invalid-feedback">
            <div *ngIf="tdStore.get('minPerBill').errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="tdStore.get('minPerBill').errors.isZero">
              {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
            </div>
          </div>
        </td>
        <td class="p-1 w-5 text-center align-middle" *ngIf="mode !== pageMode.VIEW">
          <a *ngIf="mode !== pageMode.REQUEST_VIEW" class="btn-text-link" (click)="deleteItem(i)">
            <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div
  class="row"
  *ngIf="
    this.purchaseAmountCoupon.length < this.maximumRow && mode !== pageMode.REQUEST_VIEW && mode !== pageMode.VIEW
  "
>
  <div class="col-md-12">
    <a class="btn-text-link d-inline-block ml-1 float-left" (click)="addPurchaseTier()">+Add Purchase Tier </a>
  </div>
</div>
