import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SearchProductModalComponent } from '../../../shared/components/search-product-modal/search-product-modal.component';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { ProductRestrictEnum, ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { RedeemPriceType, RewardPageModes } from '../../../shared/enum/reward.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { BarcodeResponse } from '../../../shared/models/barcode.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { selectRewardRequest, selectRewardValidate } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { applyProductTableItemError } from '../../../shared/utils/product-items-util';

@Component({
  selector: 'app-purchase-amount-goods',
  templateUrl: './purchase-amount-goods.component.html',
  styleUrls: ['./purchase-amount-goods.component.scss']
})
export class PurchaseAmountGoodsComponent implements OnInit, OnDestroy {
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;

  @Input() parentForm: FormGroup;
  @Input() mode: RewardPageModes;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() conditionPurchase: ConditionPurchase;
  @Input() maximumRow: number;
  @Input() isEditFirst: boolean;

  public redeemPriceOption = filterDropdown.rewardRedeemPriceOption;

  productType?: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  allowRestrict = ProductRestrictEnum.NO;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;

  constructor(
    public fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parentForm.addControl('purchaseAmountGoods', this.fb.array([], this.duplicatedValidator));

    this.initSelectLocalStore();
    if ([RewardPageModes.REQUEST_VIEW, RewardPageModes.REQUEST_EDIT, RewardPageModes.VIEW].includes(this.mode)) {
      this.setValue();
    }
  }

  initSelectLocalStore() {
    this.localStore
      .pipe(
        select(selectRewardValidate),
        filter(data => data !== null && data.items && data.items.length > 0 && (this.submitted || this.isEditFirst)),
        map(data => data.items)
      )
      .subscribe(items => {
        if (this.purchaseAmountGoods && this.purchaseAmountGoods.controls.length > 0) {
          this.purchaseAmountGoods.controls.forEach(ctr => {
            const error = items.find(x => x.barcode === ctr.get('barcode').value);
            if (error && error.errorMessage) {
              applyProductTableItemError(ctr, error.errorMessage, 'barcode', 'offerItem');
              ctr.get('barcode').updateValueAndValidity();
              ctr.get('offerItem').updateValueAndValidity();
            }
          });
        }
      });
  }
  setValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        if (req && req.purchaseAmtGoodsItems && req.purchaseAmtGoodsItems.length > 0) {
          req.purchaseAmtGoodsItems.forEach((value: any, i) => {
            this.purchaseAmountGoods.push(this.createForm(value.offerItem));

            const purchaseAmount = value.purchaseAmount ? value.purchaseAmount.amount : null;
            const wholesalePriceAmount = value.wholesalePriceAmount ? value.wholesalePriceAmount.amount : null;
            const offerQuantity = value.offerQty;
            const redeemPriceAmount = value.redeemPrice.amount ? value.redeemPrice.amount.amount : null;
            const redeemPriceType = value.redeemPrice ? value.redeemPrice.type : null;
            this.purchaseAmountGoods.at(i).patchValue({
              purchaseAmount: purchaseAmount,
              wholesalePriceIncVatAmount: wholesalePriceAmount,
              offerQuantity: offerQuantity,
              redeemPriceType: redeemPriceType,
              redeemPriceAmount: redeemPriceAmount
            });
          });

          if (this.mode === RewardPageModes.REQUEST_VIEW || this.mode === RewardPageModes.VIEW) {
            this.purchaseAmountGoods.disable();
          } else {
            this.setEditMode();
          }
        }
      });
  }

  setEditMode() {
    this.purchaseAmountGoods.enable();
    if (this.purchaseAmountGoods.controls.length > 0) {
      this.purchaseAmountGoods.controls.forEach(ctr => {
        if (ctr.get('redeemPriceType').value === RedeemPriceType.FREE) {
          ctr.get('redeemPriceAmount').disable();
        }
      });
    }
  }

  get purchaseAmountGoods() {
    return this.parentForm.get('purchaseAmountGoods') as FormArray;
  }
  get validateRequired() {
    return [{ value: null, disabled: false }, [Validators.required, isZeroValidator()]];
  }

  createForm(data: BarcodeResponse): FormGroup {
    return this.fb.group({
      purchaseAmount: this.validateRequired,
      offerItem: data.productName,
      barcode: data.barcode,
      articleNo: data.articleNo,
      unit: data.unit,
      unitFactor: data.unitFactor,
      wholesalePriceIncVatAmount: this.validateRequired,
      offerQuantity: this.validateRequired,
      redeemPriceType: [{ value: null, disabled: false }, [Validators.required]],
      redeemPriceAmount: this.validateRequired
    });
  }

  addPurchaseTier(): void {
    this.searchProductModal.openSelectProductModal();
  }

  deleteItem(i: number): void {
    this.purchaseAmountGoods.removeAt(i);
  }

  onchangeRedeemPriceType(event, i: number): void {
    if (event.value === RedeemPriceType.FREE) {
      this.purchaseAmountGoods
        .at(i)
        .get('redeemPriceAmount')
        .reset(null);
      this.purchaseAmountGoods
        .at(i)
        .get('redeemPriceAmount')
        .clearValidators();

      this.purchaseAmountGoods
        .at(i)
        .get('redeemPriceAmount')
        .disable();
    } else {
      this.purchaseAmountGoods
        .at(i)
        .get('redeemPriceAmount')
        .setValidators([Validators.required, isZeroValidator()]);
      this.purchaseAmountGoods
        .at(i)
        .get('redeemPriceAmount')
        .enable();
    }

    this.purchaseAmountGoods
      .at(i)
      .get('redeemPriceAmount')
      .updateValueAndValidity();
  }

  onAddItem(event: BarcodeResponse[]): void {
    if (event && event.length > 0) {
      if (this.purchaseAmountGoods.controls.some(x => x.get('barcode').value === event[0].barcode)) {
        this.showAlert('Failed', 'Not allow to add duplicated barcode.');
        return;
      }

      this.purchaseAmountGoods.push(this.createForm(event[0]));
    }
  }
  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  get duplicatedValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();
      const availableItems = [];
      for (let i = 0; i < array.length; i++) {
        if (array[i].purchaseAmount > 0) {
          const isDuplicated = availableItems.indexOf(array[i].purchaseAmount) > -1;
          if (!isDuplicated) {
            availableItems.push(array[i].purchaseAmount);
          }

          if (!fa.controls[i].errors || fa.controls[i].getError('duplicated')) {
            fa.controls[i].setErrors(isDuplicated && { duplicated: true });
          }
        }
      }
      return null;
    };
  }

  get pageMode() {
    return RewardPageModes;
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl('purchaseAmountGoods');
  }
}
