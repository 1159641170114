/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store-import-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../shared/components/file-submit-before-upload/file-submit-before-upload.component.ngfactory";
import * as i5 from "../../../../shared/components/file-submit-before-upload/file-submit-before-upload.component";
import * as i6 from "ngx-bootstrap/modal";
import * as i7 from "ngx-logger";
import * as i8 from "ngx-intl-tel-input";
import * as i9 from "./store-import-modal.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@ngrx/store";
import * as i12 from "../../../../shared/services/reward-request.service";
var styles_StoreImportModalComponent = [i0.styles];
var RenderType_StoreImportModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StoreImportModalComponent, data: {} });
export { RenderType_StoreImportModalComponent as RenderType_StoreImportModalComponent };
function View_StoreImportModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Incorrect Format (allow only format file .xlsx). "]))], null, null); }
function View_StoreImportModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-link upload-error-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadErrorExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["download"]))], null, null); }
function View_StoreImportModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StoreImportModalComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorFile; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_StoreImportModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert-danger form-group mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StoreImportModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StoreImportModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.form.controls.importItems.errors == null) ? null : _co.form.controls.importItems.errors.fileExt); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.errorMessage && ((_co.form.controls.importItems.errors == null) ? null : _co.form.controls.importItems.errors.importFail)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StoreImportModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fileUpload: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 32, "div", [["class", "modal-content admin-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Import"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 20, "div", [["class", "modal-body"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 11, "div", [["class", "form-group col-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "label", [["for", "importItems"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Store"])), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 7, "app-file-submit-before-upload", [["class", "form-control border-0 p-0"], ["formControlName", "importItems"], ["id", "importItems"]], [[2, "is-invalid", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "uploadFileContent"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("uploadFileContent" === en)) {
        var pd_2 = (_co.onUpload($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_FileSubmitBeforeUploadComponent_0, i4.RenderType_FileSubmitBeforeUploadComponent)), i1.ɵdid(18, 770048, [[1, 4]], 0, i5.FileSubmitBeforeUploadComponent, [[3, i3.ControlContainer], i6.BsModalService, i7.NGXLogger], { allowedExt: [0, "allowedExt"], allowedTypes: [1, "allowedTypes"], hasError: [2, "hasError"], withMeta: [3, "withMeta"], controlName: [4, "controlName"] }, { uploadFileContent: "uploadFileContent" }), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.FileSubmitBeforeUploadComponent]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.FileSubmitBeforeUploadComponent]), i1.ɵdid(21, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(23, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(24, 81920, null, 0, i8.NativeElementInjectorDirective, [i3.NgControl, i1.ElementRef], null, null), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [["class", "form-group col-md"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StoreImportModalComponent_1)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "btn btn-standard"], ["id", "btCancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "btnSubmit"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fileUpload.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 9, 0, currVal_7); var currVal_16 = "xlsx"; var currVal_17 = "sheet"; var currVal_18 = _co.form.controls.importItems.errors; var currVal_19 = true; var currVal_20 = "importItems"; _ck(_v, 18, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = "importItems"; _ck(_v, 21, 0, currVal_21); _ck(_v, 24, 0); var currVal_22 = _co.form.controls.importItems.errors; _ck(_v, 28, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 11).ngClassValid; var currVal_5 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 18).invalid; var currVal_9 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 23).ngClassValid; var currVal_14 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_23 = ((_co.form.controls.importItems.errors || !_co.form.controls.importItems.value) || (_co.form.controls.importItems.value.length === 0)); _ck(_v, 32, 0, currVal_23); }); }
export function View_StoreImportModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-store-import-modal", [], null, null, null, View_StoreImportModalComponent_0, RenderType_StoreImportModalComponent)), i1.ɵdid(1, 245760, null, 0, i9.StoreImportModalComponent, [i6.BsModalRef, i3.FormBuilder, i10.TranslateService, i7.NGXLogger, i11.Store, i6.BsModalService, i12.RewardRequestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StoreImportModalComponentNgFactory = i1.ɵccf("app-store-import-modal", i9.StoreImportModalComponent, View_StoreImportModalComponent_Host_0, { loading: "loading", isUploadSuccess: "isUploadSuccess", conditionPurchase: "conditionPurchase" }, { submitUpload: "submitUpload", stores: "stores" }, []);
export { StoreImportModalComponentNgFactory as StoreImportModalComponentNgFactory };
