<!--<button class="btn btn-primary" (click)="log()">Log</button>-->

<div class="admin-form my-2" [formGroup]="parentForm" *ngIf="parentForm">
  <ng-container formArrayName="barcodes">
    <ng-container *ngFor="let barcodeForm of getBarcodeForm.controls; let i = index" [formGroupName]="i">
      <div class="container-fluid position-relative border p-3 my-2">
        <span
          *ngIf="
            listOfChange['newBarcode'] &&
            listOfChange['newBarcode']?.after.includes(barcodeForm.controls.barcode.value) &&
            isRequestViewMode
          "
          class="badge badge-success badge-position"
          >New Barcode</span
        >
        <div class="row">
          <div
            class="d-flex justify-content-end"
            *ngIf="
              [
                assortmentPageModes.REQUEST_CREATE,
                assortmentPageModes.ASSORTMENT_EDIT,
                assortmentPageModes.REQUEST_EDIT
              ].includes(mode) &&
              (!barcodeForm.controls.id?.value || !barcodeForm.controls.barcodeStatus?.value)
            "
          >
            <a
              [id]="'delete-barcode-' + i"
              class="text-muted mr-1 pt-2 delete-button cursor-pointer"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
              (click)="onDeleteBarcode(i)"
            >
              <em class="icon-delete"></em>
            </a>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label [attr.for]="'barcode-' + i">Barcode Number</label>
                  <div class="float-right">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input-label checkbox-modal"
                        [id]="'tdPickingUnit-' + i"
                        formControlName="tdPickingUnit"
                        (click)="onChangeTDPickingUnit(i)"
                      />
                    </div>
                    <label
                      [attr.for]="'tdPickingUnit-' + i"
                      [ngClass]="{
                        'bg-h-change':
                          isRequestViewMode &&
                          barcodeForm.controls.changes?.value &&
                          (barcodeForm.controls.changes?.value)['tdPickingUnit']
                      }"
                    >
                      TD Picking Unit
                    </label>
                  </div>
                  <div class="d-flex">
                    <!--<img width="24" height="24" src="assets/images/barcode.svg" alt="barcode" class="mr-2 mt-2" />-->
                    <div class="w-100">
                      <input
                        [id]="'barcode-' + i"
                        type="text"
                        class="form-control"
                        placeholder="Barcode"
                        formControlName="barcode"
                        maxlength="25"
                        [ngClass]="{
                          'is-invalid':
                            (isSubmitted &&
                              barcodeForm.controls.barcode?.errors &&
                              barcodeForm.controls.barcode?.errors.required) ||
                            ((isSubmitted || barcodeForm.touched) &&
                              barcodeForm.controls.barcode?.errors &&
                              barcodeForm.controls.barcode?.errors.duplicatedBarcode)
                        }"
                      />
                      <div
                        *ngIf="
                          (isSubmitted &&
                            barcodeForm.controls.barcode?.errors &&
                            barcodeForm.controls.barcode?.errors.required) ||
                          ((isSubmitted || barcodeForm.touched) &&
                            barcodeForm.controls.barcode?.errors &&
                            barcodeForm.controls.barcode?.errors.duplicatedBarcode)
                        "
                        class="invalid-display"
                      >
                        <div *ngIf="barcodeForm.controls.barcode?.errors.required">
                          {{ 'ERRORS.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="barcodeForm.controls.barcode?.errors.duplicatedBarcode">
                          {{ 'ERRORS.DUPLICATED_BARCODE' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="tdBarcodeStatusSmall">TD Barcode Status:</label>
                  <ng-select
                    id="tdBarcodeStatusSmall"
                    placeholder="Please select..."
                    [searchable]="false"
                    [clearable]="false"
                    [items]="
                      barcodeForm.controls.id?.value && barcodeForm.controls.barcodeStatus?.value
                        ? barcodeStatusListWithoutNoneValue
                        : barcodeStatusList
                    "
                    bindLabel="label"
                    bindValue="value"
                    formControlName="tdBarcodeStatus"
                    [ngClass]="{
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['barcodeStatus']
                    }"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 pr-md-1">
                <div class="form-group">
                  <label [attr.for]="'barSize-' + i">Barcode Size<span class="text-danger">*</span></label>
                  <ng-select
                    [id]="'barSize-' + i"
                    placeholder="Please select..."
                    [searchable]="false"
                    [clearable]="false"
                    [items]="barcodeSizeList"
                    bindLabel="label"
                    bindValue="value"
                    formControlName="barSize"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.barSize?.errors,
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['barSize']
                    }"
                  >
                  </ng-select>
                  <div *ngIf="isSubmitted && barcodeForm.controls.barSize?.errors" class="invalid-display">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-3 pl-md-1">
                <div class="form-group">
                  <label [attr.for]="'unit-' + i">Unit<span class="text-danger">*</span></label>
                  <ng-select
                    [id]="'unit-' + i"
                    placeholder="Please select..."
                    [searchable]="false"
                    [clearable]="false"
                    [items]="listOfValue[assortmentAllType.UNIT]"
                    bindLabel="nameEn"
                    bindValue="code"
                    formControlName="unit"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.unit?.errors,
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['unit']
                    }"
                  >
                  </ng-select>
                  <div *ngIf="isSubmitted && barcodeForm.controls.unit?.errors" class="invalid-display">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-3 pr-md-1">
                <div class="form-group">
                  <label [attr.for]="'unitFactor-' + i">Unit Factor<span class="text-danger">*</span></label>
                  <app-numeric-textbox
                    [id]="'unitFactor-' + i"
                    [format]="'0,0'"
                    [decimals]="2"
                    placeholder="Unit Factor"
                    formControlName="unitFactor"
                    maxlength="9"
                    [negativeSign]="false"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.unitFactor?.errors,
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['unitFactor']
                    }"
                  >
                  </app-numeric-textbox>
                  <div *ngIf="isSubmitted && barcodeForm.controls.unitFactor?.errors" class="invalid-display">
                    <span *ngIf="barcodeForm.controls.unitFactor?.errors.required; else zero">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </span>
                    <ng-template #zero>
                      <span *ngIf="barcodeForm.controls.unitFactor?.errors.isZero; else barcodeSize">
                        {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                      </span>
                    </ng-template>
                    <ng-template #barcodeSize>
                      <span *ngIf="barcodeForm.controls.unitFactor?.errors.isSizeMOrL">
                        {{ 'ERRORS.NOT_ALLOW_TAIL_VALUE' | translate: '{ value: 1 }' }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pl-md-1">
                <div class="form-group">
                  <label [attr.for]="'weight-' + i">Weight (g)<span class="text-danger">*</span></label>
                  <app-numeric-textbox
                    [id]="'weight-' + i"
                    [format]="'0,0[.]00'"
                    placeholder="Gram"
                    formControlName="weight"
                    decimals="2"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.weight?.errors,
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['weight']
                    }"
                  >
                  </app-numeric-textbox>
                  <div *ngIf="isSubmitted && barcodeForm.controls.weight?.errors" class="invalid-display">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Dimension (H * W * L) in cm<span class="text-danger">*</span></label>
                  <div class="row">
                    <div class="col-md-4 pr-md-1">
                      <app-numeric-textbox
                        [id]="'height-' + i"
                        [format]="'0,0[.]00'"
                        [negativeSign]="false"
                        placeholder="cm"
                        formControlName="height"
                        decimals="2"
                        maxlength="10"
                        [ngClass]="{
                          'is-invalid': isSubmitted && barcodeForm.controls.height?.errors,
                          'bg-h-change':
                            isRequestViewMode &&
                            barcodeForm.controls.changes?.value &&
                            (barcodeForm.controls.changes?.value)['height']
                        }"
                      >
                      </app-numeric-textbox>
                    </div>
                    <div class="col-md-4 pr-md-1 pl-md-1">
                      <app-numeric-textbox
                        [id]="'width-' + i"
                        [format]="'0,0[.]00'"
                        [negativeSign]="false"
                        placeholder="cm"
                        formControlName="width"
                        decimals="2"
                        maxlength="10"
                        [ngClass]="{
                          'is-invalid': isSubmitted && barcodeForm.controls.width?.errors,
                          'bg-h-change':
                            isRequestViewMode &&
                            barcodeForm.controls.changes?.value &&
                            (barcodeForm.controls.changes?.value)['width']
                        }"
                      >
                      </app-numeric-textbox>
                    </div>
                    <div class="col-md-4 pl-md-1">
                      <app-numeric-textbox
                        [id]="'length-' + i"
                        [format]="'0,0[.]00'"
                        [negativeSign]="false"
                        placeholder="cm"
                        formControlName="length"
                        decimals="2"
                        maxlength="10"
                        [ngClass]="{
                          'is-invalid': isSubmitted && barcodeForm.controls.length?.errors,
                          'bg-h-change':
                            isRequestViewMode &&
                            barcodeForm.controls.changes?.value &&
                            (barcodeForm.controls.changes?.value)['length']
                        }"
                      >
                      </app-numeric-textbox>
                      <!---->
                    </div>
                  </div>
                  <div
                    *ngIf="
                      isSubmitted &&
                      (barcodeForm.controls.height?.errors ||
                        barcodeForm.controls.width?.errors ||
                        barcodeForm.controls.length?.errors)
                    "
                    class="invalid-display"
                  >
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="articleTypeEnum.MERCHANDISE === articleType">
                <div class="form-group">
                  <label [attr.for]="'suggestedRetailPriceIncVat-' + i"
                    >Suggested Retail Price (inc. VAT)<span class="text-danger">*</span></label
                  >
                  <app-numeric-textbox
                    [id]="'suggestedRetailPriceIncVat-' + i"
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    placeholder="0.00"
                    formControlName="suggestedRetailPriceIncVat"
                    decimals="2"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.suggestedRetailPriceIncVat?.errors,
                      'bg-h-change':
                        isRequestViewMode &&
                        barcodeForm.controls.changes?.value &&
                        (barcodeForm.controls.changes?.value)['suggestedRetailPrice']
                    }"
                  >
                  </app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && barcodeForm.controls.suggestedRetailPriceIncVat?.errors"
                    class="invalid-display w-max-content"
                  >
                    <span *ngIf="barcodeForm.controls.suggestedRetailPriceIncVat?.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row" *ngIf="articleTypeEnum.MERCHANDISE === articleType">
              <div class="col-md-6">
                <div class="form-group">
                  <label [attr.for]="'retailPriceIncVat-' + i"
                    >Retail Price (inc. VAT)<span class="text-danger">*</span></label
                  >
                  <app-numeric-textbox
                    [id]="'retailPriceIncVat-' + i"
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    placeholder="0.00"
                    formControlName="retailPriceIncVat"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.retailPriceIncVat?.errors
                    }"
                  >
                  </app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && barcodeForm.controls.retailPriceIncVat?.errors"
                    class="invalid-display w-max-content"
                  >
                    <span *ngIf="barcodeForm.controls.retailPriceIncVat?.errors.required; else zero">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </span>
                    <ng-template #zero>
                      <span *ngIf="barcodeForm.controls.retailPriceIncVat?.errors.isZero">
                        {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label [attr.for]="'retailPriceEffectiveDate-' + i"
                    >Retail Price Effective Date<span class="text-danger">*</span></label
                  >
                  <input
                    [id]="'retailPriceEffectiveDate-' + i"
                    type="text"
                    placeholder="{{ dateFormat }}"
                    class="form-control calendar"
                    readonly
                    [bsConfig]="bsDateConfig"
                    placement="bottom"
                    bsDatepicker
                    formControlName="retailPriceEffectiveDate"
                    [minDate]="minDate"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitted &&
                        barcodeForm.controls.changes?.value &&
                        barcodeForm.controls.retailPriceEffectiveDate?.errors
                    }"
                  />
                  <label [attr.for]="'retailPriceEffectiveDate-' + i" class="icon-calendar btn-calendar"></label>
                  <div
                    *ngIf="isSubmitted && barcodeForm.controls.retailPriceEffectiveDate?.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="barcodeForm.controls.retailPriceEffectiveDate?.errors">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-1" *ngIf="articleTypeEnum.MERCHANDISE === articleType">
              <div class="col-md-3 pr-md-1">
                <div class="form-group">
                  <label [attr.for]="'retailPriceVat-' + i">Retail Price VAT</label>
                  <app-numeric-textbox
                    [id]="'retailPriceVat-' + i"
                    [format]="'0,0.00'"
                    placeholder="0.00"
                    formControlName="retailPriceVat"
                    decimals="2"
                    maxlength="10"
                  >
                  </app-numeric-textbox>
                </div>
              </div>

              <div class="col-md-3 pl-md-1">
                <div class="form-group">
                  <label [attr.for]="'retailPriceExVat-' + i">Retail Price (ex. VAT)</label>
                  <app-numeric-textbox
                    [id]="'retailPriceExVat-' + i"
                    [format]="'0,0.00'"
                    placeholder="0.00"
                    formControlName="retailPriceExVat"
                    decimals="2"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.retailPriceExVat?.errors
                    }"
                  >
                  </app-numeric-textbox>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label [attr.for]="'estimatePartnerGp-' + i">Estimated Partner GP (%)</label>
                  <app-numeric-textbox
                    [id]="'estimatePartnerGp-' + i"
                    [format]="'0,0.00'"
                    placeholder="0.00"
                    formControlName="estimatePartnerGp"
                    decimals="2"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid': isSubmitted && barcodeForm.controls.estimatePartnerGp?.errors
                    }"
                  >
                  </app-numeric-textbox>
                  <label class="image-description"
                    >Refer to current Wholesale Price as a cost of calculated GP.</label
                  >
                </div>
              </div>
            </div>

            <div
              *ngIf="
                barcodeForm.controls?.tdBarcodeStatus.value &&
                ((articleTypeEnum.MERCHANDISE === articleType &&
                  barcodeForm.controls?.id.value !== null &&
                  assortmentPageModes.ASSORTMENT_EDIT === mode) ||
                  barcodeForm?.controls.scheduleRetailPriceIncVat?.value > 0 ||
                  barcodeForm?.controls.scheduleRetailPriceEffectiveDate?.value ||
                  (articleTypeEnum.MERCHANDISE === articleType &&
                    assortmentRequestTypeEnum.EDIT === type &&
                    assortmentPageModes.REQUEST_EDIT === mode))
              "
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label [attr.for]="'scheduleRetailPriceIncVat-' + i">New Retail Price (inc. VAT)</label>
                    <app-numeric-textbox
                      [id]="'scheduleRetailPriceIncVat-' + i"
                      [format]="'0,0.00'"
                      placeholder="0.00"
                      formControlName="scheduleRetailPriceIncVat"
                      maxlength="10"
                      [negativeSign]="false"
                      [ngClass]="{
                        'is-invalid': isSubmitted && barcodeForm.controls.scheduleRetailPriceIncVat?.errors,
                        'bg-h-change':
                          isRequestViewMode &&
                          barcodeForm.controls.changes?.value &&
                          (barcodeForm.controls.changes?.value)['scheduledRetailPrice']
                      }"
                    >
                    </app-numeric-textbox>
                    <div
                      *ngIf="isSubmitted && barcodeForm.controls.scheduleRetailPriceIncVat?.errors"
                      class="invalid-display w-max-content"
                    >
                      <span *ngIf="barcodeForm.controls.scheduleRetailPriceIncVat?.errors.required; else zero">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </span>
                      <ng-template #zero>
                        <span *ngIf="barcodeForm.controls.scheduleRetailPriceIncVat?.errors.isZero">
                          {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label [attr.for]="'scheduleRetailPriceEffectiveDate-' + i"
                      >New Retail Price Effective Date</label
                    >
                    <input
                      [id]="'scheduleRetailPriceEffectiveDate-' + i"
                      type="text"
                      placeholder="{{ dateFormat }}"
                      class="form-control calendar"
                      readonly
                      [bsConfig]="bsDateConfig"
                      placement="bottom"
                      bsDatepicker
                      formControlName="scheduleRetailPriceEffectiveDate"
                      [minDate]="minNewRetailPriceEffectiveDate"
                      [ngClass]="{
                        'is-invalid': isSubmitted && barcodeForm.controls.scheduleRetailPriceEffectiveDate?.errors,
                        'bg-h-change':
                          isRequestViewMode &&
                          barcodeForm.controls.changes?.value &&
                          (barcodeForm.controls.changes?.value)['scheduledEffectiveDate']
                      }"
                    />
                    <label
                      [attr.for]="'scheduleRetailPriceEffectiveDate-' + i"
                      class="icon-calendar btn-calendar"
                    ></label>
                    <div
                      *ngIf="isSubmitted && barcodeForm.controls.scheduleRetailPriceEffectiveDate?.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="barcodeForm.controls.scheduleRetailPriceEffectiveDate?.errors.required">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                      <div *ngIf="barcodeForm.controls.scheduleRetailPriceEffectiveDate?.errors.invalidDate">
                        {{ 'ERRORS.INVALID_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 pr-md-1">
                  <div class="form-group">
                    <label [attr.for]="'scheduleRetailPriceVat-' + i">New Retail Price VAT</label>
                    <app-numeric-textbox
                      [id]="'scheduleRetailPriceVat-' + i"
                      [format]="'0,0.00'"
                      placeholder="0.00"
                      formControlName="scheduleRetailPriceVat"
                      decimals="2"
                      maxlength="10"
                    >
                    </app-numeric-textbox>
                  </div>
                </div>

                <div class="col-md-3 pl-md-1">
                  <div class="form-group">
                    <label [attr.for]="'scheduleRetailPriceExVat-' + i">New Retail Price (ex. VAT)</label>
                    <app-numeric-textbox
                      [id]="'retailPriceExVat-' + i"
                      [format]="'0,0.00'"
                      placeholder="0.00"
                      formControlName="scheduleRetailPriceExVat"
                      decimals="2"
                      maxlength="10"
                      [ngClass]="{
                        'is-invalid': isSubmitted && barcodeForm.controls.scheduleRetailPriceExVat?.errors
                      }"
                    >
                    </app-numeric-textbox>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label [attr.for]="'scheduleEstimatePartnerGp-' + i">New Estimated Partner GP (%)</label>
                    <app-numeric-textbox
                      [id]="'scheduleEstimatePartnerGp-' + i"
                      [format]="'0,0.00'"
                      placeholder="0.00"
                      formControlName="scheduleEstimatePartnerGp"
                      decimals="2"
                      maxlength="10"
                      [ngClass]="{
                        'is-invalid': isSubmitted && barcodeForm.controls.scheduleEstimatePartnerGp?.errors
                      }"
                    >
                    </app-numeric-textbox>
                    <label class="image-description"
                      >Refer to current Wholesale Price as a cost of calculated GP.</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
