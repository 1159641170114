import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProfitSharingModeEnum, ProfitSharingStatusEnum } from '../../../shared/enum/profit-sharing.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  ProfitSharingData,
  ProfitSharingPrintCriteria,
  ProfitSharingSearchCriteria
} from '../../../shared/models/profit-sharing.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { ProfitSharingService } from '../../../shared/services/profit-sharing.service';
import {
  ProfitSharingListRequestAction,
  ProfitSharingSubmitErrorResetAction,
  ProfitSharingSubmitRequestAction,
  ProfitSharingViewRequestAction
} from '../../../shared/store/actions/profit-sharing.actions';
import {
  selectProfitSharingData,
  selectProfitSharingSearchCriteria,
  selectProfitSharingSubmitError
} from '../../../shared/store/selectors/profit-sharing.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { roundTo2DecimalPlace } from '../../../shared/utils/number-util';

@Component({
  selector: 'app-profit-sharing-view',
  templateUrl: './profit-sharing-view.component.html',
  styleUrls: ['./profit-sharing-view.component.scss']
})
export class ProfitSharingViewComponent extends BaseComponent {
  @Output() data: {
    title: string;
    id: string;
    mode: ProfitSharingModeEnum;
  };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  public profitSharing: ProfitSharingData;
  public profitSharingForm: FormGroup;
  public profitSharingModeEnum = ProfitSharingModeEnum;
  public isSubmitted = false;
  public hasPermissionManage: boolean;
  public vatPct: number;
  private criteriaObject: ProfitSharingSearchCriteria;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected profitSharingService: ProfitSharingService,
    private masterService: MasterService
  ) {
    super(store, modalService, true);
    this.hasPermissionManage = this.authGuardService.checkPermission(['profitsharing_m']);
  }

  private localStore: Observable<any>;

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.profitSharing = {} as ProfitSharingData;
    this.initialDataForViewEdit();
    this.initialSubscription();
  }

  initialDataForViewEdit() {
    const countryQuery = new GraphqlQueryObject();

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];
    this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct))
      .subscribe(result => {
        this.vatPct = result;
      });
    this.profitSharing = {} as ProfitSharingData;
    this.store.dispatch(new ProfitSharingViewRequestAction(this.data.id));
    this.store.pipe(select(selectProfitSharingData), untilComponentDestroyed(this)).subscribe(profitSharing => {
      if (profitSharing) {
        this.profitSharing = profitSharing as ProfitSharingData;
        this.createForm();
      }
    });
  }

  initialSubscription() {
    this.localStore.pipe(select(selectProfitSharingSearchCriteria)).subscribe(criteriaObject => {
      this.criteriaObject = criteriaObject;
    });

    this.localStore.pipe(select(selectProfitSharingSubmitError)).subscribe(submitError => {
      if (submitError) {
        const alertModalRef = this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: submitError
          }
        });

        alertModalRef.content.action
          .pipe(untilComponentDestroyed(this))
          .subscribe((result: ModalButtonResponseEnum) => {
            if (result === ModalButtonResponseEnum.OK) {
              this.store.dispatch(new ProfitSharingSubmitErrorResetAction());
              this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
              this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
            }
          });
      }
    });
  }

  doAfterVersionAlertModal() {
    this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.store.dispatch(new ProfitSharingListRequestAction(this.criteriaObject));
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  subscribeForVersionError() {}

  onCloseFullModal() {
    if (this.profitSharingForm.touched || this.profitSharingForm.dirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
      return;
    }
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  getColorStatus(status): string {
    return status.toLowerCase();
  }

  createForm() {
    this.profitSharingForm = this.fb.group({
      compensateAmount: [this.profitSharing.compensateAmount, Validators.required],
      withHoldingTaxAmount: [this.profitSharing.withHoldingTaxAmount, Validators.required],
      lessCashTransferAmount: [this.profitSharing.lessCashTransferAmount, Validators.required],
      storeUseAmount: [this.profitSharing.storeUseAmount, Validators.required],
      stockLossAdjustmentAmount: [this.profitSharing.stockLossAdjustmentAmount, Validators.required],
      destroyAmount: [this.profitSharing.destroyAmount, Validators.required],
      netPenaltyAmount: [this.profitSharing.netPenaltyAmount, Validators.required],
      otherExpenseAmount: [this.profitSharing.otherExpenseAmount, Validators.required],
      cigaretteVatAdjustmentAmount: [this.profitSharing.cigaretteVatAdjustmentAmount, Validators.required],
      overCashTransferAmount: [this.profitSharing.overCashTransferAmount, Validators.required],
      incentiveAmount: [this.profitSharing.incentiveAmount, Validators.required],
      otherIncomeAmount: [this.profitSharing.otherIncomeAmount, Validators.required]
    });
    if (this.data.mode === ProfitSharingModeEnum.VIEW) {
      this.profitSharingForm.disable();
    }

    this.calculateAdministrativeExpenses();
    this.calculateOtherIncome();
    this.updateValueChangesEventForm();
  }

  updateValueChangesEventForm() {
    this.profitSharingForm.controls['cigaretteVatAdjustmentAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateGrossProfitAndProfitSharing();
      });
    this.profitSharingForm.controls['compensateAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateGrossProfitAndProfitSharing();
      });
    this.profitSharingForm.controls['withHoldingTaxAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['lessCashTransferAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['storeUseAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['stockLossAdjustmentAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['destroyAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['netPenaltyAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['otherExpenseAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateAdministrativeExpenses();
      });
    this.profitSharingForm.controls['overCashTransferAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncome();
      });
    this.profitSharingForm.controls['incentiveAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncome();
      });
    this.profitSharingForm.controls['otherIncomeAmount'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.calculateOtherIncome();
      });
  }

  calculateAdministrativeExpenses() {
    const administrativeExpensesAmount =
      this.profitSharingForm.controls.withHoldingTaxAmount.value +
      this.profitSharingForm.controls.lessCashTransferAmount.value +
      this.profitSharingForm.controls.storeUseAmount.value +
      this.profitSharingForm.controls.stockLossAdjustmentAmount.value +
      this.profitSharingForm.controls.destroyAmount.value +
      this.profitSharingForm.controls.netPenaltyAmount.value +
      this.profitSharingForm.controls.otherExpenseAmount.value;

    this.profitSharing.totalOtherExpense = roundTo2DecimalPlace(administrativeExpensesAmount);
    this.calculateProfitSharing();
  }

  calculateOtherIncome() {
    const otherIncomeAmount =
      this.profitSharingForm.controls.overCashTransferAmount.value +
      this.profitSharingForm.controls.incentiveAmount.value +
      this.profitSharingForm.controls.otherIncomeAmount.value;

    this.profitSharing.totalOtherIncome = roundTo2DecimalPlace(otherIncomeAmount);
    this.calculateProfitSharing();
  }

  calculateGrossProfitAndProfitSharing() {
    const grossProfitIncVatAmount = roundTo2DecimalPlace(
      this.profitSharing.salesIncVatAmount -
        this.profitSharing.cogsIncVatAmount +
        this.profitSharingForm.controls.compensateAmount.value -
        this.profitSharingForm.controls.cigaretteVatAdjustmentAmount.value
    );
    this.profitSharing.grossProfitIncVatAmount = grossProfitIncVatAmount;

    const grossProfitExcVatAmount = roundTo2DecimalPlace(
      this.profitSharing.salesExcVatAmount -
        this.profitSharing.cogsExcVatAmount +
        this.profitSharingForm.controls.compensateAmount.value -
        this.profitSharingForm.controls.cigaretteVatAdjustmentAmount.value
    );

    this.profitSharing.grossProfitExcVatAmount = grossProfitExcVatAmount;

    const shareToTDExcVatAmount = roundTo2DecimalPlace(
      (this.profitSharing.salesExcVatAmount -
        this.profitSharing.cogsExcVatAmount +
        this.profitSharingForm.controls.compensateAmount.value) *
        0.15
    );

    this.profitSharing.shareToTDExcVatAmount = shareToTDExcVatAmount;

    const shareToTdVatAmount = roundTo2DecimalPlace((this.profitSharing.shareToTDExcVatAmount * this.vatPct) / 100);
    this.profitSharing.shareToTDVatAmount = shareToTdVatAmount;

    this.profitSharing.shareToTDIncVatAmount = roundTo2DecimalPlace(
      this.profitSharing.shareToTDExcVatAmount + this.profitSharing.shareToTDVatAmount
    );
    const grossProfitAfterShareAmount = roundTo2DecimalPlace(
      this.profitSharing.grossProfitIncVatAmount - this.profitSharing.shareToTDIncVatAmount
    );
    this.profitSharing.grossProfitAfterShareAmount = grossProfitAfterShareAmount;

    this.calculateProfitSharing();
  }

  calculateProfitSharing() {
    const profitSharingAmount = roundTo2DecimalPlace(
      this.profitSharing.grossProfitAfterShareAmount -
        this.profitSharing.totalOtherExpense +
        this.profitSharing.totalOtherIncome
    );

    this.profitSharing.profitSharingAmount = profitSharingAmount > 0 ? profitSharingAmount : 0;
    this.profitSharing.remainingBalanceAmount = profitSharingAmount < 0 ? profitSharingAmount * -1 : 0;
    this.profitSharing.profitStatus =
      profitSharingAmount >= 0 ? ProfitSharingStatusEnum.POSITIVE : ProfitSharingStatusEnum.NEGATIVE;
  }

  toggleToEditMode() {
    const isEndOfPeriod = moment(this.profitSharing.lastAllowEditedDate).isBefore(moment().utc());
    if (isEndOfPeriod) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to edit. This period has been closed.'
        }
      });
      return;
    }

    this.data.mode = ProfitSharingModeEnum.EDIT;
    this.data.title = 'Edit Profit Sharing';
    this.profitSharingForm.enable();
  }

  onCancel() {
    this.onCloseFullModal();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.profitSharingForm.invalid) {
      return;
    }

    if (this.isNoValueChanged()) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'No data have been edited.'
        }
      });
      return;
    }

    this.handleConfirm();
  }

  isNoValueChanged() {
    const loadedValue = {
      cigaretteVatAdjustmentAmount: this.profitSharing.cigaretteVatAdjustmentAmount,
      destroyAmount: this.profitSharing.destroyAmount,
      incentiveAmount: this.profitSharing.incentiveAmount,
      lessCashTransferAmount: this.profitSharing.lessCashTransferAmount,
      netPenaltyAmount: this.profitSharing.netPenaltyAmount,
      otherExpenseAmount: this.profitSharing.otherExpenseAmount,
      otherIncomeAmount: this.profitSharing.otherIncomeAmount,
      overCashTransferAmount: this.profitSharing.overCashTransferAmount,
      stockLossAdjustmentAmount: this.profitSharing.stockLossAdjustmentAmount,
      storeUseAmount: this.profitSharing.storeUseAmount,
      withHoldingTaxAmount: this.profitSharing.withHoldingTaxAmount,
      compensateAmount: this.profitSharing.compensateAmount
    };

    const currentValue = this.profitSharingForm.getRawValue();

    return isEqual(loadedValue, currentValue);
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.prepareProfitSharingData();
          this.store.dispatch(new ProfitSharingSubmitRequestAction(this.profitSharing));
        }
      });
  }

  prepareProfitSharingData() {
    this.profitSharing = { ...this.profitSharing, ...this.profitSharingForm.getRawValue() };
  }

  get isViewMode(): boolean {
    return [ProfitSharingModeEnum.VIEW].includes(this.data.mode);
  }

  onPrintProfitSharing() {
    if (!this.profitSharing.docNo) {
      return;
    }
    const param: ProfitSharingPrintCriteria = {
      docNo: this.profitSharing.docNo,
      runDate: this.profitSharing.schedule
        .split('/')
        .reverse()
        .join('')
    };

    this.profitSharingService.printProfitSharingPdf(param).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      saveAs(blob, generatedFilenamePdf(this.profitSharing.docNo));
    });
  }
}
