import { BaseReport } from './base-report.model';

export class Z8ParameterListSearchCriteria {
  page: number;
  size: number;
}

export class Z8ParameterListResponse {
  content: Z8ParameterList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class Z8ParameterList {
  id: string;
  guid: string;
  name: string;
  requestNo: string;
  type: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  lastUpdatedDate: string;
}

export class Z8ParameterContent {
  id?: string;
  priority?: number;
  store?: string;
  storeNo?: string;
  storeCode?: string;
  storeCodeName?: string;
  productLevel: string;
  productValue: {
    code: string;
    name: string;
  };
  parameterValue: number;
  startDate: string;
  endDate?: string;
  remark?: string;
  status?: string;
}

export class Z8ParameterRequest {
  parameter: string;
  guid: string;
  chunkSize: number;
  chunkNo: number;
  totalChunkSize: number;
  z8Conditions: Z8ParameterContent[];
}

export class Z8ParameterRequestUpdate {
  parameter: string;
  guid: string;
  status: string;
}

export class Z8Article {
  articleNo: string;
  articleNoProductName: string;
  productName: string;
}

export class Z8ExportCriteria extends BaseReport {
  parameter: string;
  storeCode?: string;
  startDate?: string;
  endDate?: string;
  status?: string;

  constructor(init?: Partial<Z8ExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class Z8File {
  refId: string;
  signedUrl: string;
}

export enum Z8ParameterName {
  WEEKEND = 'WEEKEND',
  BEGINNING_MONTH = 'BEGINNING_MONTH',
  PRODUCT_FACTOR = 'PRODUCT_FACTOR',
  PROMOTION = 'PROMOTION',
  MAX_TARGET = 'MAX_TARGET',
  ROUNDING = 'ROUNDING'
}
