/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./numeric-textbox.component";
var styles_NumericTextboxComponent = [];
var RenderType_NumericTextboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NumericTextboxComponent, data: {} });
export { RenderType_NumericTextboxComponent as RenderType_NumericTextboxComponent };
export function View_NumericTextboxComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { numericInput: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["numericInput", 1]], null, 0, "input", [["type", "text"]], [[8, "className", 0], [1, "maxlength", 0], [1, "placeholder", 0], [8, "disabled", 0]], [[null, "input"], [null, "focus"], [null, "blur"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.handleInput() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.handleBlur() !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (_co.handleKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.classValue; var currVal_1 = _co.maxlength; var currVal_2 = _co.placeholder; var currVal_3 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_NumericTextboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-numeric-textbox", [], null, [[null, "paste"]], function (_v, en, $event) { var ad = true; if (("paste" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onPaste($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NumericTextboxComponent_0, RenderType_NumericTextboxComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.NumericTextboxComponent]), i0.ɵprd(5120, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.NumericTextboxComponent]), i0.ɵdid(3, 638976, null, 0, i2.NumericTextboxComponent, [i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var NumericTextboxComponentNgFactory = i0.ɵccf("app-numeric-textbox", i2.NumericTextboxComponent, View_NumericTextboxComponent_Host_0, { negativeSign: "negativeSign", maxlength: "maxlength", min: "min", max: "max", minValue: "minValue", maxValue: "maxValue", cssClass: "cssClass", value: "value", placeholder: "placeholder", decimals: "decimals", disabled: "disabled", format: "format", autoCorrect: "autoCorrect", rangeValidation: "rangeValidation", allowPaste: "allowPaste" }, { valueChange: "valueChange", focus: "focus", blur: "blur", enter: "enter", escape: "escape" }, []);
export { NumericTextboxComponentNgFactory as NumericTextboxComponentNgFactory };
