import { createEntityAdapter } from '@ngrx/entity';
import { RewardActionTypes } from '../actions/reward.actions';
const ɵ0 = (model) => model.rewardNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialRewardActiveState = adapter.getInitialState({
    isLoading: false,
    criteriaObject: {
        page: 0,
        size: 20
    },
    summary: null,
    totalElements: 0,
    totalPages: 0
});
export function RewardActiveReducers(state = initialRewardActiveState, action) {
    switch (action.type) {
        case RewardActionTypes.REWARD_ACTIVE_LIST_REQUESTED:
            return Object.assign({}, state, { criteriaObject: action.payload.criteria });
        case RewardActionTypes.REWARD_ACTIVE_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case RewardActionTypes.REWARD_ACTIVE_SUMMARY_RESPONSE:
            return Object.assign({}, state, { summary: action.payload });
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
