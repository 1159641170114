<div id="delivery-order-list" class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.DELIVERY_ORDER_SHIPMENT' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group d-flex text-nowrap">
        <button
          type="button"
          class="btn btn-secondary col-sm-12 flex-item"
          (click)="showModalShipment(null, shipmentTypeEnum.CREATE, null)"
          *ngIf="hasManageLogistic && (listState$ | async)?.totalElements > 0"
        >
          <em class="icon-plus"></em>
          Create Shipment
        </button>
        <app-import-export-button
          class="ml-2"
          [buttons]="buttons"
          (import)="showImportDoModal()"
          (export)="onOpenExport()"
        ></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="deliveryOrderStatusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          ></ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Warehouse
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="warehouseList"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="warehouseNameDisplay"
                          bindValue="code"
                          formControlName="warehouseCode"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'CREATED_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="createdDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="createdDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'DELIVERY_ORDER.REQUESTED_DELIVERY' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="requestedDeliveryDateFrom"
                          formControlName="requestedDeliveryDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="requestDeliveryMaxDate"
                          (bsValueChange)="onChangeRequestDeliveryDateFrom($event)"
                          readonly
                        />
                        <label for="requestedDeliveryDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="requestedDeliveryDateTo"
                          formControlName="requestedDeliveryDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="requestDeliveryMinDate"
                          (bsValueChange)="onChangeRequestDeliveryDateTo($event)"
                          readonly
                        />
                        <label for="requestedDeliveryDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="createdDateStringTag || requestedDeliveryDateStringTag || warehouseTypeStringTag"
  >
    <div class="search-criteria" *ngIf="warehouseTypeTag">
      <a class="remove-criteria" (click)="clearFilterWarehouse()">
        <em class="icon-close"></em>
      </a>
      <span>{{ warehouseTypeStringTag }}: {{ warehouseTypeTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="createdDateStringTag">
      <a class="remove-criteria" (click)="clearFilterCreatedDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ createdDateStringTag }}: {{ createdDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="requestedDeliveryDateStringTag">
      <a class="remove-criteria" (click)="clearFilterRequestedDeliveryDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ requestedDeliveryDateStringTag }}: {{ requestedDeliveryDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let order$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer">
          <div class="row">
            <div class="col-12 d-flex">
              <label
                class="chk-container"
                [ngClass]="{
                  'indent-chk-container':
                    !order$.shipmentNo && canSelectCheckbox(order$.doStatus, order$.warehouseCode)
                }"
                ><app-click-to-copy [text]="order$.doNo"></app-click-to-copy>
                <input
                  type="checkbox"
                  value=""
                  id="deliveryOrders{{ i }}"
                  class="chkPONo"
                  [(ngModel)]="checkedList[order$.id]"
                  (change)="onSelect($event, order$)"
                  *ngIf="!order$.shipmentNo && canSelectCheckbox(order$.doStatus, order$.warehouseCode)"
                />
                <span
                  *ngIf="!order$.shipmentNo && canSelectCheckbox(order$.doStatus, order$.warehouseCode)"
                  class="check-mark"
                ></span>
              </label>
              <span [ngClass]="getColorStatus(order$.doStatus)" class="card-status do-status ml-auto">{{
                order$.doStatus | titlecase
              }}</span>
            </div>
          </div>
          <div class="row" (click)="goToView(order$)">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order No.:</span>
              <span class="result-value"><app-click-to-copy [text]="order$.poNo"></app-click-to-copy></span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Store:</span>
              <span class="result-value"
                ><app-click-to-copy [text]="order$.storeCode + '-' + order$.storeName"></app-click-to-copy
              ></span>
            </div>
          </div>
          <div class="row" (click)="goToView(order$)">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Req. Delivery Date:</span>
              <span class="result-value">{{ order$.requestedDeliveryDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ order$.createdDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
          <div class="row" (click)="goToView(order$)">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Warehouse:</span>
              <span class="result-value">{{ order$.warehouseDisplayName | dashDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Shipment No:</span>
              <span class="result-value">{{ order$.shipmentNo | dashDisplay: '(None)' }}</span>
            </div>
          </div>
          <div class="row" (click)="goToView(order$)">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Delivery By:</span
              ><span class="result-value">{{ order$.courierName | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Plate No./Driver:</span>
              <span class="result-value">{{ order$.platNoDriverNameDisplay | dashDisplay: '(None)' }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
            *ngIf="
              isCanPrintPDF(order$.doStatus) ||
              showEditButton(order$.doStatus) ||
              showCancelButton(order$.doStatus) ||
              showHistoryButton(order$.doStatus)
            "
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <div class="form-row">
              <button
                type="button"
                class="btn btn-link d-none d-md-block"
                *ngIf="isCanPrintPDF(order$.doStatus)"
                (click)="onPrintPdf(order$)"
              >
                <em class="icon-print"></em>Print
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  showEditButton(order$.doStatus) &&
                  permissionUtil.determineDoPermission(order$.warehouseCode, [permissionAction.MANAGE])
                "
                (click)="goToEdit(order$.id)"
              >
                <em class="icon-edit"></em>Edit
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  showCancelButton(order$.doStatus) &&
                  permissionUtil.determineDoPermission(order$.warehouseCode, [permissionAction.MANAGE])
                "
                (click)="cancelDeliveryOrder(order$.doNo)"
              >
                <em class="icon-forbidden"></em>Cancel
              </button>
              <button
                type="button"
                class="btn btn-link"
                *ngIf="
                  showHistoryButton(order$.doStatus) &&
                  (permissionUtil.determineDoPermission(order$.warehouseCode, [
                    permissionAction.MANAGE,
                    permissionAction.VIEW
                  ]) ||
                    permissionUtil.determineLogisticPermission(order$.warehouseCode, [
                      permissionAction.MANAGE,
                      permissionAction.VIEW
                    ]))
                "
                (click)="showHistory(order$)"
              >
                <em class="icon-history"></em>History
              </button>
            </div>
          </ng-template>

          <button
            type="button"
            class="btn btn-standard btn-loaded"
            *ngIf="
              canLoad(order$.doStatus) &&
              permissionUtil.determineDoPermission(order$.warehouseCode, [permissionAction.MANAGE])
            "
            (click)="showModalShipment(order$.shipmentNo, shipmentTypeEnum.LOAD, order$.doNo)"
          >
            <em class="icon-confirm-load"></em>
            Confirm Load
          </button>
        </div>
      </div>
    </div>

    <!--pagination-->

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements <= 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title pull-left">Export Data</h4>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="exportModal.hide(); resetExportForm()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-group">
            <label>Select Status<span class="text-danger">*</span></label>
            <ng-select
              [items]="deliveryOrderStatusExportList"
              [searchable]="false"
              [clearable]="false"
              [multiple]="true"
              class="select-status w-100"
              bindLabel="label"
              bindValue="value"
              formControlName="status"
              placeholder="Please select..."
            ></ng-select>
          </div>

          <div class="form-group">
            <div class="mb-2">
              Warehouse
            </div>
            <div class="form-group">
              <app-dropdown-multiple-select
                [parentForm]="exportForm"
                controlName="code"
                [items]="exportWarehouseList"
                bindLabel="warehouseNameDisplay"
                bindValue="code"
              >
              </app-dropdown-multiple-select>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12">Order Date</div>
            <div class="form-group col-md-6">
              <label>{{ 'FROM' | translate }}</label>
              <input
                id="orderFrom"
                formControlName="orderDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.orderDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="orderMaxDate"
                (bsValueChange)="onChangeOrderDateFrom($event)"
                readonly
              />
              <label for="orderFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.orderDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.orderDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>{{ 'TO' | translate }}</label>
              <input
                id="orderTo"
                formControlName="orderDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.orderDateTo.errors
                }"
                [bsConfig]="{
                  containerClass: 'theme-dark-blue',
                  dateInputFormat: environment.dateFormat,
                  showWeekNumbers: false
                }"
                [minDate]="orderMinDate"
                (bsValueChange)="onChangeOrderDateTo($event)"
                readonly
              />
              <label for="orderTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.orderDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.orderDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12">Pick Date</div>
            <div class="form-group col-md-6">
              <label>{{ 'FROM' | translate }}</label>
              <input
                id="pickFrom"
                formControlName="pickDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.pickDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="pickMaxDate"
                (bsValueChange)="onChangePickDateFrom($event)"
                readonly
              />
              <label for="pickFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.pickDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.pickDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>{{ 'TO' | translate }}</label>
              <input
                id="pickTo"
                formControlName="pickDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.pickDateTo.errors
                }"
                [bsConfig]="{
                  containerClass: 'theme-dark-blue',
                  dateInputFormat: environment.dateFormat,
                  showWeekNumbers: false
                }"
                [minDate]="pickMinDate"
                (bsValueChange)="onChangePickDateTo($event)"
                readonly
              />
              <label for="pickTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.pickDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.pickDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-12">Req. Delivery Date</div>
            <div class="form-group col-md-6">
              <label>{{ 'FROM' | translate }}</label>
              <input
                id="exFrom"
                formControlName="requestedDeliveryDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.requestedDeliveryDateFrom.errors
                }"
                [bsConfig]="{
                  containerClass: 'theme-dark-blue',
                  dateInputFormat: environment.dateFormat,
                  showWeekNumbers: false
                }"
                [maxDate]="reqDeliveryMaxDate"
                (bsValueChange)="onChangeReqDeliveryDateFrom($event)"
                readonly
              />
              <label for="exFrom" class="icon-calendar btn-calendar"></label>
              <div
                *ngIf="submittedExport && exportForm.controls.requestedDeliveryDateFrom.errors"
                class="invalid-feedback"
              >
                <div *ngIf="exportForm.controls.requestedDeliveryDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>{{ 'TO' | translate }}</label>
              <input
                id="exTo"
                formControlName="requestedDeliveryDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.requestedDeliveryDateTo.errors
                }"
                [bsConfig]="{
                  containerClass: 'theme-dark-blue',
                  dateInputFormat: environment.dateFormat,
                  showWeekNumbers: false
                }"
                [minDate]="reqDeliveryMinDate"
                (bsValueChange)="onChangeReqDeliveryDateTo($event)"
                readonly
              />
              <label for="exTo" class="icon-calendar btn-calendar"></label>
              <div
                *ngIf="submittedExport && exportForm.controls.requestedDeliveryDateTo.errors"
                class="invalid-feedback"
              >
                <div *ngIf="exportForm.controls.requestedDeliveryDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="hasManageDeliveryOrder">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="changeStatusToPicking"
                formControlName="changeStatusToPicking"
              />
              <label class="form-check-label" for="changeStatusToPicking">Change Status New to Picking</label>
            </div>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExport()"
            [disabled]="exportFormInValid"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" bsModal #importInvalidModel="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">{{ importInvalid?.title }}</h6>
        </div>
        <div class="modal-body">
          <div
            *ngIf="importInvalid && importInvalid.table && importInvalid.table.length > 0"
            class="max-height-table table-responsive"
          >
            <table class="table table-striped table-bordered table-hover w-100">
              <thead>
                <tr class="error-background text-center">
                  <th scope="col">Row</th>
                  <th scope="col">Column</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of importInvalid.table; index as i">
                  <td class="text-center">{{ item.row }}</td>
                  <td>{{ item.column }}</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" *ngIf="importInvalid && importInvalid.message">
            {{ importInvalid.message }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="importInvalidModel.hide()">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
