<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-marketing"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="isRequestViewMode && (rewardRequestView$ | async)?.status === 'W' && hasEditRewardPermission"
    (click)="onCancelRewardRequest()"
  >
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>

  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="isRequestViewMode && (rewardRequestView$ | async)?.status === 'D' && hasEditRewardPermission"
    (click)="onDeleteRewardRequest()"
  >
    <em class="icon-delete" title="Delete"></em>
    Delete
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form" [formGroup]="rewardForm">
  <div class="info-header-container">
    <div class="info-header-item mr-4" *ngIf="(rewardRequestView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (rewardRequestView$ | async)?.requestNo }}</span>
      </div>
    </div>

    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="pageMode.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('D')"
          class="request-status"
        >
          {{ 'REWARD.STATUS.D' | translate }}</span
        >
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus(status)" class="request-status">
            {{ 'REWARD.STATUS.' + status | translate | titlecase }}</span
          >
        </ng-template>
      </div>
    </div>
  </div>
  <div>
    <div class="row section-header">
      <span>Reward Details</span>
    </div>
    <app-reward-details
      #rewardDetails
      [submitted]="submitted"
      [saved]="saved"
      [parentForm]="rewardForm"
      [mode]="data.mode"
      (changeConditionAndOffer)="onChangeConditionAndOffer($event)"
    ></app-reward-details>
    <ng-container *ngIf="this.conditionPurchase?.condition && this.conditionPurchase?.offer">
      <hr class="section-divider" />
      <div class="row section-header">
        <span *ngIf="this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT">Purchase Tier</span>
        <span *ngIf="this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY">Purchase Item</span>
        <span *ngIf="this.conditionPurchase?.condition === this.conditionEnum.SUPPLIER_COUPON">Offer Coupon</span>
      </div>
      <!--      Purchase Amount Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-purchase-amount-coupon
          #purchaseAmountCoupon
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [conditionPurchase]="conditionPurchase"
          [isEditFirst]="isEditFirst"
        ></app-purchase-amount-coupon>
      </ng-container>
      <!--      Purchase Amount Goods   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_AMOUNT &&
          this.conditionPurchase?.offer === this.offerEnum.GOODS
        "
      >
        <app-purchase-amount-goods
          #purchaseAmountGoods
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-purchase-amount-goods>
      </ng-container>
      <!--      Purchase Quantity Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-purchase-quantity-coupon
          #purchaseQuantityCoupon
          [parentForm]="rewardForm"
          [maximumRow]="10"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-purchase-quantity-coupon>
      </ng-container>
      <!--      Purchase Quantity Goods   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.PURCHASE_QUANTITY &&
          this.conditionPurchase?.offer === this.offerEnum.GOODS
        "
      >
        <app-purchase-quantity-goods
          #purchaseQuantityGoods
          [parentForm]="rewardForm"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [conditionPurchase]="conditionPurchase"
          [isEditFirst]="isEditFirst"
        ></app-purchase-quantity-goods>
      </ng-container>
      <!--      Supplier Coupon   -->
      <ng-container
        *ngIf="
          this.conditionPurchase?.condition === this.conditionEnum.SUPPLIER_COUPON &&
          this.conditionPurchase?.offer === this.offerEnum.COUPON
        "
      >
        <app-supplier-coupon
          #supplierCoupon
          [parentForm]="rewardForm"
          [maximumRow]="1"
          [submitted]="submitted"
          [saved]="saved"
          [mode]="data.mode"
          [isEditFirst]="isEditFirst"
          [conditionPurchase]="conditionPurchase"
        ></app-supplier-coupon>
      </ng-container>

      <hr class="section-divider" />
      <div class="row section-header">
        <span>Select Store</span>
      </div>
      <app-select-store
        #selectStore
        [parentForm]="rewardForm"
        [submitted]="submitted"
        [saved]="saved"
        [mode]="data.mode"
        [conditionPurchase]="conditionPurchase"
      ></app-select-store>
    </ng-container>
  </div>
  <!--footer-->
  <div class="fixed-row-bottom form-row">
    <div class="float-left">
      <button
        *ngIf="
          (isRequestEditMode || isRequestCreateMode) &&
          (rewardRequestView$ | async)?.status !== 'W' &&
          hasEditRewardPermission
        "
        type="button"
        id="btn-save"
        class="btn btn-secondary"
        (click)="onSave()"
        appPreventDoubleClick
      >
        Save
      </button>
    </div>
    <div class="float-right">
      <button
        *ngIf="(isRequestEditMode || isRequestCreateMode) && hasEditRewardPermission"
        type="button"
        id="btn-cancel-request"
        class="btn btn-standard mr-2"
        (click)="onExit()"
      >
        Cancel
      </button>
      <button
        *ngIf="(isRequestEditMode || isRequestCreateMode) && hasEditRewardPermission"
        type="button"
        id="btn-submit"
        class="btn btn-primary"
        appPreventDoubleClick
        (click)="onSubmit()"
      >
        Submit
      </button>
      <button
        *ngIf="
          isRequestViewMode &&
          hasEditRewardPermission &&
          ((rewardRequestView$ | async)?.status === 'D' || (rewardRequestView$ | async)?.status === 'W')
        "
        type="button"
        id="btn-request-edit"
        class="btn btn-primary  mr-3"
        (click)="toggleToEditMode()"
      >
        Edit
      </button>

      <button
        *ngIf="isRequestViewMode && (rewardRequestView$ | async)?.status === 'W' && hasApproveRewardPermission"
        type="button"
        class="btn btn-special-reject mr-3"
        id="btn-reject"
        (click)="reject()"
        appPreventDoubleClick
      >
        Reject
      </button>
      <button
        *ngIf="isRequestViewMode && (rewardRequestView$ | async)?.status === 'W' && hasApproveRewardPermission"
        type="button"
        class="btn btn-special-approve mr-3"
        id="btn-approve"
        (click)="approve()"
        appPreventDoubleClick
      >
        Approve
      </button>
    </div>
  </div>
  <!--End footer-->
</div>
