import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { RewardRequestResponse, RewardValidateResponse } from '../../models/reward.model';
import { RewardRequestAction, RewardRequestActionTypes } from '../actions/reward-request.actions';

export interface RewardCreateState extends EntityState<RewardRequestResponse> {
  isLoading: boolean;
  isSubmitSuccess: boolean;
  requestRewardData: RewardRequestResponse;
  validateData: RewardValidateResponse;
  submitError: ErrorResponse;
}

export const adapter: EntityAdapter<RewardRequestResponse> = createEntityAdapter<RewardRequestResponse>();

export const initialRewardCreateState: RewardCreateState = adapter.getInitialState({
  isLoading: false,
  isSubmitSuccess: false,
  requestRewardData: null,
  validateData: null,
  submitError: null
});

export function RewardCreateReducers(
  state = initialRewardCreateState,
  action: RewardRequestAction
): RewardCreateState {
  switch (action.type) {
    case RewardRequestActionTypes.REWARD_REQUEST_SAVE_RESPONSE:
      return {
        ...state,
        isSubmitSuccess: true,
        requestRewardData: action.payload
      };
    case RewardRequestActionTypes.REWARD_REQUEST_SAVE_RESET:
      return initialRewardCreateState;
    case RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESPONSE:
      return {
        ...state,
        validateData: action.payload
      };
    case RewardRequestActionTypes.REWARD_REQUEST_SUBMIT_ERROR:
      return {
        ...state,
        submitError: action.payload
      };
    case RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESET:
      return {
        ...state,
        validateData: null
      };
    case RewardRequestActionTypes.REWARD_REQUEST_VALIDATE_RESET_AMOUNT_COUPON:
      return {
        ...state,
        validateData: null
      };
    default: {
      return state;
    }
  }
}
