export enum ProductTypeEnum {
  INVENTORY = 'INVENTORY',
  FIX_ASSET = 'FIX_ASSET',
  STORE_USE = 'STORE_USE',
  ALL = ''
}

export enum ProductRestrictEnum {
  YES = 'YES',
  NO = 'NO'
}

export enum ProductItemErrorEnum {
  INVALID_BARCODE = 'INVALID_BARCODE',
  INACTIVE_BARCODE = 'INACTIVE_BARCODE',
  STATUS_IS_DELISTED = 'STATUS_IS_DELISTED',
  RESTRICT_ITEM = 'RESTRICT_ITEM',
  DIFFERENT_PRICE = 'DIFFERENT_PRICE',
  NOT_ALLOW_INVENTORY = 'NOT_ALLOW_INVENTORY',
  NOT_ALLOW_FIX_ASSET = 'NOT_ALLOW_FIX_ASSET',
  NOT_ALLOW_STORE_USE = 'NOT_ALLOW_STORE_USE',
  NOT_ALLOW_ZERO = 'NOT_ALLOW_ZERO',
  REWARD_PURCHASE_ITEM_DUPLICATE = 'REWARD_PURCHASE_ITEM_DUPLICATE'
}
