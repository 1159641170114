<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-marketing"></em>
    <h4>Reward</h4>
  </div>
  <app-tab [routeLinkTabs]="listRoute"></app-tab>
  <div class="container-fluid summary">
    <div class="row row-summary">
      <div class="col-md-3 mb-4 mt-4 mr-4 information-box first-box">
        <div class="d-flex flex-row">
          <div class="p-0">
            <em class="icon-activate"></em>
          </div>
          <div class="p-2 title">Active Reward</div>
          <div class="ml-auto  p-2 amount green">
            {{ (activeSummaryState$ | async)?.activeRewardTotal | numberFormatDisplay: 0 }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4 mt-4 mr-4 information-box">
        <div class="d-flex flex-row">
          <div class="p-0">
            <em class="icon-approve-all"></em>
          </div>
          <div class="p-2 title">Always Offer</div>
          <div class="ml-auto  p-2 amount red">
            {{ (activeSummaryState$ | async)?.alwaysOfferTrueTotal | numberFormatDisplay: 0 }}
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4 mt-4 mr-4 information-box">
        <div class="d-flex flex-row">
          <div class="p-0">
            <em class="icon-approve-select"></em>
          </div>
          <div class="p-2 title">Selectable Offer</div>
          <div class="ml-auto  p-2 amount red">
            {{ (activeSummaryState$ | async)?.selectableOfferTotal | numberFormatDisplay: 0 }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(activeListState$ | async)?.totalElements > 0">
    <table id="rewardActiveTable" class="table table-striped table-bordered w-100 table-hover">
      <thead>
        <tr>
          <th class="text-center w-2">No</th>
          <th class="text-center w-5">Reward No.</th>
          <th class="text-center w-25">Reward Name</th>
          <th class="text-center w-5">No. of Store</th>
          <th class="text-center w-5">Offer from Date</th>
          <th class="text-center w-5">Offer to Date</th>
          <th class="text-center w-5">Always Offer</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of resultActiveItemList$ | async; index as i">
          <td (click)="goToView(item)" class="text-center align-top">
            {{ (activeListState$ | async)?.criteriaObject.page * pageSize + (i + 1) }}
          </td>
          <td (click)="goToView(item)" class="text-left align-top">
            {{ item.rewardNo }}
          </td>
          <td (click)="goToView(item)" class="text-left align-top">
            {{ item.rewardName }}
          </td>
          <td (click)="goToView(item)" class="text-center align-top">
            {{ item.noOfStore | numberFormatDisplay: 0 }}
          </td>
          <td (click)="goToView(item)" class="text-center align-top">
            {{ item.offerFromDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
          </td>
          <td (click)="goToView(item)" class="text-center align-top">
            {{ item.offerToDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
          </td>
          <td (click)="goToView(item)" class="text-center align-top">
            <span class="icon-check-mini icon-alwaysOffer" *ngIf="item.alwaysOffer == true; else dash"></span>
            <ng-template #dash>
              <span>-</span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="activeListState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
  <ng-container *ngIf="(activeListState$ | async)?.totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="No data" />
      </div>
    </div>
  </ng-container>
</div>
