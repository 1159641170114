import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { UpdateOrderPolicyComponent } from '../../../shared/components/td-store/order-policy/update-order-policy/update-order-policy.component';
import { StoreProfileComponent } from '../../../shared/components/td-store/store-profile/store-profile.component';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { NewRequestStatusEnum, RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ErrorResponse, StoreProfile, StoreRequestTemplate, StoreViewResponse } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { StoreService } from '../../../shared/services/store.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import {
  StoreCreateResetAction,
  StoreCreateSubmitRequestAction
} from '../../../shared/store/actions/store-request.actions';
import { ResetStoreSelected, StoreByIdRequestAction } from '../../../shared/store/actions/store.actions';
import { StoreCreateResponseState } from '../../../shared/store/reducers/store-create.reducers';
import { selectStore } from '../../../shared/store/selectors/store-create.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../shared/utils/date-util';
import { getFileId, getMultipleFileId } from '../../../shared/utils/get-fileId-util';
import { getSelectByPage } from '../../../shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-view-store',
  templateUrl: './view-store.component.html',
  styleUrls: ['./view-store.component.scss']
})
export class ViewStoreComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('storeProfile', { static: false }) storeProfile: StoreProfileComponent;
  @Input() data: { title: string; mode: RequestPageModesEnum; storeNo?: string; merchant?: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  private localStore: Observable<any>;
  private readonly type: RequestTypeEnum = RequestTypeEnum.EDIT;
  private readonly step: RequestStepEnum = RequestStepEnum.EDIT_PROFILE;
  private readonly page: TDStorePage = TDStorePage.STORE_EDIT;

  public storeForm: FormGroup;
  public storeView$: Observable<StoreViewResponse>;
  public status: RequestStatusEnum;
  public submitted: boolean;
  public version: number;
  public requestStatus = NewRequestStatusEnum;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected storeRequestService: StoreRequestService,
    protected readonly translate: TranslateService,
    protected bsModalService: BsModalService,
    protected cdRef: ChangeDetectorRef,
    protected storeService: StoreService
  ) {
    super(store, modalService, true);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetStoreSelected());
    this.store.dispatch(new StoreCreateResetAction());
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.store.dispatch(
      new StoreByIdRequestAction({
        merchant: { merchant: this.data.merchant },
        storeNo: { storeNo: this.data.storeNo }
      })
    );

    this.storeView$ = this.localStore.pipe(select(getSelectByPage(TDStorePage.STORE_EDIT)));
    this.storeView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          this.version = value.version;
        })
      )
      .subscribe();

    this.initControl();

    if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
      this.editStore();
    }
  }

  get merchantPage() {
    return TDStorePage.STORE_EDIT;
  }

  get requestPageModesEnum() {
    return RequestPageModesEnum;
  }

  get profile() {
    return this.storeForm.get('storeProfile')['controls'][0] as FormGroup;
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  initControl() {
    this.storeForm = this.fb.group({});
  }

  onCancel() {
    if (this.storeForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.storeForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const storeRequestTemplate = this.prepareStoreRequest();
          this.store.dispatch(new StoreCreateSubmitRequestAction(storeRequestTemplate));
        }
      });
  }

  prepareStoreRequest(): StoreRequestTemplate {
    const storeRequestTemplate = new StoreRequestTemplate();
    const formData = this.storeForm.getRawValue();
    const store = formData.storeProfile[0];
    const phone: { internationalNumber; nationalNumber } = store.phone;
    let storeProfileData = new StoreProfile();

    this.storeView$
      .pipe(
        filter(value => value !== null),
        tap(value => {
          storeRequestTemplate.step = this.step;
          storeRequestTemplate.status = this.status;
          storeRequestTemplate.type = this.type;

          if (value.merchantInfo) {
            storeProfileData = value.merchantInfo.storeProfile[0];
          }
        })
      )
      .subscribe();

    storeRequestTemplate.merchantNo = this.data.merchant;
    storeRequestTemplate.storeProfile = {
      ...store,
      openDate: formatDateStartOfDay(store.openDate, env.dateISO8601),
      storeNo: this.data.storeNo,
      countryCode:
        phone && phone.internationalNumber
          ? phone.internationalNumber.split(' ')[0]
          : storeProfileData && storeProfileData.countryCode,
      phone:
        phone && phone.nationalNumber
          ? phone.nationalNumber.replace(/ /g, '')
          : storeProfileData && storeProfileData.phone,
      rentalFee:
        !this.profile.controls.rentalFee.disabled && store.rentalFee
          ? { amount: store.rentalFee, currency: 'THB' }
          : null,
      poiType: store.poiType && store.poiType.join(','),
      storeFrontPicture: store.storePicFront && getMultipleFileId(store.storePicFront),
      storeFrontVideo: getFileId(store.storeVDOFront),
      titleDeed: getFileId(store.titleDeed),
      houseRegistrationPicture: getFileId(store.houseRegistrationPicture),
      idCardPicture: getFileId(store.idCardPicture),
      consentLetterPicture: getFileId(store.consentLetterPicture),
      attachmentPicture: store.attachmentPicture && getMultipleFileId(store.attachmentPicture)
    };
    storeRequestTemplate.orderSchedule = formData.orderSchedule.orderScheduleList[0];

    return storeRequestTemplate;
  }

  onUpdateOrderSchedule() {
    const initialState = {
      parentForm: this.storeForm,
      mode: RequestPageModesEnum.REQUEST_EDIT,
      page: TDStorePage.STORE_EDIT_ORDER_SCHEDULE,
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      version: this.version
    };

    this.modalService.show(UpdateOrderPolicyComponent, {
      class: 'update-order-schedule',
      initialState
    });
  }

  editStore() {
    this.status = RequestStatusEnum.DRAFT;

    this.localStore
      .pipe(
        select(selectStore),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: StoreCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal('The request has been sent to approver.');
        } else {
          this.alertErrorModal(value.result.errorResponse);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertModal(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onTriggerEdit() {
    this.storeRequestService
      .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, this.data.storeNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        res => {
          if (res.body.allowToEdit) {
            this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

            if (
              this.tdStoreWorkflowUtil.canEditSection(
                this.type,
                this.page,
                this.step,
                RequestSectionEnum.STORE_PROFILE
              )
            ) {
              this.data.title = 'Edit Store';
              this.editStore();

              this.storeProfile.toggleEditStoreProfile();
            }
          } else {
            this.alertModal('Alert', 'Another request is awaiting approval.');
          }
        },
        error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      );
  }

  onActivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to activate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeService
            .activateStore({ storeNo })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The store has been activated.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  onDeactivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to deactivate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeRequestService
            .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, storeNo)
            .pipe(
              untilComponentDestroyed(this),
              filter(v => Boolean(v))
            )
            .subscribe(
              res => {
                if (res.body.allowToEdit) {
                  this.deactivateStore(storeNo);
                } else {
                  this.alertModal('Failed', 'The request is awaiting approval.');
                }
              },
              error => {
                this.store.dispatch(new LayoutActionLoadError(error));
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deactivateStore(storeNo) {
    this.storeService
      .deactivateStore({ storeNo })
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        () => {
          this.alertSuccessModal('The store has been deactivated.');
        },
        error => {
          this.alertErrorModal(error.error);
        }
      );
  }

  hasEditPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, RequestStatusEnum.DRAFT);
  }

  hasSubmitPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasAtLeastOnePermission() {
    return this.hasEditPermission() || this.hasSubmitPermission();
  }

  doAfterVersionAlertModal() {}
}
