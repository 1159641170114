import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { environment, environment as env } from '../../../../environments/environment';
import { beforeTodayValidator } from '../../../shared/custom-validators/before-today-validator';
import { isZeroValidator } from '../../../shared/custom-validators/is-zero-validator';
import { RewardCondition, RewardOffer, RewardPageModes } from '../../../shared/enum/reward.enum';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { ConditionPurchase, RewardRequestResponse } from '../../../shared/models/reward.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { SupplierService } from '../../../shared/services/supplier.service';
import {
  ResetPurchaseTierRewardByIdRequestSelected,
  ResetRewardAmountCouponRequestValidateAction
} from '../../../shared/store/actions/reward-request.actions';
import { selectRewardRequest } from '../../../shared/store/selectors/reward-request.selectors';
import { selectReward } from '../../../shared/store/selectors/reward.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { convertUtcToBkk, getDateFromString } from '../../../shared/utils/date-util';

@Component({
  selector: 'app-reward-details',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss']
})
export class RewardDetailsComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() mode: RewardPageModes;

  @Output() changeConditionAndOffer: EventEmitter<ConditionPurchase> = new EventEmitter<ConditionPurchase>();

  public bsDateConfig;
  public bsDateRedeemToDateConfig;
  public environment;
  public conditionSelect = filterDropdown.rewardCondition;
  public offerSelect = filterDropdown.rewardOffer;
  public alwaysOfferSelect = filterDropdown.rewardAlwaysOffer;
  public offerMaxDate: Date;
  public offerMinDate: Date;
  public redeemMaxDate: Date;
  public redeemMinDate: Date;
  public currentDate: Date;
  public redeemCurrentDate: Date;
  public rewardRequestView$: Observable<RewardRequestResponse>;
  private localStore: Observable<any>;
  private firstChange = true;
  private offerToDate = null;
  private offerFromDate = null;
  public isRequestViewMode = false;

  public supplierList: Observable<SupplierSearch[]>;
  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();

  constructor(
    private readonly fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected supplierService: SupplierService
  ) {
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: environment.dateFormat,
      showWeekNumbers: false,
      useUtc: false,
      adaptivePosition: true
    } as BsDatepickerConfig;

    this.bsDateRedeemToDateConfig = {
      containerClass: 'theme-dark-blue move-left',
      dateInputFormat: environment.dateFormat,
      showWeekNumbers: false,
      useUtc: false,
      adaptivePosition: true
    } as BsDatepickerConfig;
    this.environment = environment;
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parentForm.addControl('details', this.createForm());

    if (this.mode === RewardPageModes.REQUEST_CREATE) {
      this.redeemCurrentDate = new Date();
      this.currentDate = new Date();
    } else {
      this.formDetails.disable();
      this.setDetailsValue();
    }

    this.loadSupplier('');
  }

  setEditMode() {
    this.formDetails.enable();
    this.checkSupplierCondition();
  }

  setDetailsValue() {
    if (this.mode === RewardPageModes.VIEW) {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectReward),
        filter(data => data !== null)
      );
    } else {
      this.rewardRequestView$ = this.localStore.pipe(
        select(selectRewardRequest),
        filter(data => data !== null)
      );
    }

    this.rewardRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response),
        take(1)
      )
      .subscribe(req => {
        if (req) {
          const detail = {
            ...req.details,
            offerFromDate: this.setStringToDate(req.details.offerFromDate),
            offerToDate: this.setStringToDate(req.details.offerToDate),
            redeemFromDate: this.setStringToDate(req.details.redeemFromDate),
            redeemToDate: this.setStringToDate(req.details.redeemToDate)
          };

          this.offerFromDate = detail.offerFromDate;
          this.offerToDate = detail.offerToDate;

          const offerFrom = detail.offerFromDate;
          if (offerFrom && moment(offerFrom).isBefore(new Date(), 'day')) {
            this.currentDate = offerFrom;
          } else {
            this.currentDate = new Date();
          }

          const redeemFromDate = detail.redeemFromDate;
          if (redeemFromDate && moment(redeemFromDate).isBefore(new Date(), 'day')) {
            this.redeemCurrentDate = redeemFromDate;
          } else {
            this.redeemCurrentDate = new Date();
          }

          const redeemToDate = detail.redeemToDate;
          if (redeemToDate) {
            this.redeemMaxDate = redeemToDate;
          }

          if (redeemToDate && moment(redeemToDate).isBefore(new Date(), 'day')) {
            this.redeemMinDate = redeemToDate;
          }

          this.formDetails.patchValue(detail);

          if (req.details.supplier) {
            const branchNo = req.details.supplier.branchNo ? req.details.supplier.branchNo : '';
            const name = `${req.details.supplier.supplierCode} ${req.details.supplier.supplierName} (${branchNo})`;
            const supp = {
              ...req.details.supplier,
              supplierNameDisplay: name
            };
            this.formDetails.controls['supplier'].setValue(supp);
          }
        }
      });
  }

  setStringToDate(str: string) {
    return str ? getDateFromString(convertUtcToBkk(str, env.dateFormat), env.dateFormat) : null;
  }
  createForm() {
    return this.fb.group({
      rewardName: this.validateRequired,
      condition: this.validateRequired,
      offer: this.validateRequired,
      supplier: [{ value: null, disabled: false }, this.validateControls.supplier],
      ref1: [{ value: null, disabled: false }, this.validateControls.ref1],
      ref2: [{ value: null, disabled: false }],
      alwaysOffer: [{ value: false, disabled: false }],
      offerFromDate: [{ value: null, disabled: false }, this.validateControls.offerFromDate],
      offerToDate: this.validateRequired,
      unlimitedPerBill: [{ value: false, disabled: false }],
      limitPerBill: [{ value: null, disabled: false }, this.validateControls.limitPerBill],
      redeemFromDate: [{ value: null, disabled: false }, this.validateControls.redeemFromDate],
      redeemToDate: [{ value: null, disabled: false }, this.validateControls.redeemToDate]
    });
  }

  onChangeUnlimited(event): void {
    this.formDetails.controls['limitPerBill'].reset(null);
    if (event.target.checked) {
      this.formDetails.controls['limitPerBill'].disable();
    } else {
      this.formDetails.controls['limitPerBill'].setValidators(this.validateControls.limitPerBill);
      this.formDetails.controls['limitPerBill'].enable();
    }

    this.formDetails.controls['limitPerBill'].updateValueAndValidity();
  }

  onchangeCondition(event): void {
    if (event.value === this.conditionEnum.SUPPLIER_COUPON) {
      this.offerSelect = filterDropdown.rewardOffer.filter(v => v.value === this.offerEnum.COUPON);

      if (this.formDetails.controls['offer'].value !== this.offerEnum.COUPON) {
        this.formDetails.controls['offer'].setValue(null);
      }
    } else {
      this.offerSelect = filterDropdown.rewardOffer;
      this.formDetails.controls['alwaysOffer'].setValue(false);
    }

    this.onchangeConditionAndOffer();
  }
  onchangeConditionAndOffer(): void {
    const condition = this.formDetails.controls.condition ? this.formDetails.controls.condition.value : null;
    const offer = this.formDetails.controls.offer ? this.formDetails.controls.offer.value : null;
    const value = {
      condition: condition,
      offer: offer
    } as ConditionPurchase;

    if (this.mode === RewardPageModes.REQUEST_EDIT && this.firstChange) {
      this.store.dispatch(new ResetPurchaseTierRewardByIdRequestSelected());
      this.firstChange = false;
    }

    this.checkSupplierCondition();

    this.changeConditionAndOffer.emit(value);
  }

  onChangeOfferFromDate(value): void {
    if (this.displaySupplier()) {
      return;
    }

    if (value && !isNaN(value.getTime())) {
      this.offerMinDate = new Date(value);
      this.offerFromDate = new Date(value);

      this.formDetails.controls['redeemFromDate'].updateValueAndValidity({ onlySelf: true });
    } else if (this.mode === RewardPageModes.REQUEST_CREATE) {
      this.offerMinDate = new Date();
    }

    if (this.submitted) {
      this.store.dispatch(new ResetRewardAmountCouponRequestValidateAction());
    }
  }
  onChangeRedeemDateFrom(value): void {
    if (value && !isNaN(value.getTime())) {
      this.redeemMinDate = new Date(value);

      if (this.displaySupplier()) {
        const d = new Date(value);
        this.formDetails.controls['offerFromDate'].setValue(d);
        this.offerFromDate = d;
      }
    } else if (this.mode === RewardPageModes.REQUEST_CREATE) {
      this.redeemMinDate = new Date();
    }
  }

  onChangeOfferToDate(value): void {
    if (this.submitted) {
      this.store.dispatch(new ResetRewardAmountCouponRequestValidateAction());
    }

    if (this.displaySupplier()) {
      return;
    }

    if (value && !isNaN(value.getTime())) {
      this.offerMaxDate = new Date(value);
      this.offerToDate = new Date(value);

      this.formDetails.controls['redeemToDate'].updateValueAndValidity({ onlySelf: true });
    } else {
      this.offerMaxDate = new Date();
      this.offerMaxDate.setDate(this.offerMaxDate.getDate() + 365);
    }
  }

  onChangeRedeemToDate(value): void {
    if (value && !isNaN(value.getTime())) {
      this.redeemMaxDate = new Date(value);

      if (this.displaySupplier()) {
        const d = new Date(value);
        this.formDetails.controls['offerToDate'].setValue(d);
        this.offerToDate = d;
      }
    } else {
      this.redeemMaxDate = new Date();
      this.redeemMaxDate.setDate(this.redeemMaxDate.getDate() + 365);
    }
  }

  validateRedeemFrom(value) {
    if (!value) {
      return null;
    }

    const offerFrom = this.formDetails.controls['offerFromDate'].value;
    if (offerFrom && moment(value).isBefore(offerFrom, 'day')) {
      return { beforeToday: true };
    }

    return null;
  }

  displaySupplier(): boolean {
    const condition = this.formDetails.controls.condition ? this.formDetails.controls.condition.value : null;
    const offer = this.formDetails.controls.offer ? this.formDetails.controls.offer.value : null;

    return condition === this.conditionEnum.SUPPLIER_COUPON && offer === this.offerEnum.COUPON;
  }

  checkSupplierCondition() {
    if (this.displaySupplier()) {
      this.formDetails.controls['alwaysOffer'].setValue(true);
      this.formDetails.controls['limitPerBill'].setValue(1);
      this.formDetails.controls['unlimitedPerBill'].setValue(false);
      this.formDetails.controls['alwaysOffer'].disable();
      this.formDetails.controls['offerFromDate'].disable();
      this.formDetails.controls['offerToDate'].disable();
      this.formDetails.controls['limitPerBill'].disable();
      this.formDetails.controls['unlimitedPerBill'].disable();

      this.formDetails.controls['supplier'].enable();
      this.formDetails.controls['ref1'].enable();
      this.formDetails.controls['ref2'].enable();

      this.formDetails.controls['offerFromDate'].setValue(this.formDetails.controls['redeemFromDate'].value);
      this.formDetails.controls['offerToDate'].setValue(this.formDetails.controls['redeemToDate'].value);

      this.offerFromDate = this.formDetails.controls['redeemFromDate'].value;
      this.offerToDate = this.formDetails.controls['redeemToDate'].value;

      this.formDetails.controls['redeemFromDate'].setValidators(this.validateControls.redeemFromDateSupplier);
      this.formDetails.controls['redeemToDate'].setValidators(this.validateControls.redeemToDateSupplier);
    } else {
      this.formDetails.controls['alwaysOffer'].enable();
      this.formDetails.controls['offerFromDate'].enable();
      this.formDetails.controls['offerToDate'].enable();
      this.formDetails.controls['limitPerBill'].enable();
      if (this.formDetails.controls['unlimitedPerBill'].value === true) {
        this.formDetails.controls['limitPerBill'].disable();
      } else {
        this.formDetails.controls['unlimitedPerBill'].enable();
      }

      this.formDetails.controls['supplier'].disable();
      this.formDetails.controls['ref1'].disable();
      this.formDetails.controls['ref2'].disable();

      this.formDetails.controls['supplier'].setValue(null);
      this.formDetails.controls['ref1'].setValue(null);
      this.formDetails.controls['ref2'].setValue(null);

      this.formDetails.controls['redeemFromDate'].setValidators(this.validateControls.redeemFromDate);
      this.formDetails.controls['redeemToDate'].setValidators(this.validateControls.redeemToDate);
    }

    this.formDetails.updateValueAndValidity({ onlySelf: true });
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  get conditionOffer() {
    const condition = this.formDetails.controls.condition ? this.formDetails.controls.condition.value : null;
    const offer = this.formDetails.controls.offer ? this.formDetails.controls.offer.value : null;
    return {
      condition: condition,
      offer: offer
    } as ConditionPurchase;
  }

  get beforeOfferFromDateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const offerFrom = this.offerFromDate;
      if (offerFrom && moment(control.value).isBefore(offerFrom, 'day')) {
        return { beforeOfferFrom: true };
      }

      return null;
    };
  }

  get beforeOfferToDateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const offerTo = this.offerToDate;
      if (offerTo && moment(control.value).isBefore(offerTo, 'day')) {
        return { beforeOfferTo: true };
      }

      return null;
    };
  }

  get conditionEnum() {
    return RewardCondition;
  }
  get offerEnum() {
    return RewardOffer;
  }

  get validateControls() {
    return {
      limitPerBill: [Validators.required, isZeroValidator()],
      supplier: [Validators.required],
      ref1: [Validators.required],
      offerFromDate: [Validators.required, beforeTodayValidator()],
      redeemFromDate: [Validators.required, this.beforeOfferFromDateValidator],
      redeemToDate: [Validators.required, this.beforeOfferToDateValidator],
      redeemFromDateSupplier: [Validators.required, beforeTodayValidator()],
      redeemToDateSupplier: [Validators.required],
      dateSupplier: [Validators.required]
    };
  }

  get validateRequired() {
    return [{ value: null, disabled: false }, Validators.required];
  }

  get formDetails() {
    return this.parentForm.get('details') as FormGroup;
  }

  ngOnDestroy(): void {}
}
