import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/base.environment';
import { BaseComponent } from '../../../../base/base.component';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { Z8ParameterContent } from '../../../../shared/models/z8-parameter.model';
import {
  Z8ConditionsListRequestAction,
  Z8ConditionsListResetAction
} from '../../../../shared/store/actions/z8-conditions.actions';
import { Z8ConditionsState } from '../../../../shared/store/reducers/z8-conditions.reducers';
import {
  selectAllZ8ConditionsList,
  selectZ8ConditionsList,
  selectZ8ConditionsListCriteria
} from '../../../../shared/store/selectors/z8-conditions.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { dateStringToTagCriteria, formatDate } from '../../../../shared/utils/date-util';
import { ExportStoreStockPlanningComponent } from '../../components/export-store-stock-planning/export-store-stock-planning.component';

@Component({
  selector: 'app-view-parameter-list',
  templateUrl: './view-parameter-list.component.html',
  styleUrls: ['./view-parameter-list.component.scss']
})
export class ViewParameterListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() viewOnly: boolean;
  @Input() parameterName: string;
  @Input() guid: string;

  resultZ8ConditionsList$: Observable<Z8ParameterContent[]>;
  listZ8ConditionsState$: Observable<Z8ConditionsState>;

  private localStore: Observable<any>;
  public criteriaObject: any;
  public z8ParameterStatusList = filterDropdown.z8ParameterStatusFilter;
  public searchForm: FormGroup;

  public isShowAdvanceSearch: boolean;
  public dateFormat = environment.dateFormat;
  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;

  public currentPage: number;
  public pageSize: number;
  public validateObj = {};
  public parameterFactor = filterDropdown.z8ParameterFactor;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(private readonly fb: FormBuilder, public modalService: BsModalService, public store: Store<AppStates>) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.setInitialCriteriaObject();
    this.initialData();
    this.createForm();
    this.setInitialValue();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectZ8ConditionsListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.search();
  }

  ngOnDestroy() {
    this.store.dispatch(new Z8ConditionsListResetAction());
  }

  setInitialValue() {
    this.currentPage = 1;
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = null;
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;

    this.criteriaObject = {
      page: 0,
      size: 20,
      guid: this.guid,
      sortBy: this.viewOnly ? 'no' : null,
      status: this.viewOnly ? 'all' : null
    };
  }

  initialData() {
    this.resultZ8ConditionsList$ = this.store.select(selectAllZ8ConditionsList);
    this.listZ8ConditionsState$ = this.store.select(selectZ8ConditionsList);
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      startDate: [null],
      endDate: [null],
      status: [this.z8ParameterStatusList[0].value]
    });
  }

  openExport() {
    this.modalService.show(ExportStoreStockPlanningComponent, {
      backdrop: 'static',
      initialState: {
        parameterName: this.parameterName
      }
    });
  }

  onSearchSubmit() {}

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search();
  }

  search() {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new Z8ConditionsListRequestAction(this.criteriaObject));
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;

    const startDate = dateStringToTagCriteria(this.criteriaObject.startDate);
    const endDate = dateStringToTagCriteria(this.criteriaObject.endDate);

    if (startDate || endDate) {
      if (startDate && endDate) {
        this.dateStringTag = `Date`;
        this.dateTag = `"${startDate} - ${endDate}"`;
      } else if (startDate) {
        this.dateStringTag = `Start Date`;
        this.dateTag = `"${startDate}"`;
      } else if (endDate) {
        this.dateStringTag = `End Date`;
        this.dateTag = `"${endDate}"`;
      }
    }
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.search();
    }
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.search();
  }

  clearFilterDate() {
    this.searchForm.controls['startDate'].reset();
    this.searchForm.controls['endDate'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startDate: null,
      endDate: null,
      page: 0
    };
    this.search();
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startDate'].reset();
    this.searchForm.controls['endDate'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startDate: null,
      endDate: null,
      page: 0
    };
    this.search();
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = null;
    }
  }

  isApplySearchFilter() {
    return Object.values(this.searchForm.value).some(x => x !== null && x !== '');
  }

  getColorStatus(status: string): string {
    return status.toLowerCase();
  }

  onAdvanceSubmit() {
    const dateFormat = environment.dateFormat;
    const formValue = this.searchForm.value;
    let startDate = formValue.startDate;
    let endDate = formValue.endDate;

    if (!startDate && !endDate) {
      return;
    }

    this.setFirstPage();

    if (startDate && !isNaN(startDate.getTime())) {
      startDate = formatDate(startDate, dateFormat);
    } else {
      startDate = null;
    }

    if (endDate && !isNaN(endDate.getTime())) {
      endDate = formatDate(endDate, dateFormat);
    } else {
      endDate = null;
    }

    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startDate,
      endDate
    };
    this.search();
    this.isShowAdvanceSearch = false;
  }

  getParameterFactor() {
    const defaultParameter = {
      parameterName: 'Default',
      format: '0,0',
      minValue: 0,
      maxValue: 99999,
      decimals: 0,
      maxLength: 5,
      allowNegative: false
    };

    const paramFactor = this.parameterFactor
      ? this.parameterFactor.find(item => item.parameterName === this.parameterName)
      : null;

    return paramFactor ? paramFactor : defaultParameter;
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.search();
  }

  doAfterVersionAlertModal() {}
}
