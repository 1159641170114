import { Injectable, Type } from '@angular/core';
import { RequestPageModesEnum } from '../enum/request-step.enum';
import { ChildItem } from '../layouts/modals/full-modal/child-item';
import { MyTaskLinkContent, PortalModule } from '../models';

@Injectable()
export class ReferenceModuleUtil {
  public static InitialState(item: MyTaskLinkContent, component: Type<any>, title: string) {
    switch (item.module) {
      case PortalModule.MERCHANT:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              title,
              mode: RequestPageModesEnum.REQUEST_VIEW,
              merchant: item.referenceID
            },
            false
          )
        };
      case PortalModule.ORDER:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              title,
              orderNo: item.referenceNo
            },
            false
          )
        };
      case PortalModule.RECEIVE_ORDER:
        return {
          title: null,
          childItem: new ChildItem(
            component,
            {
              title,
              receiveOrderNo: item.referenceNo
            },
            false
          )
        };
      default:
        return null;
    }
  }
}
