import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-click-to-copy',
  templateUrl: './click-to-copy.component.html',
  styleUrls: ['./click-to-copy.component.scss']
})
export class ClickToCopyComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit() {}

  copyToClipboard(event) {
    if (!this.isAllowToCopy()) {
      return;
    }

    event.stopPropagation();

    const clipboard = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.text);
      e.preventDefault();
      document.removeEventListener('copy', clipboard);
    };

    document.addEventListener('copy', clipboard);
    document.execCommand('copy');
  }

  isAllowToCopy() {
    return this.text === '(None)' || this.text === '-' || this.text === null ? false : true;
  }
}
