import { createSelector } from '@ngrx/store';
import * as fromRewardCreateState from '../reducers/reward-create.reducers';
import * as fromRewardRequestState from '../reducers/reward-request.reducers';
import { AppStates } from '../state/app.states';

const selectRewardRequestState = (state: AppStates) => state.rewardRequest;
const selectRewardCreateState = (state: AppStates) => state.rewardCreate;

export const selectAllRewardRequestList = createSelector(selectRewardRequestState, fromRewardRequestState.selectAll);

export const selectRewardRequestList = createSelector(
  selectRewardRequestState,
  (state: fromRewardRequestState.RewardRequestState) => state
);

export const selectRewardRequest = createSelector(
  selectRewardRequestState,
  (state: fromRewardRequestState.RewardRequestState) => state.selected
);

export const selectRewardValidate = createSelector(
  selectRewardCreateState,
  (state: fromRewardCreateState.RewardCreateState) => state.validateData
);

export const selectRewardError = createSelector(
  selectRewardCreateState,
  (state: fromRewardCreateState.RewardCreateState) => state.submitError
);

export const selectRewardRequestListCriteria = createSelector(selectRewardRequestState, state => {
  return state.criteriaObject;
});
