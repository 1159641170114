<div id="delivery-order-list" class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.CLAIM' | translate }}</h4>
  </div>
  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button
          class="ml-2"
          [buttons]="buttons"
          (export)="exportModal.show()"
        ></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="claimRequestStatusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          ></ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'REQUESTED_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="requestedDateFrom"
                          formControlName="requestedDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="requestedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="requestedDateTo"
                          formControlName="requestedDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="requestedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="requestedDateStringTag">
    <div class="search-criteria" *ngIf="requestedDateStringTag">
      <a class="remove-criteria" (click)="clearFilterRequestedDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ requestedDateStringTag }}: {{ requestedDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let claim$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(claim$)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                <app-click-to-copy [text]="claim$.claimRequestNo"></app-click-to-copy>
              </label>
              <span [ngClass]="getColorStatus(claim$.claimRequestStatus)" class="card-status cr-status ml-auto">
                {{ 'CLAIM_REQUEST.CLAIM_REQUEST_STATUS.' + claim$.claimRequestStatus | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Receive Order No.:</span>
              <span class="result-value"
                ><app-click-to-copy [text]="claim$.roNo | dashDisplay: '(None)'"></app-click-to-copy
              ></span>
            </div>
            <div class="col-md-6 text-break">
              <span class="result-property">Order No.:</span>
              <span class="result-value"
                ><app-click-to-copy [text]="claim$.orderNo | dashDisplay: '(None)'"></app-click-to-copy
              ></span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Store:</span>
              <span class="result-value">{{ claim$.storeCodeName }}</span>
            </div>
            <div class="col-md-6 text-break">
              <span class="result-property">Request Amount(THB):</span>
              <span class="result-value">{{ claim$.requestAmt | numberFormatDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Requested By:</span>
              <span class="result-value">{{ claim$.requestedBy | dashDisplay }}</span>
            </div>
            <div class="col-md-6 text-break">
              <span class="result-property">Requested Date:</span>
              <span class="result-value">{{ claim$.requestedDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button type="button" class="btn btn-link" (click)="showHistory(claim$)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Export Data</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-row">
            <div class="col-12 px-0">Requested Date<span class="text-danger">*</span></div>
            <div class="form-group col-md-6 pl-0">
              <label>{{ 'FROM' | translate }}:</label>
              <input
                id="export_createdDateFrom"
                formControlName="createdDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="createdMaxDate"
                (bsValueChange)="onChangeRequestedDateFrom($event)"
                readonly
              />
              <label for="export_createdDateFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>{{ 'TO' | translate }}:</label>
              <input
                id="export_createdDateTo"
                formControlName="createdDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.createdDateTo.errors
                }"
                [bsConfig]="bsDateConfig"
                [minDate]="createdMinDate"
                (bsValueChange)="onChangeRequestedDateTo($event)"
                readonly
              />
              <label for="export_createdDateTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.createdDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.createdDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Status
            </div>
            <div class="form-group">
              <ng-select
                id="export_claimStatus"
                placeholder="Please select..."
                [items]="claimStatusExportList"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
              >
              </ng-select>
            </div>
          </div>

          <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExport()"
            [disabled]="exportFormInValid"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
