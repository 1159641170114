import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RewardActiveSummary, RewardContent, RewardSearchCriteria } from '../../models/reward.model';
import { RewardAction, RewardActionTypes } from '../actions/reward.actions';

export interface RewardActiveState extends EntityState<RewardContent> {
  isLoading: boolean;
  criteriaObject: RewardSearchCriteria;
  summary: RewardActiveSummary;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<RewardContent> = createEntityAdapter<RewardContent>({
  selectId: (model: RewardContent) => model.rewardNo
});

export const initialRewardActiveState: RewardActiveState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  summary: null,
  totalElements: 0,
  totalPages: 0
});

export function RewardActiveReducers(state = initialRewardActiveState, action: RewardAction): RewardActiveState {
  switch (action.type) {
    case RewardActionTypes.REWARD_ACTIVE_LIST_REQUESTED:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case RewardActionTypes.REWARD_ACTIVE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case RewardActionTypes.REWARD_ACTIVE_SUMMARY_RESPONSE:
      return {
        ...state,
        summary: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
